import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useState, useEffect} from "react";

const WeightCalculator = (props) => {
    const [length, setLength] = useState()
    const [width, setWidth] = useState()
    const [height, setHeight] = useState()
    const [sum, setSum] = useState()

    const handleChangeInput = (e, name) => {

        if (name === 'length') {
            setLength(e)
            if (e && width && height) {
                setSum(((e * width * height) / 6000).toPrecision(3))
            }
        }
        if (name === 'width') {
            setWidth(e)
            if (length && e && height) {
                setSum(((length * e * height) / 6000).toPrecision(3))
            }
        }
        if (name === 'height') {
            setHeight(e)
            if (length && width && e) {
                setSum(((length * width * e) / 6000).toPrecision(3))
            }
        }

    }
    return (
        <Card>
            <CardBody style={{paddingBottom: '7px'}}>
                {
                    props.type
                    ?  <h4 className="card-title">{props.t("_VOLUME_WEIGHT_CALCULATOR_")}</h4>
                        : null
                }

                <AvForm className="needs-validation">
                    <Row>
                        <Col md="4">
                            <FormGroup className="mb-3">
                                <Label htmlFor="length"> {props.t("_LENGTH_")}  </Label>
                                <input
                                    className="form-control"
                                    value={length}
                                    type="text"
                                    onChange={(e) => handleChangeInput(e.target.value.replace(/\D/,''), 'length')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup className="mb-3">
                                <Label htmlFor="Width">{props.t("_WIDTH_")}</Label>
                                <input
                                    className="form-control"
                                    value={width}
                                    type="text"
                                    onChange={(e) => handleChangeInput(e.target.value.replace(/\D/,''), 'width')}
                                />

                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup className="mb-3">
                                <Label htmlFor="height">{props.t("_HEIGHT_")}</Label>
                                <input
                                    className="form-control"
                                    value={height}
                                    type="text"
                                    onChange={(e) => handleChangeInput(e.target.value.replace(/\D/,''), 'height')}
                                />

                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">{props.t("_WEIGHT_")}</Label>
                                <input
                                    className="form-control"
                                    value={length && width && height ? sum : ''}
                                    type="text"
                                    disabled
                                />
                            </FormGroup>
                        </Col>

                        {/*    ვამრავლებთ (სიგრძეს სიგანეს სიმაღლეს) და ვყოფთ 6000*/}

                    </Row>
                </AvForm>
            </CardBody>
        </Card>
    )
}

const mapStateToProps = state => {
    const {
        Auth
    } = state
    return {
        Auth
    }
};

export default connect(mapStateToProps)(withTranslation()(WeightCalculator));
