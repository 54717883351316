import {
    POST_ALL_PAYMENT_REQUEST,
    POST_FLIGHT_PAYMENT_REQUEST
} from "./actionTypes"

export const paymentAll = (data) => ({
    type:POST_ALL_PAYMENT_REQUEST,
    payload:data
})

export const paymentFlightId = (data) => ({
    type:POST_FLIGHT_PAYMENT_REQUEST,
    payload:data
})

