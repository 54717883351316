export const NULL_PRODUCTS = "NULL_PRODUCTS"
export const PRODUCT_ONE_NULL_REQUEST = "PRODUCT_ONE_NULL_REQUEST"
export const ADD_PARCEL_REQUEST = "ADD_PARCEL_REQUEST"
export const ADD_PARCEL_REQUEST_SUCCESS = "ADD_PARCEL_REQUEST_SUCCESS"
export const ADD_PARCEL_WITH_FILE_REQUEST = "ADD_PARCEL_WITH_FILE_REQUEST"
export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST"
export const GET_PRODUCTS_REQUEST_SUCCESS = "GET_PRODUCTS_REQUEST_SUCCESS"

export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST"
export const PRODUCT_NULL_REQUEST = "PRODUCT_NULL_REQUEST"
export const GET_PRODUCT_REQUEST_SUCCESS = "GET_PRODUCT_REQUEST_SUCCESS"
export const GET_PRODUCTS_WITH_FLIGHT_ID_REQUEST = "GET_PRODUCTS_WITH_FLIGHT_ID_REQUEST"
export const GET_PRODUCTS_WITH_FLIGHT_ID_REQUEST_SUCCESS = "GET_PRODUCTS_WITH_FLIGHT_ID_REQUEST_SUCCESS"
export const UPDATE_PRODUCT_DECLARATION_REQUEST = "UPDATE_PRODUCT_DECLARATION_REQUEST"
export const UPDATE_PRODUCT_DECLARATION_REQUEST_SUCCESS = "UPDATE_PRODUCT_DECLARATION_REQUEST_SUCCESS"
export const UPDATE_PRODUCT_DECLARATION_REQUEST_WITH_FILE = "UPDATE_PRODUCT_DECLARATION_REQUEST_WITH_FILE"
export const UPDATE_PRODUCT_DECLARATION_REQUEST_WITH_FILE_SUCCESS = "UPDATE_PRODUCT_DECLARATION_REQUEST_WITH_FILE_SUCCESS"

export const GET_PRODUCTS_INTINITI_REQUEST = "GET_PRODUCTS_INTINITI_REQUEST"
export const GET_PRODUCTS_INTINITI_REQUEST_SUCCESS = "GET_PRODUCTS_INTINITI_REQUEST_SUCCESS"
export const BTN_SPINNER_ACTIVE = "BTN_SPINNER_ACTIVE"

export const GET_PRODUCTS_REQUEST_OBTAINET_SUCCESS = "GET_PRODUCTS_REQUEST_OBTAINET_SUCCESS"
export const GET_PRODUCTS_INTINITI_REQUEST_OBTAINET_SUCCESS = "GET_PRODUCTS_INTINITI_REQUEST_OBTAINET_SUCCESS"
