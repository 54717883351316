import {call, put, takeEvery} from "redux-saga/effects"
import {
    POST_ALL_PAYMENT_REQUEST, POST_FLIGHT_PAYMENT_REQUEST
} from "./actionTypes"

import {notBalanceMessageError, balanceMessageSuccess} from "../../helpers/notification";
import {checkUserAuth, getUserDept} from "../auth/actions";
import {getProducts} from "../products/actions";

function* paymentAllSaga({payload}) {
    try {

        const callBack = yield call(paymentAllSagaAsync, payload)
        if (callBack.success) {
            let item = {
                status: 'arrived',
                skip: 0,
                limit: 1000
            }

            if (payload === 'arrived' ) {
                yield put(getProducts(item))
            }
            yield put(getUserDept())
            yield put(checkUserAuth())
            balanceMessageSuccess('success')
        } else {
            yield put(getUserDept())
            notBalanceMessageError('error')
        }
    } catch (err) {
        console.error(err);
    }
}


const paymentAllSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/payment/payall`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* paymentFlightIdSaga({payload}) {
    try {
        const callBack = yield call(paymentFlightIdSagaAsync, payload)
        if (callBack.success) {
            let item = {
                status: 'arrived',
                skip: 0,
                limit: 1000
            }
            yield put(getProducts(item))
            yield put(getUserDept())
            yield put(checkUserAuth())
            balanceMessageSuccess('success')
        } else {

            notBalanceMessageError('error')
        }
    } catch (err) {
        console.error(err);
    }
}


const paymentFlightIdSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/payment/payFlightId`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body : JSON.stringify(item)
    })
    return await response.json()
}


function* pageSaga() {
    yield takeEvery(POST_ALL_PAYMENT_REQUEST, paymentAllSaga)
    yield takeEvery(POST_FLIGHT_PAYMENT_REQUEST, paymentFlightIdSaga)
}

export default pageSaga
