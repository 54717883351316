import {
    GET_INVOICE_REQUEST,
    GET_INVOICE_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    invoice: {},
    invoiceProducts: [],
    invoices: {},
    isLoading: true,
}

const Invoices = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_INVOICE_REQUEST:
            return {
                ...state,
                isLoading:true
            }

        case GET_INVOICE_REQUEST_SUCCESS:
            return {
                ...state,
                invoice: action.callBack.invoice,
                invoiceProducts: action.callBack.products,
                isLoading:false
            }


        default:
            return state
    }
}

export default Invoices
