import PropTypes from 'prop-types';
import React, {useEffect, useState} from "react";

import {connect, useDispatch} from "react-redux";
import { OffcanvasProvider, Trigger, Offcanvas } from 'react-simple-offcanvas'

import { Link } from "react-router-dom";

// Reactstrap

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logoSm from "../../assets/images/logo.png";
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store

import RightbarParcel from "../CommonForBoth/RightbarParcel";
import RightbarAddAmount from "../CommonForBoth/RightbarAddAmount";
import RightbarUpdateParcel from "../CommonForBoth/RightbarUpdateParcel";
import RightbarForgetPassword from "../CommonForBoth/RightbarForgetPassword";
import CentralModal from "../CommonForBoth/CentralModal";
import RightbarCourier from "../CommonForBoth/RightbarCourier";
import {getRates} from "../../store/rate/actions";

const Header = props => {
  const dispatch = useDispatch();
  const handleOpen = () => console.log("open");
  // Callback function that is triggered when the Offcanvas is closed
  const handleClose = () => console.log("close");


  function tToggle() {
    /*
    var body = document.body;
    body.classList.toggle("vertical-collpsed");

    body.classList.toggle("sidebar-enable");*/
  }

  useEffect(() => {
    dispatch(getRates())
  }, [])

  return (
    <React.Fragment>
      {props.Layout.showRightSidebarAddParcel ? <RightbarParcel history={props.history}   /> : null}
      {props.Layout.showRightSidebarAddAmount ? <RightbarAddAmount/> : null}
      {props.Layout.showRightSidebarUpdateParcel ? <RightbarUpdateParcel/> : null}
      {props.Layout.showRightSidebarForgetPassword ? <RightbarForgetPassword/> : null}
      {props.Layout.showRightSidebarCourier ? <RightbarCourier/> : null}

      <CentralModal/>
      <header id="page-topbar" >
        <div className="navbar-header" >
          <div className="d-flex">
            <div className="navbar-brand-box" >
              <Link to="/" className="logo">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="45" />
                </span>
              </Link>
            </div>

            <ul className="navv navv-mobile" >
              <li className="navv-li">
                <Link to="/" className="nav-link">
                  {props.t("_MAIN_")}
                </Link>
              </li>
              <li className="navv-li">
                <Link to="/services" className="nav-link">
                  {props.t("_SERVICES_")}
                </Link>
              </li>
              <li className="navv-li">
                <Link to="/tarrifs" className="nav-link">
                  {props.t("_TARIFF_")}
                </Link>
              </li>
              <li className="navv-li">
                <Link to="/schedule" className="nav-link">
                  {props.t("_FLIGHT_SCHEDULES")}
                </Link>
              </li>
              <li className="navv-li">
                <Link to="/news" className="nav-link">
                  {props.t("_NEWS_")}
                </Link>
              </li>
              <li className="navv-li">
                <Link to="/contact" className="nav-link">
                  {props.t("_CONTACT_")}
                </Link>
              </li>
            </ul>
          </div>

          <div className="d-flex">
           <div className='langauge-mob'>
             <LanguageDropdown />
           </div>
            <div className='dd-none' style={{marginTop:'-2px'}}>
              <LanguageDropdown />
            </div>
            <OffcanvasProvider  onOpen={handleOpen} onClose={handleClose}>
              <Trigger
                  component={'button'}
                  children={<i className="fa fa-fw fa-bars" style={{fontSize:'20px'}} />}
                  className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn" />
              <Offcanvas
                  title={'AliGeorgia.ge'}
                  styles={{backgroundColor:'#2c323c', height:'400px'}}
                  children={
                    <div id="sidebar-menu">
                      <ul className="metismenu list-unstyled" id="side-menu">
                        <li>
                          <Link to="/" className="waves-effect">
                            <i className="uil-home-alt"></i>
                            <span>{props.t("_MAIN_")} </span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/services" className="waves-effect">
                            <i className="uil-signal-alt-3"></i>
                            <span>{props.t("_SERVICES_")}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/tarrifs" className="waves-effect">
                            <i className="uil-usd-square"></i>
                            <span>{props.t("_TARIFF_")}</span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/schedule" className="waves-effect">
                            <i className="uil-schedule"></i>
                            <span>{props.t("_FLIGHT_SCHEDULES")}</span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/news" className="waves-effect">
                            <i className="uil-newspaper"></i>
                            <span>{props.t("_NEWS_")}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/contact" className="waves-effect">
                            <i className="uil-location-point"></i>
                            <span>{props.t("_CONTACT_")}</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  }

                  position={'top'}
              />
            </OffcanvasProvider>


            {
              props.Auth.isAuthenticated !== null
                  ?
                  <>
                    {
                      props.Auth.isAuthenticated === false
                          ? null
                          : <>
                            <NotificationDropdown />
                            <ProfileMenu />
                        </>
                    }
                  </>
                  : null
            }
          </div>
        </div>

      </header>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const {
    Layout,
    Auth
  } = state
  return {
    Layout,
    Auth
  }
};

export default connect(mapStateToProps)(withTranslation()(Header));
