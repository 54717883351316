import React, {useEffect} from "react";
import {Card, CardBody, CardImg, CardTitle, Col, Container, Label, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {newNewst} from "../../store/news/actions";




function ucFirst(str) {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
}


const NewsPage = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        let item = {
            skip: 0,
            limit: 18
        }
        dispatch(newNewst(item))
    }, [])


    let title ='newsTitle'+ ucFirst(props.i18n.language)

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid={true}>
                    <Row>

                    {
                        props.News.array.length > 0
                            ?
                            <>
                                {
                                    props.News.array.map((item,key) =>{
                                        return (
                                            <Col mg={6} xl={3} key={key}>
                                                <Card>
                                                    <CardImg top className="img-fluid" src={process.env.REACT_APP_UPLOADER_SERVER_URL+'/uploads/middle-'+item.photo} alt={item.newsTitleKa}/>
                                                    <CardBody>
                                                        <CardTitle className="h4 mt-0">{item[title]}</CardTitle>
                                                        <Link
                                                            to={`/news-detail/${item._id}`}
                                                            className="btn btn-primary waves-effect waves-light"
                                                        >
                                                            {props.t("_MORE_")}
                                                        </Link>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                            </>
                            : null
                    }

                    </Row>

                </Container>
            </div>

        </React.Fragment>
    )

}


const mapStateToProps = state => {
    const {
        News
    } = state
    return {
        News
    }
};


export default connect(mapStateToProps)(withTranslation()(NewsPage));

