import React, {useEffect} from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

//Import Images
import errorImg from "../../assets/images/checked.png"
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

const SuccessPage = (props) => {
    useEffect(() => {

    });

    return (
        <React.Fragment>
            <div className="my-5 pt-sm-5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="text-center">
                                <Row className="row justify-content-center">
                                    <Col sm={4} style={{paddingTop:'50px'}}>
                                        <div className="error-img">
                                            <img src={errorImg} alt="" className="img-fluid mx-auto d-block" />
                                        </div>
                                    </Col>
                                </Row>
                                <h4 className="text-uppercase mt-4">{props.t('_PAYMENT_SUCCESS_')}</h4>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default withTranslation()(SuccessPage);
