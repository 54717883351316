import {call, put, takeEvery} from "redux-saga/effects"
import {
    CREATE_COURIER_REQUEST,
    GET_COURIER_FLIGHTS_REQUEST,
    GET_COURIER_FLIGHTS_REQUEST_SUCCESS,
    GET_COURIER_PARCELS_REQUEST,
    GET_COURIER_PARCELS_REQUEST_SUCCESS,
} from "./actionTypes"
import {showRightSidebarCourierAction} from "../layout/actions";
import {notification} from "antd";


const modalSuccess = (type, placement) => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
        placement
    });
};

function* getCouriersFlightsSaga({payload}) {
    try {
        const callBack = yield call(getCouriersFlightsSagaAsync, payload)
        if (callBack.success) {
            yield put({type: GET_COURIER_FLIGHTS_REQUEST_SUCCESS, callBack})
        }
    } catch (err) {
        console.error(err);
    }
}

const getCouriersFlightsSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")

    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/courier/getFlights/${item.branches}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* getCouriersParcelsSaga({payload}) {
    try {
        const callBack = yield call(getCouriersParcelsSagaAsync, payload)
        if (callBack.success) {
            yield put({type: GET_COURIER_PARCELS_REQUEST_SUCCESS, callBack})
        }
    } catch (err) {
        console.error(err);
    }
}

const getCouriersParcelsSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/courier/getParcels/${item.branches}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}



function* createCourierSaga({payload}) {
    try {
        const callBack = yield call(createCourierSagaAsync, payload)
        if (callBack.success) {
            modalSuccess('success');
            yield put(showRightSidebarCourierAction(false))
        }else {

        }
    } catch (err) {
        console.error(err);
    }
}


const createCourierSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/courier/create`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* newsSaga() {
    yield takeEvery(GET_COURIER_PARCELS_REQUEST, getCouriersParcelsSaga)
    yield takeEvery(GET_COURIER_FLIGHTS_REQUEST, getCouriersFlightsSaga)
    yield takeEvery(CREATE_COURIER_REQUEST, createCourierSaga)
}

export default newsSaga
