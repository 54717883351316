import React, {useEffect} from "react";
import {Col, Container, Row} from "reactstrap";
import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {getPage} from "../../store/page/actions";


function ucFirst(str) {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
}


const TermsPages = (props) => {
    const dispatch = useDispatch();
    useEffect(()=>{
        let item = {
            slug : 'terms'
        }
        dispatch(getPage(item))
    },[])

    let ucTitle ='title'+ ucFirst(props.i18n.language)
    let ucFirstDescription ='description'+ ucFirst(props.i18n.language)

    return (
        <React.Fragment>
            <div className="my-5 pt-sm-5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="text-center">
                                <h4 className="text-uppercase mt-4">
                                    {/*{props.t("_TERMS_2_")}*/}
                                    {props.Pages.page[ucTitle]}

                                </h4>
                                <br/>
                            </div>
                            <div >
                                <div dangerouslySetInnerHTML={{__html: props.Pages.page[ucFirstDescription]}} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}


const mapStateToProps = state => {
    const {
        Layout,
        Pages
    } = state
    return {
        Layout,
        Pages
    }
};
export default connect(mapStateToProps)(withTranslation()(TermsPages))

