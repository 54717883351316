import {call, put, takeEvery} from "redux-saga/effects"
import {
    CHECK_USER_AUTH_REQUEST,
    CHECK_USER_AUTH_REQUEST_SUCCESS,
    SIGN_IN_REQUEST,
    CHECK_USER_AUTH_REQUEST_ERROR,
    LOG_OUT_REQUEST,
    LOG_OUT_REQUEST_SUCCESS,
    CHECK_USER_AUTH_REQUEST_SUCCESS_FALSE,
    SIGN_UP_REQUEST,
    SIGN_UP_WITH_FILE_REQUEST,
    UPDATE_USER_PROFILE_REQUEST,
    FORGET_PASSWORD_REQUEST,
    CHANGE_PASSWORD_REQUEST,
    GET_USER_DEPT_REQUEST, GET_USER_DEPT_REQUEST_SUCCESS
} from "./actionTypes"
import {checkUserAuth} from "./actions";
import {showRightSidebarAction} from "../layout/actions";

import {
    forgetPasswordSuccess,
    forgetPasswordError,
    changePasswordError,
    changePasswordSuccess,
    profileSuccessUpdate,
    suchAnEmailHasArledy,
    suchAnIdHasArledy,
    signEmailOrAXIncorect
} from "../../helpers/notification";

function* signInSaga({payload}) {
    try {
        const callBack = yield call(signInSagaAsync, payload)
        if (callBack.success) {
            localStorage.setItem('token', callBack.token);
            yield put(checkUserAuth(payload));
        } else {
            signEmailOrAXIncorect('warning')
            localStorage.removeItem("token")
        }
    } catch (err) {
        console.error(err);
    }
}


const signInSagaAsync = async (item) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/authorization/signin`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* checkUserAuthSaga({payload}) {
    try {
        if (localStorage.getItem("token")) {
            const callBack = yield call(checkUserAuthSagaAsync)
            if (callBack.success) {
                localStorage.setItem("authUser", JSON.stringify(callBack.candidate))
                yield put({type: CHECK_USER_AUTH_REQUEST_SUCCESS, callBack})
            }else {
                localStorage.removeItem("token")
                localStorage.removeItem("authUser")
                yield put({type: CHECK_USER_AUTH_REQUEST_SUCCESS_FALSE})
            }
        }else {
            yield put({type: CHECK_USER_AUTH_REQUEST_ERROR})
        }
    } catch (e) {
        localStorage.removeItem("token")
        localStorage.removeItem("authUser")
        //Router.push('/');
    }
}


const checkUserAuthSagaAsync = async () => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/authorization/userInformation`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* signUpSaga({payload}) {
    try {
        const callBack = yield call(signUpSagaAsync, payload)
        if (callBack.success) {
            localStorage.setItem('token', callBack.token);
            yield put(checkUserAuth(payload));
            yield put(showRightSidebarAction(false));
        }else {
            if (callBack.message === 'email_or_user_id_already_registred') {
                suchAnEmailHasArledy('warning')
            }
            else if (callBack.message === 'passport_id_already_registred') {
                suchAnIdHasArledy('warning')
            }
        }
    } catch (e) {
        localStorage.removeItem("token")
        localStorage.removeItem("authUser")
    }
}


const signUpSagaAsync = async (item) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/authorization/signup`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* signUpWithFileSaga({payload}) {
    try {
        const callBack = yield call(signUpWithFileSagaAsync, payload)
        if (callBack.success) {
            localStorage.setItem('token', callBack.token);
            yield put(checkUserAuth(payload));
            yield put(showRightSidebarAction(false));
        }else {
            if (callBack.message === 'email_or_user_id_already_registred') {
                suchAnEmailHasArledy('warning')
            }
            else if (callBack.message === 'passport_id_already_registred') {
                suchAnIdHasArledy('warning')
            }
        }
    } catch (e) {
        localStorage.removeItem("token")
        localStorage.removeItem("authUser")
    }
}


const signUpWithFileSagaAsync = async (item) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/authorization/signupFile`, {
        method: "POST",
        body: item
    })
    return await response.json()
}






function* updateUserProfileSaga({payload}) {
    try {
        const callBack = yield call(updateUserProfileSagaAsync, payload)
        if (callBack.success) {
            profileSuccessUpdate('success')
        }
    } catch (e) {
        // localStorage.removeItem("token")
        // localStorage.removeItem("authUser")
    }
}


const updateUserProfileSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/authorization/updateprofile`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}



function* forgetPasswordSaga({payload}) {
    try {
        const callBack = yield call(forgetPasswordSagaAsync, payload)
        if (callBack.success) {
            forgetPasswordSuccess('success');
        }else {
            forgetPasswordError('error');
        }

    } catch (e) {
        // localStorage.removeItem("token")
        // localStorage.removeItem("authUser")
    }
}


const forgetPasswordSagaAsync = async (item) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/authorization/forgetPassword`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* changePasswordSaga({payload}) {
    try {
        const callBack = yield call(changePasswordSagaAsync, payload)
        if (callBack.success) {
            changePasswordSuccess('success');
        }else {
            changePasswordError('error');
        }

    } catch (e) {
        console.error(e);
    }
}
const changePasswordSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/authorization/changePassword`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}
function* getUserDeptSaga() {
    try {
        const callBack = yield call(getUserDeptSagaAsync)
        if (callBack.success) {
            yield put({type: GET_USER_DEPT_REQUEST_SUCCESS, callBack})
        }else {

        }

    } catch (e) {
        console.error(e);
    }
}
const getUserDeptSagaAsync = async () => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/product/debtSum`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* logOutSaga({payload}) {
    try {
        localStorage.removeItem("token")
        localStorage.removeItem("authUser")
        yield put({type: LOG_OUT_REQUEST_SUCCESS})
    } catch (e) {
        console.log(e);
    }
}


function* authSaga() {
    yield takeEvery(SIGN_IN_REQUEST, signInSaga)
    yield takeEvery(SIGN_UP_WITH_FILE_REQUEST, signUpWithFileSaga)
    yield takeEvery(SIGN_UP_REQUEST, signUpSaga)
    yield takeEvery(CHECK_USER_AUTH_REQUEST, checkUserAuthSaga)
    yield takeEvery(LOG_OUT_REQUEST, logOutSaga)
    yield takeEvery(UPDATE_USER_PROFILE_REQUEST, updateUserProfileSaga)
    yield takeEvery(FORGET_PASSWORD_REQUEST, forgetPasswordSaga)
    yield takeEvery(CHANGE_PASSWORD_REQUEST, changePasswordSaga)
    yield takeEvery(GET_USER_DEPT_REQUEST, getUserDeptSaga)
}

export default authSaga
