import React  from 'react'
import {withRouter} from "react-router-dom";
const RegisterPayment = (props) => {


/*

    https://voyage.ru/RegisterPayment.php?trx_id=0MZN2KNHVPYQ4FAV&amp;merch_id=IVACQ_voyage&amp;merchant_trx=0MZN2KNHVPYQ4FAV&amp;r
        esult_code=1&amp;amount=780000&amp;o.order_id=114&amp;p.rrn=999345&amp;p.authcode=AB2F23&amp;p.maskedPan=545454xxxxxx5454&amp;p.isFullyAuthent
    icated=Y&amp;p.transmissionDateTime=0511170331&amp;ts=20160511+17%3A02%3A24&amp;signature=pIvz%2FvuTTWfA9xxUC1BAho2gHyXi6uXDgYX
    YLRZWX0emxL3S0xOsbGkDsLZjCD8EaDMrKa91NKWBqiY2OCGNb4CWi%2Bqjs2pRHuMzBLWEfGWcstd0g6hYMrOMqUTFpEx0uUFiqE%2FnU
    QzpOkHRXexACA9S7b8FdJaDkDrJKojhVdE%3D
*/

    return (
        <>

        </>
    )
}
export default withRouter(RegisterPayment)
