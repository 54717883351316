import React  from 'react'
import XMLViewer from 'react-xml-viewer'
import { withRouter, useLocation } from "react-router-dom"
const queryString = require('query-string');

const CheckAvailable = (props) => {
//http://localhost:3000/checkAvailable?merch_id=IVACQ_voyage&amp;trx_id=0MZN2KNHVPYQ4FAV&amp;o.order_id=114&amp;lang_code=RU&amp;ts=20160511+17%3A02%3A11

 //   const id = new URLSearchParams(props.location.search).get("order_id");
   // console.log("######",id)
    const parsed = queryString.parse(props.location.search);
    console.log(parsed.trx_id); // replace param with your own

    // const search = useLocation().search;
    // console.log('path', search);
    // const id = new URLSearchParams(search).get("merch_id");
    // console.log(id);
    //
    // const xml = '<payment-avail-response>\n' +
    //     '<result>\n' +
    //     '<code>1</code>\n' +
    //     '<desc>OK</desc>\n' +
    //     '</result>\n' +
    //     '<merchant-trx>\'.$query_params[\'trx_id\'].\'</merchant-trx>\n' +
    //     '<purchase>\n' +
    //     '<shortDesc>ბალანსის შევსება</shortDesc>\n' +
    //     '<longDesc>ბალანსის შევსება</longDesc>\n' +
    //     '<account-amount>\n' +
    //     '<id>CEAA21277C5182E7AE1DE136F8A1558D</id>\n' +
    //     '<amount>\'.$query_params[\'o_amount\'].\'</amount>\n' +
    //     '<currency>981</currency>\n' +
    //     '<exponent>2</exponent>\n' +
    //     '</account-amount>\n' +
    //     '</purchase>\n' +
    //     '</payment-avail-response>'
    //
    const xml =  `<payment-avail-response>
<result>
<code>1</code>
<desc>OK</desc>
</result>
<merchant-trx>${parsed.trx_id}</merchant-trx>
<purchase>
<shortDesc>ბალანსის შევსება</shortDesc>
<longDesc>ბალანსის შევსება</longDesc>
<account-amount>
<id>CEAA21277C5182E7AE1DE136F8A1558D</id>
<amount>${parsed.o_amount}</amount>
<currency>981</currency>
<exponent>2</exponent>
</account-amount>
</purchase>
</payment-avail-response>`
    return (
        <>
            <XMLViewer xml={xml} />
        </>
    )
}
export default withRouter(CheckAvailable)
