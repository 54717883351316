import {
    ADD_BALANCE_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    result: {
        createdAt: "",
        destination: "",
        type: "",
        userAXID: "",
        __v: 0,
        _id: "",
    },
}

const News = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_BALANCE_REQUEST_SUCCESS:
            return {
                ...state,
                result: action.callBack.result,
            }

        default:
            return state
    }
}

export default News
