import {notification} from 'antd';
import {withTranslation} from "react-i18next";
import i18n from "../i18n"


export const signEmailOrAXIncorect = type => {
    notification[type]({
        message: i18n.t('_SUCCESS_ERROR_'),
        description: i18n.t('_PASSWORD_OR_AX_INCORRECT'),
    });
};

export const forgetPasswordSuccess = type => {
    notification[type]({
        message: i18n.t('_SUCCESS_'),
        description:i18n.t('_NOTIFICATION_NEW_PASSWORD_HAS_SEND_TO_EMAIL_'),
    });
};

export const forgetPasswordError = type => {
    notification[type]({
        message: i18n.t('_SUCCESS_'),
        description: i18n.t('_EMAIL_NOT_FINDED_'),
    });
};

export const changePasswordSuccess = type => {
    notification[type]({
        message: i18n.t('_SUCCESS_'),
        description:i18n.t('_NOTIFICATION_PASSWORD_CHANGED_SUCCESSFULLY_'),
    });
};


export const changePasswordError = type => {
    notification[type]({
        message: i18n.t('_SUCCESS_'),
        description: i18n.t('_NOTIFICATION_OLD_PASSWORD_INCORECT_'),
    });
};


export const rightbarParcelError = type => {
    notification[type]({
        message: i18n.t('_SUCCESS_'),
        description: i18n.t('_NOTIFICATION_TO_FILL_IN_ALL_'),
    });
};

export const notBalanceMessageError = type => {
    notification[type]({
        message: i18n.t('_SUCCESS_ERROR_'),
        description:i18n.t('_NOTIFICATION_YOU_DONT_HAVE_MONEY_'),
    });
};

export const balanceMessageSuccess = type => {
    notification[type]({
        message: i18n.t('_SUCCESS_'),
        description: i18n.t('_NOTIFICATION_SUCCESS_PAYMENT_'),
    });
};

export const profileSuccessUpdate = type => {
    notification[type]({
        message: i18n.t('_SUCCESS_'),
        description: i18n.t('_SUCCESS_PROFILE_UPDATED_'),
    });
};
export const suchAnEmailHasArledy = type => {
    notification[type]({
        message: i18n.t('_SUCCESS_ERROR_'),
        description: i18n.t('_SUCH_AN_EMAIL_HAS_ARLEDY_BEEN_REGISTRED'),
    });
};
export const suchAnIdHasArledy = type => {
    notification[type]({
        message: i18n.t('_SUCCESS_ERROR_'),
        description: i18n.t('_SUCH_AN_ID_HAS_ARLEDY_BEEN_REGISTRED'),
    });
};

export const incorectFormat = type => {
    notification[type]({
        message: i18n.t('_SUCCESS_ERROR_'),
        description: i18n.t('_OTHER_ONLY_JPG_2'),
    });
};

export const incorectBranchAddress = type => {
    notification[type]({
        message: i18n.t('_SUCCESS_ERROR_'),
        description: i18n.t('_Choose_a_branch'),
    });
};
export const trackingAlreadyAdded = type => {
    notification[type]({
        message: i18n.t('_SUCCESS_ERROR_'),
        description: i18n.t('_NOTIFICATION_TRAKING_HAS_ALREADY_ADDED_'),
    });
};
