import {
    GET_NOTIFICATION_REQUEST,
    GET_NOTIFICATION_DETAIL_REQUEST,
    GET_NOTIFICATION_ALL_REQUEST,
    UPDATE_MARK_ALL_AS_READ_REQUEST
} from "./actionTypes"

export const getNotification = () => ({
    type:GET_NOTIFICATION_REQUEST
})

export const getNotificationAll = () => ({
    type:GET_NOTIFICATION_ALL_REQUEST
})

export const markAllAsRead = () => ({
    type:UPDATE_MARK_ALL_AS_READ_REQUEST
})

export const getNotificationDetail = (data) => ({
    type:GET_NOTIFICATION_DETAIL_REQUEST,
    payload: data,
})

