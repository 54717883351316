import React, {useEffect, useState} from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

import { withTranslation } from "react-i18next"
import {connect, useDispatch} from "react-redux";
import {getNotification, markAllAsRead} from "../../../store/notification/actions";



function ucFirst(str) {
  if (!str) return str;
  return str[0].toUpperCase() + str.slice(1);
}



const NotificationDropdown = props => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false)

  useEffect(()=>{
    if (props.Auth.isAuthenticated===true) {
      dispatch(getNotification())
    }

  },[])

  const handleMarkAllAsRead = () =>{
    dispatch(markAllAsRead())
  }
  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          {/*<i className="uil-bell"  style={{color:'#2c323c'}}></i>*/}
          <i className="uil-bell notification-icon-color"   ></i>
          {
            props.Notification.arrayCount > 0
              ?   <span className="badge bg-danger rounded-pill">{props.Notification.arrayCount}</span>
                : null
          }

        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16">  {props.t("_NOTIFICATION_")} </h6>
              </Col>

            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            <>
              {
                props.Notification.array.map((item, key) =>{
                  let title ='title'+ ucFirst(props.i18n.language)
                  let description ='description'+ ucFirst(props.i18n.language)
                  return (
                      <Link key={key} to={`/notification-detail/${item._id}`}
                          style={item.status === '1' ? {fontWeight:'bolder' } : null}
                            className="text-reset notification-item">
                        <div className="d-flex align-items-start">
                          <div className="avatar-xs me-3" style={{height:'23px', width:'25px'}}>
                                  <span className="avatar-title bg-success rounded-circle font-size-16">
                                    <i className="bx bx-badge-check"/>
                                  </span>
                          </div>
                          <div className="flex-1">
                            <h6 className="mt-0 mb-1"
                                style={item.status === '1' ? {fontWeight:'bolder'} : null}
                            >
                              {item[title]}
                            </h6>
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">
                                {item[description].substr(0, 50)}...
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                  )
                })
              }
            </>


          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
                className="btn btn-sm btn-link font-size-14 text-center"
                to="/notification-all"
            ><i className="uil-arrow-circle-right me-1"></i>
              {" "}
              {props.t("_VIEW_ALL_")}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}


const mapStateToProps = state => {
  const {
    Auth,
    Layout,
    Notification
  } = state
  return {
    Auth,
    Layout,
    Notification
  }
};

export default connect(mapStateToProps)(withTranslation()(NotificationDropdown));
