import {call, put, takeEvery} from "redux-saga/effects"
import {
    CREATE_COURIER_REQUEST,
    GET_COURIER_FLIGHTS_REQUEST, GET_COURIER_FLIGHTS_REQUEST_SUCCESS, UPDATE_BRANCHES_REQUEST,
} from "./actionTypes"
import {showRightSidebarCourierAction} from "../layout/actions";
import {notification} from "antd";


const modalSuccess = (type, placement) => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
        placement
    });
};


function* updateBranchesSaga({payload}) {
    try {
        const callBack = yield call(updateBranchesSagaAsync, payload)
        if (callBack.success) {
            modalSuccess('success');
            yield put(showRightSidebarCourierAction(false))
        }else {

        }
    } catch (err) {
        console.error(err);
    }
}


const updateBranchesSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/branch/create`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* newsSaga() {
    yield takeEvery(UPDATE_BRANCHES_REQUEST, updateBranchesSaga)
}

export default newsSaga
