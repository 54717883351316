import React, {useState, useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {Card, CardBody, CardTitle, Col, Container, Nav, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import {getOffices} from "../../store/offices/actions";
import AddressContainer from "./AddressContainer";

import ChinaFlag from "../../assets/images/flags/china.png";
import TurkeyFlag from "../../assets/images/flags/turkey.png";

const Offices = (props) => {
    const dispatch = useDispatch();
    const [activeTabV, setactiveTabV] = useState("1")

    function toggleV(tab) {
        if (activeTabV !== tab) {
            setactiveTabV(tab)
        }
    }

    useEffect(() => {
        dispatch(getOffices())
    }, [])
    return (
        <Container className='div-none-web' fluid>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <CardTitle className="h4">
                                {
                                    props.t("_ADDRESSES_")
                                }
                            </CardTitle>
                            <Row>
                                <Col md={2}>
                                    <Nav pills className="flex-column">
                                        <NavLink
                                            style={{cursor: "pointer"}}
                                            className={classnames('mb-2', {
                                                active: activeTabV === "1",
                                            })}
                                            onClick={() => {
                                                toggleV("1")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><img src={ChinaFlag}  style={{height:'20px'}}/>
                                            <span className="d-sm-block"> {props.t("_CHINA_")} </span>
                                            </span>
                                            <span className="d-none d-sm-block">  {props.t("_CHINA_")}  </span>
                                        </NavLink>
                                        <NavLink
                                            style={{cursor: "pointer"}}
                                            className={classnames('mb-2', {
                                                active: activeTabV === "3",
                                            })}
                                            onClick={() => {
                                                toggleV("3")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><img src={TurkeyFlag} style={{height:'20px'}}/>
                                             <span className="d-sm-block">  {props.t("_TURKEY_")} </span>
                                            </span>
                                            <span className="d-none d-sm-block"> {props.t("_TURKEY_")} </span>
                                        </NavLink>

                                        <NavLink
                                            style={{cursor: "pointer"}}
                                            className={classnames('mb-2', {
                                                active: activeTabV === "2",
                                            })}
                                            onClick={() => {
                                                toggleV("2")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><img src={TurkeyFlag} style={{height:'20px'}}/>
                                             <span className="d-sm-block">  {props.t("_USA_")} </span>
                                            </span>
                                            <span className="d-none d-sm-block"> {props.t("_USA_")} </span>
                                        </NavLink>

                                    </Nav>
                                </Col>
                                <Col md={10}>
                                    <TabContent activeTab={activeTabV} className="text-muted mt-4 mt-md-0">
                                        <TabPane tabId="1">
                                            <Row>
                                                <AddressContainer
                                                    country={1}
                                                    Offices={props.Offices.array}
                                                    Auth={props.Auth}
                                                />
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <AddressContainer
                                                    country={2}
                                                    Offices={props.Offices.array}
                                                    Auth={props.Auth}
                                                />
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <Row>
                                                <AddressContainer
                                                    country={3}
                                                    Offices={props.Offices.array}
                                                    Auth={props.Auth}
                                                />
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="18">
                                            <Row>
                                                <AddressContainer
                                                    country={4}
                                                    Offices={props.Offices.array}
                                                    Auth={props.Auth}
                                                />
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </Col>

            </Row>

        </Container>
    )
}

const mapStateToProps = state => {
    const {
        Offices,
        Auth
    } = state
    return {
        Offices,
        Auth
    }
};

export default connect(mapStateToProps)(withTranslation()(Offices));
