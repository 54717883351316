export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST"
export const SIGN_IN_REQUEST_SUCCESS = "SIGN_IN_REQUEST_SUCCESS"

export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST"
export const SIGN_UP_REQUEST_SUCCESS = "SIGN_UP_REQUEST_SUCCESS"

export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST"
export const FORGET_PASSWORD_REQUEST_SUCCESS = "FORGET_PASSWORD_REQUEST_SUCCESS"

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST"
export const CHANGE_PASSWORD_REQUEST_SUCCESS = "CHANGE_PASSWORD_REQUEST_SUCCESS"

export const CHECK_USER_AUTH_REQUEST = "CHECK_USER_AUTH_REQUEST"
export const CHECK_USER_AUTH_REQUEST_SUCCESS = "CHECK_USER_AUTH_REQUEST_SUCCESS"
export const CHECK_USER_AUTH_REQUEST_SUCCESS_FALSE = "CHECK_USER_AUTH_REQUEST_SUCCESS_FALSE"
export const CHECK_USER_AUTH_REQUEST_ERROR = "CHECK_USER_AUTH_REQUEST_ERROR"
export const LOG_OUT_REQUEST = "LOG_OUT_REQUEST"
export const LOG_OUT_REQUEST_SUCCESS = "LOG_OUT_REQUEST_SUCCESS"
export const SIGN_UP_WITH_FILE_REQUEST = "SIGN_UP_WITH_FILE_REQUEST"

export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST"
export const UPDATE_USER_PROFILE_REQUEST_SUCCESS = "UPDATE_USER_PROFILE_REQUEST_SUCCESS"
export const GET_USER_DEPT_REQUEST = "GET_USER_DEPT_REQUEST"
export const GET_USER_DEPT_REQUEST_SUCCESS = "GET_USER_DEPT_REQUEST_SUCCESS"
