import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap";
import {AvForm} from "availity-reactstrap-validation";
import React, {Fragment,useState, useEffect} from "react";
import {Link} from "react-router-dom";

import {tariffeType} from '../helpers/types'

const Tariffs = (props) => {
    const [tariffType, setTarffType] = useState(1)
    const [tariff, setTarff] = useState(null)


    useEffect(() => {
        tariffFetchData();
    }, []);

    const tariffFetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/tariffs/gets`);
            const json = await response.json();
            setTarff(json.tariffs)
        } catch (error) {
            console.log("error", error);
        }
    };

    return (
        <Card>
            <CardBody>
                <h4 className="card-title">{props.t("_PRICE_")}</h4>
                <AvForm className="needs-validation">
                    <Row>
                        <Col md="12">
                            <FormGroup className="mb-3">
                                <select
                                    value={tariffType}
                                    onChange={(e) => setTarffType(parseInt(e.target.value))}
                                    className="form-control form-control-border">
                                    <option value="1">{props.t("_CHINA_")}</option>
                                    <option value="2">{props.t("_USA_")}</option>
                                    <option value="3">{props.t("_TURKEY_")}</option>
                                </select>
                            </FormGroup>
                        </Col>
                    </Row>

                    {
                        tariff !== null
                            ?
                            <>
                                {
                                    tariff.map((item, key) => {
                                        return (
                                            <Fragment key={key}>
                                                {
                                                    item.country === tariffType
                                                        ?
                                                        <Row className="country">
                                                            <Col sm={12}>
                                                                <div className="mt-1 mb-0">
                                                                    <p><i
                                                                        className="mdi mdi-circle-medium text-warning me-2"></i>{item.amount}$ {' '}
                                                                        {tariffeType(item.type, props)}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        : null
                                                }
                                            </Fragment>
                                        )
                                    })
                                }
                            </>
                            : null
                    }
                    <Row>
                        <Col sm={12} style={{textAlign: 'right'}}>
                          <Link to={'/tarrifs'}>
                              <p className=" mt-1 mb-0">
                                  {props.t("_MORE_")}...</p>
                          </Link>
                        </Col>

                    </Row>
                </AvForm>
            </CardBody>
        </Card>
    )
}

const mapStateToProps = state => {
    const {
        Auth
    } = state
    return {
        Auth
    }
};

export default connect(mapStateToProps)(withTranslation()(Tariffs));
