import {call, put, takeEvery} from "redux-saga/effects"
import {
    GET_CENTRAL_MODAL_REQUEST,
    GET_CENTRAL_MODAL_REQUEST_SUCCESS
} from "./actionTypes"


function* getModalSaga({payload}) {
    try {
        const callBack = yield call(getModalSagaAsync, payload)
        if (callBack.success) {
            yield put({type: GET_CENTRAL_MODAL_REQUEST_SUCCESS, callBack})
        } else {

        }
    } catch (err) {
        console.error(err);
    }
}


const getModalSagaAsync = async (item) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/popap/get`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",

        }
    })
    return await response.json()
}


function* pageSaga() {
    yield takeEvery(GET_CENTRAL_MODAL_REQUEST, getModalSaga)
}

export default pageSaga
