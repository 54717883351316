import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {Card, CardBody, CardImg, CardTitle, Col, Container, Label, Row} from "reactstrap";
import React, {useState, useEffect} from "react";

import {Link} from "react-router-dom";
import {newNewst} from "../store/news/actions";

const News = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        let item = {
            skip: 0,
            limit: 4
        }
        dispatch(newNewst(item))
    }, [])
    return (
        <Container fluid>
            <Row style={{textAlign: 'center'}}>
                {
                    props.News.array.length > 0
                        ?
                        <>
                            {
                                props.News.array.map((item,key) =>{
                                    return (
                                        <Col mg={6} xl={3} key={key}>
                                            <Card>
                                                <CardImg top className="img-fluid" src={process.env.REACT_APP_UPLOADER_SERVER_URL+'/uploads/middle-'+item.photo} alt={item.newsTitleKa}/>
                                                <CardBody>
                                                    <CardTitle className="h4 mt-0">{item.newsTitleKa}</CardTitle>
                                                    <Link
                                                        to={`/news-detail/${item._id}`}
                                                        className="btn btn-primary waves-effect waves-light"
                                                    >

                                                        {props.t("_MORE_")}
                                                    </Link>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                        </>
                        : null
                }

            </Row>
        </Container>
    )
}

const mapStateToProps = state => {
    const {
        Auth,
        News
    } = state
    return {
        Auth,
        News
    }
};

export default connect(mapStateToProps)(withTranslation()(News));
