import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {
    Card,
} from "reactstrap"
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import {withTranslation} from "react-i18next";

const rowStyle2 = (row, rowIndex) => {
    const style = {};
    if (row.readerStatus === true) {
        style.backgroundColor = '#c8e6c9';
    }

    return style;
};

const ObjectColumns = props => {

    const Columns = [
        {
            dataField: "flightId",
            text: "რეისის ნომერი",
        },
    ]

    return (
        <>
            {
                !isEmpty(props.flights)
                    ?
                    <>
                        <Card>
                            <BootstrapTable
                                selectRow={{
                                    mode: 'checkbox', clickToSelect: false,
                                    onSelect: props.onRowSelect,
                                    onSelectAll: props.onSelectAll,
                                }}
                                // ref={props.bstable}
                                _id={'_id'}
                                keyField="_id"
                                data={props.flights}
                                ref={props.bstable}
                                columns={Columns}
                            />
                        </Card>

                    </>
                    :
                    <>
                        {/*{
                            props.Courier.message === 'user_have_products'
                                ? <i style={{color :'red'}}> თქვენ არ გაქვთ გადახდილი წონის საფასური </i>
                                : <i style={{color :'red'}}> თქვენ არ გაქვთ რეისი ჩამოსულში </i>
                        }*/}
                        {
                            props.Courier.message === 'user_have_not_products'
                                ? <i style={{color :'red'}}> თქვენ არ გაქვთ რეისი ჩამოსულში </i>
                                : null
                        }
                        {
                            props.Courier.message === 'product_unpaid'
                                ? <i style={{color :'red'}}> თქვენ არ გაქვთ გადახდილი წონის საფასური </i>
                                : null
                        }
                    </>
            }

        </>
    )
}

const mapStateToProps = state => {
    const {
        Courier
    } = state
    return {
        Courier
    }
};

export default connect(mapStateToProps)(withTranslation()(ObjectColumns));


