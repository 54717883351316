import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Label,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import {connect, useDispatch} from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import user4 from "../../../assets/images/users/avatar-4.jpg"
import { logOut} from "../../../store/auth/actions";

const ProfileMenu = props => {
  const dispatch = useDispatch();
 const [menu, setMenu] = useState(false)

  const handleLogout = () =>{
    props.history.push('/home');
    dispatch(logOut())
  }


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user4}
            alt="Header Avatar"
          />
          <span
              style={{color :'#2c323c'}}
              className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">
         {/*   {props.Auth.candidate.information.nameKa}*/}

          </span>{" "}
          <i
              style={{color :'#2c323c'}}
              className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem>
            {" "}
            <Link to={'/profile'}>
              <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
              {props.t("_PROFILE_")}
            </Link>
          </DropdownItem>
       {/*   <DropdownItem tag="a" href="/">
            <i className="uil uil-wallet font-size-18 align-middle me-1 text-muted"></i>
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <i className="uil uil-cog font-size-18 align-middle me-1 text-muted"></i>
            {props.t("Settings")}
            <span className="badge bg-soft-success rounded-pill mt-1 ms-2">03</span>
          </DropdownItem>*/}
          <DropdownItem  >

            <Link to={'/change-password'}>
              <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"></i>

              {props.t("_CHANGE_PASSWORD_")}
            </Link>
          </DropdownItem>


          <DropdownItem>
            <Link to={'/add-branches'}>
              <i className="uil uil-location-point font-size-18 align-middle me-1 text-muted"></i>
              {props.t('_Choose_a_branch')}
            </Link>
          </DropdownItem>


          <div className="dropdown-divider" />
          <span style={{cursor:'pointer'}} onClick={handleLogout} className="dropdown-item">
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t("_SIGN_OUT_")}  </span>
          </span>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}


const mapStateToProps = state => {
  const {
    Auth
  } = state
  return {
    Auth
  }
};
/*
export default withRouter(
    connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)*/
export default withRouter(connect(mapStateToProps)(withTranslation()(ProfileMenu)));

