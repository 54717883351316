import {
    ADD_PARCEL_REQUEST,
    GET_PRODUCTS_REQUEST,
    GET_PRODUCTS_WITH_FLIGHT_ID_REQUEST,
    GET_PRODUCT_REQUEST,
    UPDATE_PRODUCT_DECLARATION_REQUEST,
    PRODUCT_NULL_REQUEST,
    ADD_PARCEL_WITH_FILE_REQUEST,
    UPDATE_PRODUCT_DECLARATION_REQUEST_WITH_FILE,
    GET_PRODUCTS_INTINITI_REQUEST,
    BTN_SPINNER_ACTIVE,
    PRODUCT_ONE_NULL_REQUEST
} from "./actionTypes"

export const addParcel = (data) => ({
    type:ADD_PARCEL_REQUEST,
    payload: data,
})

export const addParcelWithFile = (data) => ({
    type:ADD_PARCEL_WITH_FILE_REQUEST,
    payload: data,
})


export const getProducts = (data) => ({
    type:GET_PRODUCTS_REQUEST,
    payload: data,
})


export const getProductsWithFlightId = (data) => ({
    type:GET_PRODUCTS_WITH_FLIGHT_ID_REQUEST,
    payload: data,
})


export const getProduct = (data) => ({
    type:GET_PRODUCT_REQUEST,
    payload: data,
})

export const getProductInfiniti = (data) => ({
    type:GET_PRODUCTS_INTINITI_REQUEST,
    payload: data,
})

export const productNull = () => ({
    type:PRODUCT_NULL_REQUEST
})

export const productOneNull = () => ({
    type:PRODUCT_ONE_NULL_REQUEST
})


export const updateProductDeclaration = (data) => ({
    type:UPDATE_PRODUCT_DECLARATION_REQUEST,
    payload: data,
})


export const updateProductDeclarationWithFile = (data) => ({
    type:UPDATE_PRODUCT_DECLARATION_REQUEST_WITH_FILE,
    payload: data,
})

