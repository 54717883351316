import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Label, Row} from "reactstrap";

import {connect, useDispatch} from "react-redux";

//SimpleBar
import SimpleBar from "simplebar-react";

import {Link} from "react-router-dom";

import "./rightbar.scss";
//Import images

import {withTranslation} from "react-i18next";
import {showRightSidebarAddAmountAction} from "../../store/layout/actions";
import {addBalance} from "../../store/merchant/actions";
import GeorgiaBank from "../../assets/images/georgia-bank-logo.png";
import Oppa from "../../assets/images/oppa-logo.png";

const RightbarAddAmount = props => {
    const dispatch = useDispatch();

    const [toggle, setToggle] = useState(1)

    const [lari, setLari] = useState('')
    const [tetri, setTetri] = useState('00')
    const [lariErr, setlariErr] = useState(false)
    const [tetriErr, tetriErrErr] = useState(false)

    const handleAddAmount = () => {
        if (lari) {

            if (lari.substr(0, 1) === '0') {
                setlariErr(true)
                return
            }else {
                setlariErr(false)
            }
            if (!tetri) {
                tetriErrErr(true)
                return
            }else {
                if (tetri.length !== 2) {
                    tetriErrErr(true)
                    return
                } else {
                    tetriErrErr(false)
                }
            }

            if (lari.substr(0, 1) !== '0' && tetri.length === 2) {
                let amount = lari+tetri
                let item = {
                    amount,
                    lari,
                    tetri
                }
                dispatch(addBalance(item))
            }

        } else {
            setlariErr(true)
        }
    }


    const handleCloseForm = () => {
        dispatch(showRightSidebarAddAmountAction(false))
    }
    /*
        https://3dacq.georgiancard.ge/payment/start.wsm?ws.id=1w5ovivhxp6i0&page_id=B1069DCF8E094A406F0A5C82684652CA&account_id_gel=CEAA21277C5182E7AE1DE136F8A1558D&trx_id=E5BA19944804390A51241B53BC716E62&lang=ka&merch_id=066782F7975C5A14ED8B41B425D1E080&ccy=981&o.amount=100&o.order_id=c244d1afee1ec974be012f9eadb3c9df&o.axcode=3188&ts=20220425%2010:04:32&back_url_s=http://aligeorgia.ge/payments/success.php&back_url_f=http://aligeorgia.ge/payments/failed.php
        https://3dacq.georgiancard.ge/payment/start.wsm?ws.id=17nb7zvdaqazf&page_id=B1069DCF8E094A406F0A5C82684652CA&account_id_gel=CEAA21277C5182E7AE1DE136F8A1558D&trx_id=E5BA19944804390A51241B53BC716E62&lang=ka&merch_id=066782F7975C5A14ED8B41B425D1E080&ccy=981&o.amount=1.0&o.order_id=626fde2696d1b152f95a6937&o.axcode=AX03188&ts=202201%2016:0:i&back_url_s=http://aligeorgia.ge/payments/success.php&back_url_f=http://aligeorgia.ge/payments/failed.php
    */

    return (
        <React.Fragment>

            <div className="right-bar">
                <SimpleBar style={{height: "900px"}}>
                    <div data-simplebar className="h-100">
                        <div className="rightbar-title d-flex align-items-center px-3 py-4">
                            <h5 className="m-0 me-2" style={{paddingLeft:'10px'}}>{props.t("_ADD_BALANCE_")}</h5>
                            <Link
                                to="#"
                                onClick={handleCloseForm}
                                className="right-bar-toggle ms-auto"
                            >
                                <i className="mdi mdi-close noti-icon"/>
                            </Link>
                        </div>

                        <hr className="my-0"/>
                        <div className="p-4">
                            <Row>
                                <Col md={12}>
                                    <div
                                        onClick={()=> setToggle(1)}
                                        style={toggle === 1 ? {height:'70px', border :'3px solid #5b73e8', borderRadius:'5px', backgroundColor:'#d7ddf9'} : {height:'70px', border :'0px solid #5b73e8', borderRadius:'5px', backgroundColor:'#ebebeb'} }>
                                        <div style={{float:'left', paddingLeft:'20px', paddingTop:'5px'}}>
                                            <img style={{height:'55px'}} src={GeorgiaBank}/>
                                        </div>
                                        <div style={{float:'left', }}>
                                            <p style={{ marginTop:'21px', paddingLeft:'10px'}}>
                                                <strong> Bank of Georgia</strong>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="p-2">

                            </Row>
                            {/*
                                        <Row>
                                            <Col md={12}>
                                                <div
                                                    onClick={()=> setToggle(2)}
                                                    style={toggle === 2 ? {height:'70px', border :'3px solid #5b73e8', borderRadius:'5px', backgroundColor:'#d7ddf9'} : {height:'70px', border :'0px solid #5b73e8', borderRadius:'5px', backgroundColor:'#ebebeb'} }>
                                                    <div style={{float:'left', paddingLeft:'20px', paddingTop:'5px'}}>
                                                        <img style={{height:'55px'}} src={Oppa}/>
                                                    </div>
                                                    <div style={{float:'left', }}>
                                                        <p style={{ marginTop:'21px', paddingLeft:'10px'}}>
                                                            <strong>PayBox </strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>*/}
                            <Row>
                                <Col md={12}>
                                    <hr/>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Row>
                                            <Col  xs={8}  >
                                                <Label className="form-label"
                                                       htmlFor="formrow-nameKa-input">  {props.t("_LARI_")}</Label>
                                                <Input
                                                    maxLength={4}
                                                    value={lari}
                                                    style={lariErr ? {borderColor: 'red'} : null}
                                                    onChange={(e) => setLari(e.target.value.replace(/[^0-9]/g, ''))}
                                                    type="text" className="form-control" id="formrow-nameKa-input"/>
                                                {
                                                    lariErr === true
                                                        ? <div style={{color: 'red'}}>
                                                            <i>
                                                                შეიყვანეთ თანხა
                                                            </i>
                                                        </div>
                                                        : null
                                                }

                                            </Col>
                                            <Col  xs={4} >
                                                <Label className="form-label"
                                                       htmlFor="formrow-nameKa-input">  {props.t("_TETRI_")}</Label>

                                                <Input
                                                    maxLength={2}
                                                    value={tetri}
                                                    onChange={(e) => setTetri(e.target.value.replace(/[^0-9]/g, ''))}
                                                    type="text" className="form-control" id="formrow-nameKa-input"/>
                                                {
                                                    tetriErr === true
                                                        ? <div style={{color: 'red'}}>
                                                            <i>
                                                                შეიყვანეთ თეთრები
                                                            </i>
                                                        </div>
                                                        : null
                                                }
                                            </Col>

                                        </Row>

                                    </div>
                                </Col>
                            </Row>

                            <div className="d-grid gap-2">
                                <Button
                                    onClick={handleAddAmount}
                                    color="primary"
                                    className="btn btn-lg btn-success waves-effect waves-light btn btn-secondary"
                                >
                                    {props.t("_ADD_BALANCE_")}
                                </Button>


                            </div>
                        </div>

                    </div>
                </SimpleBar>
            </div>
            <div className="rightbar-overlay"/>



        </React.Fragment>
    );
};


const mapStateToProps = state => {
    const {
        Auth,
        Layout,
        Merchant
    } = state
    return {
        Auth,
        Layout,
        Merchant
    }
};

export default connect(mapStateToProps)(withTranslation()(RightbarAddAmount));
