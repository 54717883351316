import {call, put, takeEvery} from "redux-saga/effects"
import {
    GET_NOTIFICATION_REQUEST,
    GET_NOTIFICATION_REQUEST_SUCCESS,
    GET_NOTIFICATION_DETAIL_REQUEST,
    GET_NOTIFICATION_DETAIL_REQUEST_SUCCESS,
    GET_NOTIFICATION_REQUEST_ALL_SUCCESS,
    GET_NOTIFICATION_ALL_REQUEST,
    UPDATE_MARK_ALL_AS_READ_REQUEST, UPDATE_MARK_ALL_AS_READ_REQUEST_SUCCESS
} from "./actionTypes"


function* getNotificationSaga({payload}) {
    try {
        const callBack = yield call(getNotificationSagaAsync, payload)
        if (callBack.success) {
            yield put({type: GET_NOTIFICATION_REQUEST_SUCCESS, callBack})
        } else {

        }
    } catch (err) {
        console.error(err);
    }
}


const getNotificationSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/notification/gets`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}


function* getNotificationDetailSaga({payload}) {
    try {
        const callBack = yield call(getNotificationDetailSagaAsync, payload)
        if (callBack.success) {
            yield put({type: GET_NOTIFICATION_DETAIL_REQUEST_SUCCESS, callBack})
        } else {

        }
    } catch (err) {
        console.error(err);
    }
}


const getNotificationDetailSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/notification/get/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}


function* getNotificationAllSaga({payload}) {
    try {
        const callBack = yield call(getNotificationAllSagaAsync, payload)
        if (callBack.success) {
            yield put({type: GET_NOTIFICATION_REQUEST_ALL_SUCCESS, callBack})
        } else {

        }
    } catch (err) {
        console.error(err);
    }
}


const getNotificationAllSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/notification/all`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* markAllAsReadSaga({payload}) {
    try {
        yield put({type: UPDATE_MARK_ALL_AS_READ_REQUEST_SUCCESS})

        const callBack = yield call(markAllAsReadSagaAsync, payload)
        if (callBack.success) {
            yield put({type: UPDATE_MARK_ALL_AS_READ_REQUEST_SUCCESS, callBack})
        } else {

        }
    } catch (err) {
        console.error(err);
    }
}

const markAllAsReadSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/notification/markAllAsRead`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}


function* pageSaga() {
    yield takeEvery(GET_NOTIFICATION_REQUEST, getNotificationSaga)
    yield takeEvery(GET_NOTIFICATION_DETAIL_REQUEST, getNotificationDetailSaga)
    yield takeEvery(GET_NOTIFICATION_ALL_REQUEST, getNotificationAllSaga)
    yield takeEvery(UPDATE_MARK_ALL_AS_READ_REQUEST, markAllAsReadSaga)
}

export default pageSaga
