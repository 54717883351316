import React from "react";
import {Card, CardBody, CardImg, CardTitle, Col, Container, Row} from "reactstrap";
import Schedule from "../../components/Schedule";
import {Link} from "react-router-dom";
const SchedulePage = (props) => {

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Row>
                        <Col md={12}>
                            <Schedule/>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )

}

export default SchedulePage
