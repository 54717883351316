import React, {useState} from 'react';
import {Button, CardBody, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";

import {connect, useDispatch} from "react-redux";

//SimpleBar
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";

import "./rightbar.css";
//Import images

import {withTranslation} from "react-i18next";
import {showRightSidebarAddAmountAction, showRightSidebarForgetPasswordAction} from "../../store/layout/actions";
import Dropzone from "react-dropzone";
import Switch from "react-switch";
import {addParcel} from "../../store/products/actions";
import {notification} from 'antd';
import {validateEmail} from "../../helpers/regexs";
import {forgetPassword, signUp} from "../../store/auth/actions";


/*

const modalError = type => {
  notification[type]({
    message: 'შეცდომა',
    description: 'პროდუქტი უკვე დამატებულია',
  });
};
*/

const RightbarForgetPassword = props => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('')
  const [emailErr, setEmailErr] = useState(false)


  const modalError = type => {
    notification[type]({
      message: 'შეცდომა',
      description: 'წითლათ მონიშნული ველები აუცილებლად უნდა შეივსოს',
    });
  };


  const handleSendNewPassword = () =>{

    if (!email) {
      setEmailErr(true)
    } else {
      if (validateEmail(email)) {
        setEmailErr(false)
      } else {
        setEmailErr(true)
      }
    }

    if (email && validateEmail(email)) {
      let item = {
        email
      }
      dispatch(forgetPassword(item))


    }else {

    }



  }


  const handleCloseForm =() =>{
    dispatch(showRightSidebarForgetPasswordAction(false))
  }


  return (
    <React.Fragment>
      <div className="right-bar">
        <SimpleBar style={{ height: "900px" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title d-flex align-items-center px-3 py-4">
              <h5 className="m-0 me-2"  style={{paddingLeft:'10px'}}>პაროლის აღდგენა</h5>
              <Link
                to="#"
                onClick={handleCloseForm}
                className="right-bar-toggle ms-auto"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
            </div>

            <hr className="my-0" />

            <div className="p-4">

              <Row>


                <Col md={12}>
                  <div className="mb-3">
                    <Row>
                      <Col md={12}>
                        <Label className="form-label" htmlFor="formrow-nameKa-input"> {props.t("_EMAIL_")} </Label>

                        <Input
                            value={email}
                            style={emailErr ? {borderColor: 'red'} : null}
                            onChange={(e) => setEmail(e.target.value)}
                            type="text" className="form-control" id="formrow-nameKa-input" />
                      </Col>

                    </Row>

                  </div>
                </Col>
              </Row>

              <div className="d-grid gap-2">
                <Button
                    onClick={handleSendNewPassword}
                    color="primary"
                    className="btn btn-primary btn-lg btn-block waves-effect waves-light mb-1"
                >
                 პაროლის აღდგენა
                </Button>
              </div>
            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay" />
    </React.Fragment>
  );
};


const mapStateToProps = state => {
  const {
    Layout,
  } = state
  return {
    Layout,
  }
};

export default connect(mapStateToProps)(withTranslation()(RightbarForgetPassword));
