import {call, put, takeEvery} from "redux-saga/effects"
import {
    GET_INVOICE_REQUEST,
    GET_INVOICE_REQUEST_SUCCESS
} from "./actionTypes"

function* getInvoiceSaga({payload}) {
    try {
        const callBack = yield call(getInvoiceSagaAsync, payload)
        if (callBack.success) {
            yield put({type: GET_INVOICE_REQUEST_SUCCESS, callBack})
        }
    } catch (err) {
        console.error(err);
    }
}


const getInvoiceSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/invoice/get/${item.flightId}/${item.branch}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}


function* flightSaga() {
    yield takeEvery(GET_INVOICE_REQUEST, getInvoiceSaga)
}

export default flightSaga
