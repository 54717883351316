import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

const Layout = (props) => {

  return (
      <React.Fragment>
          {props.children}
      </React.Fragment>
  )

}


export default withRouter(Layout)
