import {
    GET_OFFICES_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    array : [],
}

const Offices = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_OFFICES_REQUEST_SUCCESS:

            return {
                ...state,
                array: action.callBack.offices,
            }

        default:
            return state
    }
}

export default Offices
