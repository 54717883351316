import React, {Fragment, useEffect, useState} from "react";
import {Card, CardBody, CardImg,   CardText,  Col, Container, Row} from "reactstrap";

import img2 from "../../assets/images/aligeogriaa.png";
import img3 from "../../assets/images/turketi.png";
import img4 from "../../assets/images/usa-georgia.png";
import {tariffeType} from "../../helpers/types";
import {withTranslation} from "react-i18next";

const TarrifsPage = (props) => {

    const [tariffType, setTarffType] = useState(1)
    const [tariff, setTarff] = useState(null)


    useEffect(() => {
        tariffFetchData();
    }, []);

    const tariffFetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/tariffs/gets`);
            const json = await response.json();
            setTarff(json.tariffs)
        } catch (error) {
            console.log("error", error);
        }
    };
    return (
        <React.Fragment>
            <div className="my-5 pt-sm-5 three-rem">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Row className="g-0 align-items-center">
                                    <Col md={4}>
                                        <CardImg className="img-fluid" src={img2} alt="Card image cap"/>
                                    </Col>
                                    <Col md={8}>
                                        <CardBody>
                                            <h1 className="h1">
                                                {props.t("_CHINA_")} - {props.t("_GEORGIA_")}</h1>
                                            <hr/>

                                            {
                                                tariff !== null
                                                    ?
                                                    <>
                                                        {
                                                            tariff.map((item, key) => {
                                                                return (
                                                                    <Fragment key={key}>
                                                                        {
                                                                            item.country === 1
                                                                                ?
                                                                                <div>
                                                                                    <div style={{float: 'left', minWidth: '25%'}}>
                                                                                        <p><strong>{tariffeType(item.type, props)}:</strong></p>
                                                                                    </div>


                                                                                    <div>
                                                                                        <p>
                                                                                            1 {props.t("_KG_")} - {item.amount} USD
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    : null
                                            }
                                            <CardText>
                                                <small className="text-muted">
                                                    <strong><i> {props.t("_THE_TARIFF_FOR_PARCELS_WEIGHING_50KG_AND_MORE_IS_INDIVIDUAL_")}
                                                    </i></strong>
                                                </small>
                                            </CardText>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col lg={12}>
                            <Card>
                                <Row className="g-0 align-items-center">
                                    <Col md={4}>
                                        <CardImg className="img-fluid" src={img3} alt="Card image cap"/>
                                    </Col>

                                    <Col md={8}>
                                        <CardBody>
                                            <h1 className="h1">{props.t("_TURKEY_")} - {props.t("_GEORGIA_")}</h1>
                                            <hr/>
                                            {
                                                tariff !== null
                                                    ?
                                                    <>
                                                        {
                                                            tariff.map((item, key) => {
                                                                return (
                                                                    <Fragment key={key}>
                                                                        {
                                                                            item.country === 3
                                                                                ?
                                                                                <div>
                                                                                    <div style={{float: 'left', minWidth: '25%'}}>
                                                                                        <p><strong>{tariffeType(item.type, props)}:</strong></p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p>
                                                                                            1 {props.t("_KG_")} - {item.amount} USD
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    : null
                                            }
                                            <CardText>
                                                <small className="text-muted">
                                                    <strong><i>     {props.t("_THE_TARIFF_FOR_PARCELS_WEIGHING_50KG_AND_MORE_IS_INDIVIDUAL_")}
                                                    </i></strong>
                                                </small>
                                            </CardText>
                                        </CardBody>
                                    </Col>

                                </Row>
                            </Card>
                        </Col>

                        <Col lg={12}>
                            <Card>
                                <Row className="g-0 align-items-center">
                                    <Col md={4}>
                                        <CardImg className="img-fluid" src={img4} alt="Card image cap"/>
                                    </Col>

                                    <Col md={8}>
                                        <CardBody>
                                            <h1 className="h1">{props.t("_USA_")} - {props.t("_GEORGIA_")}</h1>
                                            <hr/>
                                            {
                                                tariff !== null
                                                    ?
                                                    <>
                                                        {
                                                            tariff.map((item, key) => {
                                                                return (
                                                                    <Fragment key={key}>
                                                                        {
                                                                            item.country === 2
                                                                                ?
                                                                                <div>
                                                                                    <div style={{float: 'left', minWidth: '25%'}}>
                                                                                        <p><strong>{tariffeType(item.type, props)}:</strong></p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p>
                                                                                            1 {props.t("_KG_")} - {item.amount} USD
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    : null
                                            }
                                            <CardText>
                                                <small className="text-muted">
                                                    <strong><i>     {props.t("_THE_TARIFF_FOR_PARCELS_WEIGHING_50KG_AND_MORE_IS_INDIVIDUAL_")}
                                                    </i></strong>
                                                </small>
                                            </CardText>
                                        </CardBody>
                                    </Col>

                                </Row>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}
export default withTranslation()(TarrifsPage);

