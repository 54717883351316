import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import { withRouter, Link } from "react-router-dom"
// import {FormattedMessage} from 'react-intl'

import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useState} from "react";
import {showRightSidebarAction, showRightSidebarForgetPasswordAction} from "../../store/layout/actions";
import {signIn} from "../../store/auth/actions";
import {Col, Input, Label} from "reactstrap";

const NotSignInForm = (props) =>{
    const dispatch = useDispatch();
    const [eye, setEye] = useState(true)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailERR, setEmailERR] = useState(false)
    const [passwordERR, setPasswordERR] = useState(false)

    const handleEye = () =>{
        setEye(!eye)
    }

    const handleSignInForm = () => {
        dispatch(showRightSidebarAction(true))
    }

    const handleForgetPassword = () => {
        dispatch(showRightSidebarForgetPasswordAction(true))
    }

    const handleSignIn = () => {

        if (!email) {
            setEmailERR(true)
        } else {
            setEmailERR(false)
        }
        if (!password) {
            setPasswordERR(true)
        } else {
            setPasswordERR(false)
        }

        if (email && password) {
            let item = {
                email: email,
                password: password,
            }
            props.dispatch(signIn(item))
            props.history.push('/home');

        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (email && password) {
                let item = {
                    email: email,
                    password: password,
                }
                props.dispatch(signIn(item))
            }
        }
    }


    return (
        <div className="p-2 mt-4">
            <AvForm className="form-horizontal">
                <div className="mb-3" style={{color: 'white'}}>
                    <Label className="form-label" htmlFor="formrow-nameKa-input">{props.t("_AX_OR_EMAIL_")}</Label>

                    <Input
                        value={email}
                        onKeyDown={handleKeyDown}
                        style={emailERR ? {borderColor: 'red'} : null}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text" className="form-control" id="formrow-nameKa-input" />
                </div>


                <Col md={12}>

                    <div className="mb-3" style={{position:'relative'}}>
                        <div className="float-end">
                            <a href={null}
                              onClick={handleForgetPassword}
                               style={{color: '#bbb40e', cursor:"pointer"}}> {props.t("_FORGOT_YOUR_PASSWORD_")} </a>

                        </div>
                        <Label className="form-label"
                               style={{color:'white'}}  > {props.t("_PASSWORD_")}</Label>
                        <Input type={eye ? 'password' : 'text'}
                               value={password}
                               onKeyDown={handleKeyDown}
                               style={passwordERR ? {borderColor: 'red'} : null}
                               onChange={(e) => setPassword(e.target.value)}
                               className="form-control" id="formrow-password-input"/>
                        <div style={{position:'absolute', right:'10px', top:'33px'}}
                             onClick={handleEye}
                        >
                            <i className={eye ?'uil-eye-slash' : 'uil-eye'} style={{fontSize:'20px', cursor:"pointer"}}></i>
                        </div>

                    </div>
                </Col>

                <div className="form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                    />
                    <label
                        style={{color: 'white'}}
                        className="form-check-label"
                        htmlFor="customControlInline"
                    >
                        {props.t("_REMEMBER_ME_")}
                    </label>
                </div>
                <div style={{padding: '10px'}}>

                </div>
            </AvForm>
            <div className={'row'}>
                <div className={'col'}>
                    <button

                        onClick={handleSignIn}
                        style={{width: '100%'}}
                        className="btn btn-primary w-sm waves-effect waves-light"
                        type="submit"
                    >
                        {props.t("_SIGN_IN_")}
                    </button>
                </div>
                <div className={'col'}>
                    <button

                        onClick={handleSignInForm}

                        style={{width: '100%'}}
                        className="btn btn-danger w-sm waves-effect waves-light"
                        type="submit"
                    >
                        {props.t("_SIGN_UP_")}
                    </button>
                </div>
            </div>




        </div>
    )
}

// export default withRouter(withTranslation()(SidebarContent))
const mapStateToProps = state => {
    const {
        Layout,
        Auth
    } = state
    return {
        Layout,
        Auth
    }
};
export default withRouter(connect(mapStateToProps)(withTranslation()(NotSignInForm)));

