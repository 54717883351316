import React from "react";
import {Card, CardBody, CardImg, CardText, Col, Container, Row} from "reactstrap";
import img2 from "../../assets/images/aligeorgia.png";
import img3 from "../../assets/images/small/img-3.jpg";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

const ContactPage = (props) => {

    return (
        <React.Fragment>
            <div className="my-5 pt-sm-5">
                <Container fluid>
                    <Row className="g-0 align-items-center" style={{textAlign:'center'}}>
                        <Col md={12} style={{paddingTop:'100px'}}>

                            <CardImg className="img-fluid" src={img2}  style={{width:'500px'}} alt="Card image cap"/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}

export default  withTranslation()(ContactPage)
