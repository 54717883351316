import {
    UPDATE_BRANCHES_REQUEST
} from "./actionTypes"


export const updateBranches = (data) => ({
    type: UPDATE_BRANCHES_REQUEST,
    payload: data
})

