import {call, put, takeEvery} from "redux-saga/effects"
import {
    GET_NEWS_DETAIL_REQUEST, GET_NEWS_DETAIL_REQUEST_SUCCESS,
    GET_NEWS_REQUEST,
    GET_NEWS_REQUEST_SUCCESS
} from "./actionTypes"

function* getNewsSaga({payload}) {
    try {

        const callBack = yield call(getNewsSagaAsync, payload)
        if (callBack.success) {
            yield put({type: GET_NEWS_REQUEST_SUCCESS, callBack})
        } else {

        }
    } catch (err) {
        console.error(err);
    }
}


const getNewsSagaAsync = async (item) => {

    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/news/gets/${item.skip}/${item.limit}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    })
    return await response.json()
}


function* getNewsDetailSaga({payload}) {
    try {
        const callBack = yield call(getNewsDetailSagaSaga, payload)
        if (callBack.success) {
            yield put({type: GET_NEWS_DETAIL_REQUEST_SUCCESS, callBack})
        } else {

        }
    } catch (err) {
        console.error(err);
    }
}


const getNewsDetailSagaSaga = async (item) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/news/get/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    })
    return await response.json()
}


function* newsSaga() {
    yield takeEvery(GET_NEWS_REQUEST, getNewsSaga)
    yield takeEvery(GET_NEWS_DETAIL_REQUEST, getNewsDetailSaga)
}

export default newsSaga
