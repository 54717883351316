import {
    COURIER_NULL,
    CREATE_COURIER_REQUEST,
    GET_COURIER_FLIGHTS_REQUEST, GET_COURIER_PARCELS_REQUEST
} from "./actionTypes"

export const courirerNull = (data) => ({
    type: COURIER_NULL,
    payload: data
})

export const getCouriersFlights = (data) => ({
    type: GET_COURIER_FLIGHTS_REQUEST,
    payload: data
})

export const getCouriersParcels = (data) => ({
    type: GET_COURIER_PARCELS_REQUEST,
    payload: data
})


export const createCourier = (data) => ({
    type: CREATE_COURIER_REQUEST,
    payload: data
})

