import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import translationKa from "./locales/ka/translation.json"
import translationRU from "./locales/ru/translation.json"
import translationENG from "./locales/eng/translation.json"

// the translations
const resources = {

  ka: {
    translation: translationKa,
  },
  ru: {
    translation: translationRU,
  },
  en: {
    translation: translationENG,
  },
}

const language = localStorage.getItem("I18N_LANGUAGE")
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "ka")
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "ka",
    keySeparator: true, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
