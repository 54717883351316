import {
    GET_NOTIFICATION_REQUEST_SUCCESS,
    GET_NOTIFICATION_DETAIL_REQUEST_SUCCESS,
    GET_NOTIFICATION_REQUEST_ALL_SUCCESS,
    UPDATE_MARK_ALL_AS_READ_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    arrayAll : [],
    arrayAllCount : 0,
    array : [],
    post : {},
    arrayCount : 0,
}

const Popap = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_MARK_ALL_AS_READ_REQUEST_SUCCESS:
            return {
                ...state,
                arrayCount :  0
            }

        case GET_NOTIFICATION_REQUEST_SUCCESS:
            return {
                ...state,
                array :  action.callBack.notifications,
                arrayCount :  action.callBack.nonotificationLength
            }

        case GET_NOTIFICATION_REQUEST_ALL_SUCCESS:
            return {
                ...state,
                arrayAll :  action.callBack.notifications,
                arrayAllLength :  action.callBack.nonotificationLength
            }

        case GET_NOTIFICATION_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                post: action.callBack.post,
            }
        default:
            return state
    }
}

export default Popap
