import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {Card, CardBody, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useState, useEffect} from "react";
import {getRates} from "../store/rate/actions";

const Rates = (props) => {
    const dispatch = useDispatch();
    const [sum, setSum] = useState('')
    const [amount, setAmount] = useState( )
    const [currency, setCurrency] = useState('USD')

    useEffect(() => {
        dispatch(getRates())
    }, [])

    const handleChangeAmount = (e) =>{
        setAmount(e)

    }
    const handleChangeCurrency = (e) =>{
        setCurrency(e)
    }

    function amountCalculate () {
        if (currency === 'USD') {
            setSum( (amount * props.Rates.USD ).toFixed(2))
        }
       else if (currency === 'EUR') {
            setSum((amount * props.Rates.EUR).toFixed(2))
        }
        else if (currency === 'TRY') {
            setSum((amount * props.Rates.TRY).toFixed(2))
        }
        else if (currency === 'CNY') {
             setSum((amount * props.Rates.CNY/10).toFixed(2))
        }
    }

    useEffect(()=>{
        if (amount) {
            amountCalculate ()
        }

    },[amount,currency])
    return (
        <Card>
            <CardBody>
                {
                    props.type
                        ?    <h4 className="card-title"> {props.t("_CURRENCY_CONVERTER_")} </h4>
                        : null
                }

                <AvForm className="needs-validation">
                    <Row>
                        <Col md="8">
                            <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">{props.t("_AMOUNT_")} </Label>
                                <input
                                    className="form-control"
                                    value={amount}
                                    type="text"
                                    onChange={(e) => handleChangeAmount(e.target.value.replace(/[a-z,!@#$%^&*()/]/, ''))}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">{props.t("_CURRENCY_")} </Label>
                                <select
                                    onChange={(e) => handleChangeCurrency(e.target.value)}
                                    className="form-control form-control-border">
                                    <option value="USD">USD</option>
                                    <option value="EUR">EUR</option>
                                    <option value="CNY">CNY</option>
                                    <option value="TRY">TRY</option>
                                </select>
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">GEL</Label>
                                <input
                                    className="form-control"
                                    value={amount ?sum : ''}
                                    type="text"
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </AvForm>
            </CardBody>
        </Card>
    )
}

const mapStateToProps = state => {
    const {
        Auth,
        Rates
    } = state
    return {
        Auth,
        Rates
    }
};

export default connect(mapStateToProps)(withTranslation()(Rates));
