import React, {useState, useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {Button, Card, CardBody, CardTitle, Col, Container, Nav, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {showRightSidebarAddAmountAction} from "../../store/layout/actions";
import {paymentAll} from "../../store/payment/actions";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
const AuthHeader = (props) =>{
    const dispatch = useDispatch();
    const handleAddAmountl = () =>{
        dispatch(showRightSidebarAddAmountAction(true))
    }

    const handlePayAll = () =>{
       if ( props.Auth.debt > 0) {
            dispatch(paymentAll(props.Layout.parcelStatus))
        }
    }

    return (
        <>
            <Container className='div-none-mob' fluid>
                <Row className=''>
                    <Col xl={12}>
                        <Card>
                            <CardBody>
                                <CardTitle >
                                    <div className={'row'} style={{textAlign:'center', color:'black'}}>
                                        <div className="col-6"> <h5> {props.t("_HELLO_")}  {capitalizeFirstLetter (props.Auth.candidate.information.nameEn)} </h5> </div>

                                        <div className="col-6"> <h5  >{props.t("_ROOM_NUMBER_")}: <span style={{color :'#bbb40e'}}> {props.Auth.candidate.AXID} </span></h5> </div>
                                        <div className="col-12"> <hr style={{height:'1px', }}/> </div>

                                    </div>
                                    <div className={'row '} >
                                        <div className="col-6" > <h6 style={{textAlign:'center',fontSize :'1rem'}}>{props.t("_BALANCE_")}: <br/> <span style={{color:'#34c38f'}}> {props.Auth.candidate.balance.toFixed(2)} ₾ </span>  </h6> </div>
                                        <div className="col-6" > <h6 style={{textAlign:'center',fontSize :'1rem'}}>{props.t("_DEBT_")}:<br/> <span style={{color:'red'}}>{props.Auth.debt.toFixed(2)}  ₾</span> </h6> </div>
                                        <div className="col-12"> <hr style={{height:'1px', }}/> </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={'col'}>
                                            <button
                                                onClick={handlePayAll}
                                                style={{width:'100%'}}
                                                className="btn btn-success w-sm waves-effect waves-light"
                                                type="submit"
                                            >
                                                {props.t("_PAY_")}
                                            </button>
                                        </div>
                                        <div className={'col'}>
                                            <button
                                                onClick={handleAddAmountl}
                                                style={{width:'100%'}}
                                                className="btn btn-warning w-sm waves-effect waves-light"
                                                type="submit"
                                            >
                                                <i className="uil uil-plus"></i>{" "}   {props.t("_ADD_BALANCE_PLUS_")}
                                            </button>
                                        </div>
                                    </div>
                                </CardTitle>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </>
    )
}

const mapStateToProps = state => {
    const {
        Layout,
        Auth
    } = state
    return {
        Layout,
        Auth
    }
};

export default connect(mapStateToProps)(withTranslation()(AuthHeader));
