import {call, put, takeEvery} from "redux-saga/effects"
import {
    GET_RATES_REQUEST,
    GET_RATES_REQUEST_SUCCESS
} from "./actionTypes"

function* getRatesSaga({payload}) {
    try {
        const callBack = yield call(getRatesSagaAsync, payload)
        if (callBack.success) {
            yield put({type: GET_RATES_REQUEST_SUCCESS, callBack})
        } else {

        }
    } catch (err) {
        console.error(err);
    }
}


const getRatesSagaAsync = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/rate/get`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    })
    return await response.json()
}


function* newsSaga() {
    yield takeEvery(GET_RATES_REQUEST, getRatesSaga)
}

export default newsSaga
