import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import Moment from 'react-moment';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row, Spinner,
    Table
} from "reactstrap";
import {isEmpty} from "lodash";
import {useReactToPrint} from "react-to-print";
import logo from "../../assets/images/logo.png";
import {getInvoice} from "../../store/invoice/actions";
import {withTranslation} from "react-i18next";
import ReactTooltip from "react-tooltip";
// import Nulled from "../../components/Null/Nulled";


const Invoice = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        const {flightId, branch} = props.match.params;
        let item = {
            flightId,
            branch
        }
        dispatch(getInvoice(item))
    }, [props.Invoice.invoice._id])
//props.invoices.invoice._id

    const componentRef = React.useRef(null);

    const onBeforeGetContentResolve = React.useRef(null);

    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        console.log("`onBeforePrint` called"); // tslint:disable-line no-console
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
        setLoading(true);
        setText("Loading new text...");

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 2000);
        });
    }, [setLoading, setText]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "AwesomeFileName",
        onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    });

    React.useEffect(() => {
        if (
            text === "New, Updated Text!" &&
            typeof onBeforeGetContentResolve.current === "function"
        ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                  {
                        props.Invoice.isLoading
                        ?    <div style={{textAlign:'center'}}>
                                <Spinner className="m-1" color="primary"/>
                            </div>
                            : <>
                                {!isEmpty(props.Invoice.invoice._id) && (
                                    <Row>
                                        <div>
                                            {loading && <p className="indicator"> Loading...</p>}
                                            <button
                                                className="btn btn-success waves-effect waves-light me-1"
                                                onClick={handlePrint}>
                                                <i className="fa fa-print"></i>  {props.t("_PRINT_")}
                                            </button>
                                        </div>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <Col lg="12">
                                            <Card>
                                                <CardBody className='invoice-gl invoice-div-card-body'>
                                                    <div ref={componentRef} className={'invoice-div'} >
                                                        <table style={{width: '100%', }} >
                                                            <tr>
                                                                <th style={{width:'50%'}}>
                                                                    <div className="text-muted">
                                                                        <p className="mb-1">{props.t("_INVOICE_ALIGEORGIA_")}:</p>
                                                                        <p className="mb-1">{props.t("_INVOICE_VAT_")}</p>
                                                                        <p className="mb-1">{props.t("_INVOICE_ADDRESS_")}</p>
                                                                        <p className="mb-1">{props.t("_INVOICE_ADD_")}</p>
                                                                    </div>

                                                                </th>
                                                                <th style={{width:'50%', textAlign:'right'}}>
                                                                    <div className=" ">
                                                                        <div>
                                                                            <p className="mb-1">{props.t("_INVOICE_DATE_")}: <span>
                                                             <Moment format="YYYY-MM-DD"
                                                                     date={props.Invoice.invoice.createdAt}/>
                                                        </span></p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="mb-1">{props.t("_INVOICE_NUMBER_")}: <span>{props.Invoice.invoice.invoiceUnicId}</span>
                                                                            </p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="mb-1"><br/> </p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="mb-1"><br/> </p>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </table>
                                                        <table style={{width: '100%'}} >
                                                            <hr className="my-4"/>
                                                        </table>
                                                        <table style={{width: '100%'}} >
                                                            <tr>
                                                                <th style={{width:'50%'}}>
                                                                    <div className="text-muted">
                                                                        <p className="mb-1">{props.t("_INVOICE_BANK_")}</p>
                                                                        <p className="mb-1">{props.t("_INVOICE_IBAN_")}</p>
                                                                        <p className="mb-1">{props.t("_INVOICE_BANK_CODE_")} /</p>
                                                                        <p className="mb-1">{props.t("_INVOICE_DETAIL_")}</p>
                                                                        <p>{props.t("_INVOICE_TRANSPORT_PRICE_")}</p>
                                                                    </div>
                                                                </th>
                                                                <th style={{width:'50%', textAlign:'center'}}>
                                                                    <div className="mb-4">
                                                                        <img src={logo} alt="logo"
                                                                             className='invoice-logo'
                                                                        />
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </table>
                                                        <hr className="my-4"/>
                                                        <Row>
                                                            <Col>
                                                                <div className="text-muted">
                                                                    <h5 className="font-size-15"><strong>{props.t("_INVOICE_RECEIVER_")}</strong></h5>
                                                                    <p className="mb-1">
                                                                        <strong>{props.t("_INVOICE_NAME_")}: </strong>

                                                                        {
                                                                            parseInt(props.Invoice.invoice.additionalInformation.userType) === 1 || isEmpty(props.Invoice.invoice.additionalInformation.userType)
                                                                                ?   <span>{props.Invoice.invoice.additionalInformation.nameKa} {' '} {props.Invoice.invoice.additionalInformation.lastNameKa}</span>
                                                                                :null
                                                                        }
                                                                        {
                                                                            parseInt(props.Invoice.invoice.additionalInformation.userType) === 2
                                                                                ?  <span>{props.Invoice.invoice.additionalInformation.LTDName} </span>
                                                                                :null

                                                                        }
                                                                    </p>

                                                                    <p className="mb-1">
                                                                        <strong>{props.t("_INVOICE_ID_")}: </strong>
                                                                        {
                                                                            parseInt(props.Invoice.invoice.additionalInformation.userType) === 1 || isEmpty(props.Invoice.invoice.additionalInformation.userType)
                                                                                ?   <span>{props.Invoice.invoice.additionalInformation.passport} </span>
                                                                                :null
                                                                        }
                                                                        {
                                                                            parseInt(props.Invoice.invoice.additionalInformation.userType) === 2
                                                                                ?  <span>{props.Invoice.invoice.additionalInformation.orgId} </span>
                                                                                :null

                                                                        }

                                                                    </p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <br/>



                                                        <div className="table-responsive">
                                                            <Table className="table-nowrap table-centered mb-0">
                                                                <thead>
                                                                <tr>
                                                                    <th style={{width: "70px"}}>{props.t("_INVOICE_TRAKING_NUMBER_")}</th>
                                                                    <th>{props.t("_INVOICE_QUANTITY_")}</th>
                                                                    <th>{props.t("_INVOICE_WEIGHT_")}</th>
                                                                    <th>{props.t("_INVOICE_PRICE_")}</th>
                                                                    <th>{props.t("_INVOICE_NBG_")}</th>
                                                                    <th>{props.t("_INVOICE_AMOUNT_")}</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    props.Invoice.invoiceProducts.map((item, key) => {
                                                                        return (
                                                                            <>
                                                                                <tr key={key}>
                                                                                    <td>{item.productOrderId}</td>
                                                                                    <td style={{borderLeft: '1px solid #f5f6f8'}}></td>
                                                                                    <td style={{borderLeft: '1px solid #f5f6f8'}}>{item.weight}</td>
                                                                                    <td style={{borderLeft: '1px solid #f5f6f8'}}>{item.tariff}</td>
                                                                                    {
                                                                                        !isEmpty(item.rate)
                                                                                            ?
                                                                                            <>
                                                                                                <td style={{borderLeft: '1px solid #f5f6f8'}}>{item.rate.USD}</td>
                                                                                                <td style={{borderLeft: '1px solid #f5f6f8'}}>{(item.weight * item.tariff * item.rate.USD).toFixed(2)}</td>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <td style={{borderLeft: '1px solid #f5f6f8'}}></td>
                                                                                                <td style={{borderLeft: '1px solid #f5f6f8'}}></td>
                                                                                            </>
                                                                                    }
                                                                                </tr>
                                                                            </>
                                                                        )

                                                                    })
                                                                }
                                                                <tr>
                                                                    <td><strong>{props.t("_INVOICE_TOTAL_")}</strong></td>
                                                                    <td style={{borderLeft: '1px solid #f5f6f8'}}>
                                                                        <strong>{props.Invoice.invoice.additionalInformation.allQuantity}</strong>
                                                                    </td>
                                                                    <td style={{borderLeft: '1px solid #f5f6f8'}}>
                                                                        <strong>{props.Invoice.invoice.additionalInformation.allWeight.toFixed(2)}</strong>
                                                                    </td>
                                                                    <td style={{borderLeft: '1px solid #f5f6f8'}}>{}</td>
                                                                    <td style={{borderLeft: '1px solid #f5f6f8'}}>{}</td>
                                                                    {
                                                                        !isEmpty(props.Invoice.invoice.additionalInformation.allSum) || props.Invoice.invoice.additionalInformation.allSum > 0
                                                                            ? <td style={{borderLeft: '1px solid #f5f6f8'}}>
                                                                                <strong>{props.Invoice.invoice.additionalInformation.allSum.toFixed(2)} </strong>
                                                                            </td>
                                                                            : <td style={{borderLeft: '1px solid #f5f6f8'}}></td>
                                                                    }
                                                                </tr>


                                                                </tbody>


                                                            </Table>
                                                        </div>


                                                        <hr className="my-4"/>
                                                        <Row>
                                                            <Col>
                                                                <div className="text-muted">
                                                                    <h5 className="font-size-15"><strong>{props.t("_INVOICE_CONTACT_")}:</strong></h5>
                                                                    <p className="mb-1">{props.t("_INVOICE_WEB_")}: <span>www.aligeorgia.ge</span>
                                                                    </p>
                                                                    <p className="mb-1">{props.t("_INVOICE_EMAIL_")}: <span>info.aligeorgia@gmail.com</span>
                                                                    </p>
                                                                    <p className="mb-1">{props.t("_INVOICE_PHONE_")}: <span>(+032) 2 196 191</span></p>
                                                                    <p className="mb-1">{props.t("_INVOICE_THANKS_")}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>


                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            </>
                    }

                </Container>
            </div>
        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        Invoice,
    } = state
    return {
        Invoice,
    }
};
export default connect(mapStateToProps)(withTranslation()(Invoice));
