import React from "react"
import { Redirect } from "react-router-dom"

// // Pages Component
// Dashboard
import Home from "../pages/Home/index"
import Dashboard from "../pages/Dashboard/index"
import ServicesPage from "../pages/Pages/services"
import NewsPage from "../pages/Pages/news"
import NewsDetailPage from "../pages/Pages/newsDetail"
import NotificationDetailPage from "../pages/Pages/notificationDetail"
import NotificationAll from "../pages/Pages/notificationAll"
import TarrifsPage from "../pages/Pages/tarrifs"
import SchedulePage from "../pages/Pages/schedule"
import ContactPage from "../pages/Pages/contact"
import TermsPage from "../pages/Pages/terms"
import ProfilePage from "../pages/Profile/Profile"
import InvoicePage from "../pages/Invoices/index"
import Appstore from "../pages/Pages/appstore"
import ChangePasswordPage from "../pages/Profile/ChangePassword"
import AddBranches from "../pages/Profile/AddBranches"
// import SuccessPage from "../pages/Merchant/Success"
// import FailedPage from "../pages/Merchant/Failed"

import SuccessPage from "../pages/payments/success"
import FailedPage from "../pages/payments/failed"


import XMLcheckAvailable from "../pages/Merchant/checkAvailable"
import XMLregisterPayment from "../pages/Merchant/registerPayment"

import Pages404 from "../pages/Utility/pages-404"


const userRoutes = [

  { path: "/home", component: Home },
  { path: "/services", component: ServicesPage },
  { path: "/terms", component: TermsPage },
  { path: "/tarrifs", component: TarrifsPage },
  { path: "/schedule", component: SchedulePage },
  { path: "/news", component: NewsPage },
  { path: "/news-detail/:id", component: NewsDetailPage },
  { path: "/contact", component: ContactPage },
  { path: "/profile", component: ProfilePage },
  { path: "/change-password", component: ChangePasswordPage },
  { path: "/add-branches", component: AddBranches },
  { path: "/notification-detail/:id", component: NotificationDetailPage },
  { path: "/notification-all", component: NotificationAll },
  { path: "/invoice/:flightId/:branch?", component: InvoicePage },
  { path: "/appstore", component: Appstore },
  { path: "/payments/success", component: SuccessPage },
  { path: "/payments/failed", component: FailedPage },
    // { path: "/profile", component: UserProfile },
  { path: "/pages-404", component: Pages404 },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/home" /> },

  { path: "*", component: Pages404 },
]

const authRoutes = [

  { path: "/pages-404", component: Pages404 },

]
const xmlRoutes = [
  { path: "/checkAvailable", component: XMLcheckAvailable },
  { path: "/registerPayment/:id?", component: XMLregisterPayment },
]

export { userRoutes, authRoutes, xmlRoutes }
