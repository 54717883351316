import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Container, Input, Label, Row} from "reactstrap";
import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {changePassword,} from "../../store/auth/actions";
import {updateBranches} from "../../store/branches/actions";
import {incorectBranchAddress, incorectFormat} from "../../helpers/notification";

const AddBranches = (props) => {
    const dispatch = useDispatch();

    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')


    useEffect(() => {
        if (props.Auth.isAuthenticated === true) {
            if (props.Auth.candidate.takedPlaced.city === 'Tbilisi'){
                setCity('_TBILISI_')
            }else {
                setCity(props.Auth.candidate.takedPlaced.city)
                // setAddress('kutaisi_1')
            }

            if (props.Auth.candidate.takedPlaced.address === "qurdiani"){
                setAddress('didube')
            }else {
                setAddress(props.Auth.candidate.takedPlaced.address)
            }
        }

    }, [props.Auth.isAuthenticated])

    const handleChangeAddress = (e) =>{
        setCity(e)
        setAddress('')
    }


    const handleUpdateBranch = () => {
        if (address) {
            let item = {
                city,
                address
            }
            dispatch(updateBranches(item))
        }else {
            incorectBranchAddress('warning')
        }

    }


    return (
        <React.Fragment>
            <div className="my-5 pt-sm-5 three-rem">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    {
                                        props.Auth.candidate.AXID
                                            ?
                                            <>
                                                <Row>
                                                    <Col md={6}>
                                                        <Label className="form-label"
                                                               htmlFor="formrow-email-input">{props.t("_CITY_")}</Label>


                                                        <select value={city}
                                                                disabled={false}
                                                                onChange={(e) => handleChangeAddress(e.target.value)}
                                                                className="form-control form-control-border">
                                                            <option value="_TBILISI_" selected>{props.t("_TBILISI_")}</option>
                                                            {/*<option value="_KUTAISI_" selected>{props.t("_KUTAISI_")}</option>*/}
                                                        </select>
                                                    </Col>

                                                    <Col md={6}>
                                                        <Label className="form-label"
                                                               htmlFor="formrow-email-input">{props.t('_branch')}</Label>
                                                        <select value={address}
                                                                onChange={(e) => setAddress(e.target.value)}
                                                                className="form-control form-control-border">
                                                            {
                                                                city === '_TBILISI_'
                                                                ?
                                                                    <>
                                                                        <option value=""> {props.t('_Choose_a_branch')}</option>
                                                                        <option value="didube" >{props.t('_Branch_Address_1')}</option>
                                                                        <option value="gldani" >{props.t('_Branch_Address_2')}</option>
                                                                        <option value="isani" >{props.t('_Branch_Address_3')}</option>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <option value="">{props.t('_Choose_a_branch')}</option>
                                                                        <option value="kutaisi_1" >{props.t('kutaisi_1')}</option>
                                                                    </>
                                                            }

                                                        </select>
                                                    </Col>
                                                    <Col md={12}>
                                                        <br/>
                                                        <div className="button-items">
                                                            <Button
                                                                onClick={handleUpdateBranch}
                                                                color="primary"
                                                                className="btn btn-primary waves-effect waves-light"
                                                            >
                                                                {props.t('_SAVE_')}
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                            : null
                                    }


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    const {
        Auth,
        Layout
    } = state
    return {
        Auth,
        Layout
    }
};

export default connect(mapStateToProps)(withTranslation()(AddBranches));
