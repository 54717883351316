import PropTypes from 'prop-types';
import React from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
// import MessengerCustomerChat from 'react-messenger-customer-chat';
// Import Routes all
import { userRoutes, authRoutes, xmlRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/antd.css"
import "./assets/scss/style.css"
// Import Firebase Configuration file props.t("_AX_OR_EMAIL_")
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// Import Fack Backend


// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = props => {
  function getLayout() {
    return VerticalLayout;
  }

  function getLayout2() {

    return HorizontalLayout;
  }


  const Layout = getLayout();
  const Layout2 = getLayout2();
  return (
    <React.Fragment>
      <Router>
        {/*<MessengerCustomerChat*/}
        {/*    pageId="577726038972441"*/}
        {/*    appId="1163036901154297"*/}
        {/*/>,*/}
        <Switch>

          {xmlRoutes.map((route, idx) => (
              <Authmiddleware
                  path={route.path}
                  layout={Layout2}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
              />
          ))}

        {authRoutes.map((route, idx) => (
              <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  exact
              />
          ))}

          {userRoutes.map((route, idx) => (
              <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
              />
          ))}

        </Switch>

      </Router>
 {/*     <Router>
        <Switch>
          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>

      </Router>*/}
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
