import React, {useEffect} from "react";
import {Card, CardBody, CardText, CardTitle, Col, Container, Row} from "reactstrap";

import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {isEmpty} from "lodash";
import {getNotificationAll, getNotificationDetail, markAllAsRead} from "../../store/notification/actions";
import Moment from "react-moment";


function ucFirst(str) {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
}

const NotificationAll = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(markAllAsRead())
        dispatch(getNotificationAll())
    }, [])

    let title = 'title' + ucFirst(props.i18n.language)
    let description = 'description' + ucFirst(props.i18n.language)
    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid={true}>
                    <Row>
                        {
                            !isEmpty(props.Notification.arrayAll)
                                ?
                                <>
                                    {
                                        props.Notification.arrayAll.map((item, key) => {
                                            let title = 'title' + ucFirst(props.i18n.language)
                                            let description = 'description' + ucFirst(props.i18n.language)
                                            return (
                                                <Col md={12} key={key}>
                                                    <Card>
                                                        <CardBody>
                                                            <CardTitle
                                                                className="h4 mt-0">{item[title]}  <i style={{fontSize:'12px'}}><Moment format="YYYY-MM-DD" date={item.createdAt}/></i> </CardTitle>
                                                            <CardText>
                                                                {item[description]}
                                                            </CardText>

                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )
                                        })
                                    }

                                </>
                                : null
                        }

                    </Row>

                </Container>
            </div>

        </React.Fragment>
    )

}


const mapStateToProps = state => {
    const {
        Notification
    } = state
    return {
        Notification
    }
};


export default connect(mapStateToProps)(withTranslation()(NotificationAll));

