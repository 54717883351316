import {
    GET_NEWS_REQUEST_SUCCESS,
    GET_NEWS_DETAIL_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    array : [],
    post : {},
}

const News = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_NEWS_REQUEST_SUCCESS:
            return {
                ...state,
                array: action.callBack.news,
            }

        case GET_NEWS_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                post: action.callBack.post,
            }

        default:
            return state
    }
}

export default News
