import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/saga"
import FlightSaga from "./flight/saga"
import NewsSaga from "./news/saga"
import RatesSaga from "./rate/saga"
import OfficesSaga from "./offices/saga"
import ProductsSaga from "./products/saga"

// import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import PageSaga from "./page/saga"
import PopapSaga from "./popap/saga"
import NotificationSaga from "./notification/saga"
import InvoiceSaga from "./invoice/saga"
import PaymentSaga from "./payment/saga"
import CourierSaga from "./courier/saga"
import MerchantSaga from "./merchant/saga"
import BranchesSaga from "./branches/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(FlightSaga),
    fork(NewsSaga),
    fork(RatesSaga),
    fork(OfficesSaga),
    fork(ProductsSaga),
    fork(PopapSaga),
    fork(NotificationSaga),
    fork(LayoutSaga),
    fork(InvoiceSaga),

    fork(PageSaga),
    fork(PaymentSaga),
    fork(CourierSaga),
    fork(MerchantSaga),
    fork(BranchesSaga),
  ])
}
