export function validateEmail($value) {
    const Reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return Reg.test(String($value).toLowerCase());
}

export function onlyStringAndSpice($value) {
    const Reg = /^[a-zA-Z\s]*$/;
    return Reg.test(String($value).toLowerCase());
}
export function onlyNumbers($value) {
    const Reg = /^\d+$/;
    return Reg.test(String($value).toLowerCase());
}
export function sameLetters($value) {
    const Reg = /(\b(?:([A-Za-z])(?!\2{2}))+\b)/;
    return Reg.test(String($value).toLowerCase());
}
