import {
    ADD_BALANCE_REQUEST,
    UPDATE_BALANCE_RESULT_REQUEST
} from "./actionTypes"

export const addBalance = (data) => ({
    type:ADD_BALANCE_REQUEST,
    payload: data,
})

export const updateBalanceResult = (data) => ({
    type:UPDATE_BALANCE_RESULT_REQUEST,
    payload: data,
})
