import {Badge, Button} from "reactstrap";
import React, {useEffect, useState, Fragment} from "react";
import {useHistory} from 'react-router-dom';
import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {Row, Col, CardBody, Card, Alert, Container} from "reactstrap"

import {showRightSidebarUpdateParcelAction} from "../../store/layout/actions";
import {getProduct} from "../../store/products/actions";

import ReactTooltip from 'react-tooltip';
import {isEmpty} from "lodash";
import {paymentFlightId} from "../../store/payment/actions";
import {BranchAddress} from "../../helpers/types";

const TableObject = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const handleUpdateParcel = (id) => {
        let item = {
            id
        }
        dispatch(getProduct(item))
        dispatch(showRightSidebarUpdateParcelAction(true))
    }

    const handleLink = (filename) => {
        if (filename) {
            window.open(process.env.REACT_APP_UPLOADER_SERVER_URL + '/uploads/invoices/' + filename)
        }
    }
    const handlePayFlight = (it) => {
        let item = {
            flightId: it
        }
        dispatch(paymentFlightId(item))
    }

    return (
        <>
            {
                props.status === 'obtained'
                    ?
                    <>
                        <div style={{paddingTop: '5px'}}></div>
                        {
                            !isEmpty(props.Products.giveawayFlightObtainet)
                                ?
                                <>

                                    {
                                        props.Products.giveawayFlightObtainet
                                            .filter((todo) => (todo.flightId.includes(props.flightCountry)))
                                            .map((it, k) => {
                                                return (
                                                    <Fragment key={k}>
                                                        {
                                                            props.status !== 'sent'
                                                                ?
                                                                <>
                                                                    <Row>
                                                                        <Col>
                                                                            <div style={{
                                                                                float: 'left',
                                                                                padding: '0px 0px 0px 10px'
                                                                            }}>
                                                                                {
                                                                                    it.state === 'paid'
                                                                                        ? <Badge
                                                                                            className={`badge bg-pill font-size-12 bg-soft-success`}
                                                                                            pill
                                                                                        >
                                                                                            <h6 style={{margin: '0px'}}> {props.t("_FLIGHT_")}
                                                                                                - {it.flightId}
                                                                                            </h6>
                                                                                            {props.t("_PAID_")}
                                                                                        </Badge>
                                                                                        : <Badge
                                                                                            className={`badge bg-pill font-size-12 bg-soft-warning`}
                                                                                            pill
                                                                                        >
                                                                                            <h6 style={{margin: '0px'}}> {props.t("_FLIGHT_")}
                                                                                                - {it.flightId}
                                                                                            </h6>
                                                                                            {props.t("_UNPAID_")}
                                                                                        </Badge>
                                                                                }

                                                                            </div>
                                                                        </Col>
                                                                        <Col>
                                                                            <div style={{
                                                                                float: 'left',
                                                                                padding: '0px 0px 0px 10px '
                                                                            }}>
                                                                                <Button
                                                                                    onClick={() => history.push(`/invoice/${it.flightId}`)}
                                                                                    type="button" color="primary"
                                                                                    style={{width: '100%', height: '34px'}}
                                                                                    className="btn-sm waves-effect waves-light">
                                                                                    {props.t("_INVOICE_")}
                                                                                </Button>
                                                                            </div>
                                                                        </Col>
                                                                        <Col>

                                                                        </Col>

                                                                    </Row>
                                                                </>
                                                                :
                                                                <Row>
                                                                    <Col>
                                                                        <Badge
                                                                            className={`badge bg-pill font-size-12 bg-soft-warning`}
                                                                            pill
                                                                        >
                                                                            <h6 style={{margin: '0px'}}> {props.t("_FLIGHT_")}
                                                                                - {it.flightId}
                                                                            </h6>
                                                                        </Badge>
                                                                    </Col>

                                                                </Row>
                                                        }


                                                        {
                                                            props.Products.arrayObtainet.map((item, key) => {
                                                                return (
                                                                    <Fragment key={key}>
                                                                        {
                                                                            props.country === item.senderCountryId && item.flightId === it.flightId
                                                                                ?

                                                                                <div style={{paddingTop: '5px'}}>
                                                                                    <div className={'traking-box '}>
                                                                                        <div>
                                                                                            <Row>
                                                                                                <Col>{props.t("_WAYBILL_NUMBER_")} </Col>
                                                                                                <Col>{item.productOrderId}</Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>{props.t("_PRODUCT_NAME_")} </Col>
                                                                                                <Col>{props.t(item.productName)} </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>{props.t("_QUANTITY_")} </Col>
                                                                                                <Col>   {item.quantity}</Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>{props.t("_ONLINE_MARKET_")} </Col>
                                                                                                <Col>    {item.shop}</Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>{props.t("_PAID_AMOUNT_")} </Col>
                                                                                                <Col>   {item.price}</Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>{props.t("_CURRENCY_")} </Col>
                                                                                                <Col>  {item.currency}</Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>{props.t("_branch")} </Col>
                                                                                                <Col>   {BranchAddress(item.branch,props)}</Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>{props.t("_INVOICE_")} </Col>
                                                                                                <Col>
                                                                                                    {
                                                                                                        item.file
                                                                                                            ?
                                                                                                            <Button
                                                                                                                onClick={handleLink.bind(this, item.file)}
                                                                                                                type="button"
                                                                                                                color="success"
                                                                                                                style={{width: '100%'}}
                                                                                                                className="btn-sm waves-effect waves-light">
                                                                                                                {props.t("_FILE_")}
                                                                                                            </Button>
                                                                                                            : null
                                                                                                    }

                                                                                                    {
                                                                                                        item.description
                                                                                                            ?
                                                                                                            <>
                                                                                                                <div
                                                                                                                    style={{paddingTop: '5px'}}>
                                                                                                                    <p data-tip={item.description}>
                                                                                                                        <Button
                                                                                                                            type="button"
                                                                                                                            color="primary"
                                                                                                                            style={{width: '100%'}}
                                                                                                                            className="btn-sm waves-effect waves-light">
                                                                                                                            {props.t("_COMMENT_")}
                                                                                                                        </Button>
                                                                                                                    </p>
                                                                                                                    <ReactTooltip/>
                                                                                                                </div>
                                                                                                            </>

                                                                                                            : null
                                                                                                    }
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col> </Col>
                                                                                                <Col>

                                                                                                    {
                                                                                                        props.status === 'arrived' || props.status === 'obtained'
                                                                                                            ? null
                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    it.updateStatus
                                                                                                                        ?
                                                                                                                        <>
                                                                                                                            <Button
                                                                                                                                onClick={handleUpdateParcel.bind(this, item._id)}
                                                                                                                                type="button"
                                                                                                                                // color={item.quantity ? 'warning' : 'danger'}
                                                                                                                                color={
                                                                                                                                    it.updateStatus
                                                                                                                                        ? item.quantity ? 'warning' : 'danger'
                                                                                                                                        : 'danger'
                                                                                                                                }
                                                                                                                                className="btn-sm btn-rounded waves-effect waves-light btn-min-wi">
                                                                                                                                {
                                                                                                                                    it.updateStatus
                                                                                                                                        ? <>
                                                                                                                                            {
                                                                                                                                                item.quantity
                                                                                                                                                    ? <> {props.t("_EDIT_")}</>
                                                                                                                                                    : <> {props.t("_DECLARE_")}</>
                                                                                                                                            }
                                                                                                                                        </>
                                                                                                                                        : null
                                                                                                                                }
                                                                                                                            </Button>

                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                item.quantity && item.price && item.quantity
                                                                                                                                    ? null
                                                                                                                                    :
                                                                                                                                    <Button
                                                                                                                                        onClick={handleUpdateParcel.bind(this, item._id)}
                                                                                                                                        type="button"
                                                                                                                                        // color={item.quantity ? 'warning' : 'danger'}
                                                                                                                                        color={
                                                                                                                                            it.updateStatus
                                                                                                                                                ? item.quantity ? 'warning' : 'danger'
                                                                                                                                                : 'danger'
                                                                                                                                        }
                                                                                                                                        className="btn-sm btn-rounded waves-effect waves-light btn-min-wi">
                                                                                                                                        {props.t("_DECLARE_")}
                                                                                                                                    </Button>
                                                                                                                            }
                                                                                                                        </>
                                                                                                                }

                                                                                                            </>
                                                                                                    }
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                : null

                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }

                                                    </Fragment>
                                                )
                                            })
                                    }

                                </>
                                : null
                        }
                    </>
                    :
                    <>
                        <div style={{paddingTop: '5px', }}></div>
                        {
                            !isEmpty(props.Products.giveawayFlight)
                                ?
                                <>

                                    {
                                        props.Products.giveawayFlight
                                            .filter((todo) => (todo.flightId.includes(props.flightCountry)))
                                            .map((it, k) => {
                                                return (
                                                    <Fragment key={k}>
                                                        {
                                                            props.status !== 'sent'
                                                                ?
                                                                <>
                                                                    <Row style={{paddingTop:'10px'}}>
                                                                        <Col>
                                                                            <div style={{
                                                                                float: 'left',
                                                                                padding: '0px 0px 0px 10px'
                                                                            }}>
                                                                                {
                                                                                    it.state === 'paid'
                                                                                        ? <Badge
                                                                                            className={`badge bg-pill font-size-12 bg-soft-success`}
                                                                                            pill
                                                                                        >
                                                                                            <h6 style={{margin: '0px'}}>
                                                                                                {props.t("_FLIGHT_")}
                                                                                                - {it.flightId}
                                                                                            </h6>
                                                                                            {props.t("_PAID_")}
                                                                                        </Badge>
                                                                                        : <Badge
                                                                                            className={`badge bg-pill font-size-12 bg-soft-warning`}
                                                                                            pill
                                                                                        >
                                                                                            <h6 style={{margin: '0px'}}> {props.t("_FLIGHT_")}
                                                                                                - {it.flightId}
                                                                                            </h6>
                                                                                            {props.t("_UNPAID_")}
                                                                                        </Badge>
                                                                                }

                                                                            </div>
                                                                        </Col>
                                                                        <Col>
                                                                            <div style={{
                                                                                float: 'left',
                                                                                padding: '0px 0px 0px 10px '
                                                                            }}>
                                                                                <Button
                                                                                    onClick={() => history.push(`/invoice/${it.flightId}`)}
                                                                                    type="button" color="primary"
                                                                                    style={{width: '100%', height: '34px'}}
                                                                                    className="btn-sm waves-effect waves-light">
                                                                                    {props.t("_INVOICE_")}
                                                                                </Button>
                                                                            </div>
                                                                        </Col>

                                                                        {
                                                                            it.state !== 'paid'
                                                                                ?
                                                                                <Col>
                                                                                    <div style={{
                                                                                        float: 'left',
                                                                                        padding: '0px 0px 0px 10px '
                                                                                    }}>
                                                                                        <Button
                                                                                            onClick={handlePayFlight.bind(this, it.flightId)}
                                                                                            type="button" color="success"
                                                                                            style={{width: '100%', height: '34px'}}
                                                                                            className="btn-sm waves-effect waves-light">
                                                                                            {props.t("_PAY_")}
                                                                                        </Button>
                                                                                    </div>
                                                                                </Col>
                                                                                : null

                                                                        }

                                                                    </Row>
                                                                </>
                                                                :
                                                                <Row>
                                                                    <Col>
                                                                        <Badge
                                                                            className={`badge bg-pill font-size-12 bg-soft-warning`}
                                                                            pill
                                                                        >
                                                                            <h6 style={{margin: '0px'}}> {props.t("_FLIGHT_")}
                                                                                - {it.flightId}
                                                                            </h6>
                                                                        </Badge>
                                                                    </Col>

                                                                </Row>
                                                        }


                                                        {
                                                            props.Products.array.map((item, key) => {
                                                                return (
                                                                    <Fragment key={key}>
                                                                        {
                                                                            props.country === item.senderCountryId && item.flightId === it.flightId
                                                                                ?

                                                                                <div style={{paddingTop: '5px'}}>
                                                                                    <div className={'traking-box '}>
                                                                                        <div>
                                                                                            <Row>
                                                                                                <Col>{props.t("_WAYBILL_NUMBER_")} </Col>
                                                                                                <Col>{item.productOrderId}</Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>{props.t("_PRODUCT_NAME_")} </Col>
                                                                                                <Col>{props.t(item.productName)} </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>{props.t("_QUANTITY_")} </Col>
                                                                                                <Col>   {item.quantity}</Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>{props.t("_ONLINE_MARKET_")} </Col>
                                                                                                <Col>
                                                                                                    {
                                                                                                        item.personalParcel
                                                                                                            ? <>
                                                                                                                პერსონალური - <br/>
                                                                                                                {item.senderName}
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    item.shop === '_OTHER_SHOP_'
                                                                                                                        ? item.otherShop
                                                                                                                        : item.shop
                                                                                                                }
                                                                                                            </>
                                                                                                    }
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>{props.t("_PAID_AMOUNT_")} </Col>
                                                                                                <Col>   {item.price}</Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>{props.t("_CURRENCY_")} </Col>
                                                                                                <Col>  {item.currency}</Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>{props.t("_branch")} </Col>
                                                                                                <Col>   {BranchAddress(item.branch,props)}</Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>{props.t("_INVOICE_")} </Col>
                                                                                                <Col>
                                                                                                    {
                                                                                                        item.file
                                                                                                            ?
                                                                                                            <Button
                                                                                                                onClick={handleLink.bind(this, item.file)}
                                                                                                                type="button"
                                                                                                                color="success"
                                                                                                                style={{width: '100%'}}
                                                                                                                className="btn-sm waves-effect waves-light">
                                                                                                                {props.t("_FILE_")}
                                                                                                            </Button>
                                                                                                            : null
                                                                                                    }

                                                                                                    {
                                                                                                        item.description
                                                                                                            ?
                                                                                                            <>
                                                                                                                <div
                                                                                                                    style={{paddingTop: '5px'}}>
                                                                                                                    <p data-tip={item.description}>
                                                                                                                        <Button
                                                                                                                            type="button"
                                                                                                                            color="primary"
                                                                                                                            style={{width: '100%'}}
                                                                                                                            className="btn-sm waves-effect waves-light">
                                                                                                                            {props.t("_COMMENT_")}
                                                                                                                        </Button>
                                                                                                                    </p>
                                                                                                                    <ReactTooltip/>
                                                                                                                </div>
                                                                                                            </>

                                                                                                            : null
                                                                                                    }
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col> </Col>
                                                                                                <Col>

                                                                                                    {
                                                                                                        props.status === 'arrived' || props.status === 'obtained'
                                                                                                            ? null
                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    it.updateStatus
                                                                                                                        ?
                                                                                                                        <>
                                                                                                                            <Button
                                                                                                                                onClick={handleUpdateParcel.bind(this, item._id)}
                                                                                                                                type="button"
                                                                                                                                // color={item.quantity ? 'warning' : 'danger'}
                                                                                                                                color={
                                                                                                                                    it.updateStatus
                                                                                                                                        ? item.quantity ? 'warning' : 'danger'
                                                                                                                                        : 'danger'
                                                                                                                                }
                                                                                                                                className="btn-sm btn-rounded waves-effect waves-light btn-min-wi">
                                                                                                                                {
                                                                                                                                    it.updateStatus
                                                                                                                                        ? <>
                                                                                                                                            {
                                                                                                                                                item.quantity
                                                                                                                                                    ? <> {props.t("_EDIT_")}</>
                                                                                                                                                    : <> {props.t("_DECLARE_")}</>
                                                                                                                                            }
                                                                                                                                        </>
                                                                                                                                        : null
                                                                                                                                }
                                                                                                                            </Button>

                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                item.quantity && item.price && item.quantity
                                                                                                                                    ? null
                                                                                                                                    :
                                                                                                                                    <Button
                                                                                                                                        onClick={handleUpdateParcel.bind(this, item._id)}
                                                                                                                                        type="button"
                                                                                                                                        // color={item.quantity ? 'warning' : 'danger'}
                                                                                                                                        color={
                                                                                                                                            it.updateStatus
                                                                                                                                                ? item.quantity ? 'warning' : 'danger'
                                                                                                                                                : 'danger'
                                                                                                                                        }
                                                                                                                                        className="btn-sm btn-rounded waves-effect waves-light btn-min-wi">
                                                                                                                                        {props.t("_DECLARE_")}
                                                                                                                                    </Button>
                                                                                                                            }
                                                                                                                        </>
                                                                                                                }

                                                                                                            </>
                                                                                                    }
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                : null

                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }

                                                    </Fragment>
                                                )
                                            })
                                    }

                                </>
                                : null
                        }
                    </>
            }

        </>
    )
}


const mapStateToProps = state => {
        const {
            Layout,
            Products
        } = state
        return {
            Layout,
            Products
        }
    }
;

export default connect(mapStateToProps)(withTranslation()(TableObject));
