import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {Col, Container, Row, Table, Spinner, Button} from "reactstrap";
import China from "../../assets/images/flags/china.png";
import Turkey from "../../assets/images/flags/turkey.png";
import Usa from "../../assets/images/flags/us.jpg";
import TableObject from "./Table";
import TableObject2 from "./Table2";
import errorImg from "../../assets/images/404-error.png";
import TableMob from "./TableMob";
import TableMob2 from "./TableMob2";

import commingsoon from "../../assets/images/coming-soon-img.png";

const TableObjects = (props) => {

    return (
        <>
            <div className="table-responsive">
                {
                    props.Products.isLoad
                        ?
                        <>
                            <React.Fragment>
                                <div className="my-5 pt-sm-5">
                                    <Container>
                                        <Row>
                                            <Col lg={12}>
                                                <div className="text-center">
                                                    <Row className="justify-content-center">
                                                        <Col lg={4} sm={5}>
                                                            <div className="maintenance-img">
                                                                <img src={commingsoon} alt=""
                                                                     className="img-fluid mx-auto d-block"/>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <h4 className="mt-5"><Spinner className="m-1"  color="primary"/>
                                                    </h4>

                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </React.Fragment>

                        </>
                        :

                        <>
                            {
                                props.Products.productsCountChina === 0 && props.Products.productsCountUsa === 0 && props.Products.productsCountTurkey === 0 && props.Products.productsCountGrecee === 0
                                    ?
                                    <Container>
                                        <Row>
                                            <Col md={12}>
                                                <div className="text-center">
                                                    <div>
                                                        <Row className="row justify-content-center">
                                                            <Col sm={4}>
                                                                <div className="error-img">
                                                                    <img src={errorImg} alt=""
                                                                         className="img-fluid mx-auto d-block"/>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <h4 className="text-uppercase mt-4">{props.text}</h4>

                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    :
                                    <>
                                        <div className={'dd-none'}>
                                            {
                                                props.Products.productsCountChina > 0
                                                    ?
                                                    <>
                                                        <div>
                                                            <div><img src={China} style={{height: '30px'}}/>
                                                                <i> {props.t("_CHINA_")}</i></div>
                                                        </div>

                                                        {
                                                            props.status === 'sent' || props.status === 'arrived' || props.status === 'obtained'
                                                                ? <TableMob2
                                                                    status={props.status}
                                                                    country={1}
                                                                    flightCountry={'CN'}
                                                                />
                                                                : <TableMob
                                                                    status={props.status}
                                                                    country={1}
                                                                    flightCountry={'CN'}
                                                                />
                                                        }
                                                    </>
                                                    : null
                                            }
                                            {
                                                props.Products.productsCountUsa > 0
                                                    ?
                                                    <>
                                                        <div>
                                                            <div><img src={Usa} style={{height: '30px'}}/>
                                                                <i> {props.t("_USA_")}</i></div>
                                                        </div>

                                                        {
                                                            props.status === 'sent' || props.status === 'arrived' || props.status === 'obtained'
                                                                ? <TableMob2
                                                                    status={props.status}
                                                                    country={2}
                                                                    flightCountry={'US'}
                                                                />
                                                                : <TableMob
                                                                    status={props.status}
                                                                    country={2}
                                                                    flightCountry={'US'}
                                                                />
                                                        }
                                                    </>
                                                    : null
                                            }


                                            {
                                                props.Products.productsCountTurkey > 0
                                                    ?
                                                    <>
                                                        <div>
                                                            <div><img src={Turkey} style={{height: '30px'}}/>
                                                                <i>  {props.t("_TURKEY_")}</i></div>
                                                        </div>
                                                        {
                                                            props.status === 'sent' || props.status === 'arrived' || props.status === 'obtained'
                                                                ? <TableMob2
                                                                    status={props.status}
                                                                    country={3}
                                                                    flightCountry={'TR'}
                                                                />
                                                                : <TableMob
                                                                    status={props.status}
                                                                    country={3}
                                                                    flightCountry={'TR'}
                                                                />
                                                        }

                                                    </>
                                                    : null
                                            }
                                        </div>

                                        <div className={'mm-none'}>
                                            <Table className="table-centered table-nowrap mb-0 ">
                                                <thead className="table-light">
                                                <tr>
                                                    <th>{props.t("_WAYBILL_NUMBER_")} </th>
                                                    <th>{props.t("_PRODUCT_NAME_")}</th>
                                                    <th>{props.t("_QUANTITY_")}</th>
                                                    <th>{props.t("_ONLINE_MARKET_")}</th>
                                                    <th>{props.t("_PAID_AMOUNT_")}</th>
                                                    <th>{props.t("_CURRENCY_")}</th>
                                                    <th>{props.t("_branch")}</th>
                                                    <th>{props.t("_INVOICE_")}</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    props.Products.productsCountChina > 0
                                                        ?
                                                        <>
                                                            <tr>
                                                                <td><img src={China} style={{height: '30px'}}/>
                                                                    <i> {props.t("_CHINA_")}</i>
                                                                </td>
                                                            </tr>
                                                            {
                                                                props.status === 'sent' || props.status === 'arrived' || props.status === 'obtained'
                                                                    ?
                                                                    <TableObject2
                                                                        status={props.status}
                                                                        country={1}
                                                                        flightCountry={'CN'}
                                                                    />
                                                                    :
                                                                    <TableObject
                                                                        status={props.status}
                                                                        country={1}
                                                                        flightCountry={'CN'}
                                                                    />
                                                            }
                                                        </>
                                                        : null
                                                }
                                                {
                                                    props.Products.productsCountUsa > 0
                                                        ?
                                                        <>
                                                            <tr>
                                                                <td><img src={Usa} style={{height: '30px'}}/>
                                                                    <i> {props.t("_USA_")}</i>
                                                                </td>
                                                            </tr>
                                                            {
                                                                props.status === 'sent' || props.status === 'arrived' || props.status === 'obtained'
                                                                    ?
                                                                    <TableObject2
                                                                        status={props.status}
                                                                        country={2}
                                                                        flightCountry={'US'}
                                                                    />
                                                                    :
                                                                    <TableObject
                                                                        status={props.status}
                                                                        country={2}
                                                                        flightCountry={'US'}
                                                                    />
                                                            }
                                                        </>
                                                        : null
                                                }

                                                {
                                                    props.Products.productsCountTurkey > 0
                                                        ?
                                                        <>
                                                            <tr>
                                                                <td><img src={Turkey} style={{height: '30px'}}/>
                                                                    <i> {props.t("_TURKEY_")}</i>
                                                                </td>
                                                            </tr>
                                                            {
                                                                props.status === 'sent' || props.status === 'arrived' || props.status === 'obtained'
                                                                    ? <TableObject2
                                                                        status={props.status}
                                                                        country={3}
                                                                        flightCountry={'TR'}
                                                                    />
                                                                    : <TableObject
                                                                        status={props.status}
                                                                        country={3}
                                                                        flightCountry={'TR'}
                                                                    />
                                                            }

                                                        </>
                                                        : null
                                                }

                                                </tbody>
                                            </Table>
                                        </div>

                                    </>
                            }

                            {
                                props.Products.array.length < props.Products.productsCount
                                ?
                                    <div style={{textAlign: 'center', padding: '20px'}}>
                                        {
                                            props.Products.btnIsLoad
                                                ?
                                                <div>
                                                    <Spinner className="m-1" color="primary"/>
                                                </div>
                                                :
                                                <Button
                                                    onClick={props.handleMore}
                                                    type="button"
                                                    color="success"
                                                    className="btn btn-success w-lg waves-effect waves-light"
                                                >
                                                    {props.t("_SHOW_MORE_")}

                                                </Button>

                                        }
                                    </div>
                                    :null
                            }


                        </>
                }

            </div>
        </>
    )
}


const mapStateToProps = state => {
    const {
        Layout,
        Products
    } = state
    return {
        Layout,
        Products
    }
};

export default connect(mapStateToProps)(withTranslation()(TableObjects));
