export const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST"
export const GET_NOTIFICATION_REQUEST_SUCCESS = "GET_NOTIFICATION_REQUEST_SUCCESS"
export const GET_NOTIFICATION_ALL_REQUEST = "GET_NOTIFICATION_ALL_REQUEST"
export const GET_NOTIFICATION_REQUEST_ALL_SUCCESS = "GET_NOTIFICATION_REQUEST_ALL_SUCCESS"

export const GET_NOTIFICATION_DETAIL_REQUEST = "GET_NOTIFICATION_DETAIL_REQUEST"
export const GET_NOTIFICATION_DETAIL_REQUEST_SUCCESS = "GET_NOTIFICATION_DETAIL_REQUEST_SUCCESS"

export const UPDATE_MARK_ALL_AS_READ_REQUEST = "UPDATE_MARK_ALL_AS_READ_REQUEST"
export const UPDATE_MARK_ALL_AS_READ_REQUEST_SUCCESS = "UPDATE_MARK_ALL_AS_READ_REQUEST_SUCCESS"
