import {
    GET_PAGE_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    page : {},
}

const Pages = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PAGE_REQUEST_SUCCESS:
            return {
                ...state,
                page: action.callBack.page,
            }
        default:
            return state
    }
}

export default Pages
