/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT"
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH"
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME"
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE"

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME"

// show sidebar
export const SHOW_SIDEBAR = "SHOW_SIDEBAR"
export const TOGGLE_LEFTMENU = "TOGGLE_LEFTMENU"

/* RIGHT SIDEBAR */
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR"

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER"

export const SHOW_RIGHT_ADD_COURIER_SIDEBAR = "SHOW_RIGHT_ADD_COURIER_SIDEBAR"
export const SHOW_RIGHT_ADD_PARCEL_SIDEBAR = "SHOW_RIGHT_ADD_PARCEL_SIDEBAR"
export const SHOW_RIGHT_ADD_AMOUNT_SIDEBAR = "SHOW_RIGHT_ADD_AMOUNT_SIDEBAR"
export const SHOW_RIGHT_FORGET_PASSWORD_SIDEBAR = "SHOW_RIGHT_FORGET_PASSWORD_SIDEBAR"
export const SHOW_RIGHT_UPDATE_PARCEL_SIDEBAR = "SHOW_RIGHT_UPDATE_PARCEL_SIDEBAR"

export const PARCEL_STATUS_CHANGES = "PARCEL_STATUS_CHANGES"
