import usFlag from "../assets/images/flags/us.jpg"
import georgian from "../assets/images/flags/georgia.jpg"
import russia from "../assets/images/flags/russia.jpg"

const languages = {
  ka: {
    label: "Georgian",
    flag: georgian,
  },
  ru: {
    label: "Russian",
    flag: russia,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
}

export default languages
