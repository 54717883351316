import {
    GET_NEWS_REQUEST,
    GET_NEWS_DETAIL_REQUEST
} from "./actionTypes"

export const newNewst = (data) => ({
    type:GET_NEWS_REQUEST,
    payload: data,
})

export const getNewsDetail = (data) => ({
    type:GET_NEWS_DETAIL_REQUEST,
    payload: data,
})

