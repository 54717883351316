import {
    CHECK_USER_AUTH_REQUEST_SUCCESS,
    CHECK_USER_AUTH_REQUEST_ERROR,
    LOG_OUT_REQUEST_SUCCESS,
    CHECK_USER_AUTH_REQUEST_SUCCESS_FALSE,
    GET_USER_DEPT_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    candidate : {
        _id:'',
        AXID : null
    },
    debt : 0,
    isAuthenticated : null
}

const users = (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_USER_DEPT_REQUEST_SUCCESS:
            return {
                ...state,
                debt : action.callBack.deptSum
            }

        case CHECK_USER_AUTH_REQUEST_SUCCESS:
            return {
                ...state,
                candidate: action.callBack.candidate,
                isAuthenticated: true,
            }

        case CHECK_USER_AUTH_REQUEST_SUCCESS_FALSE:
            return {
                ...state,
                isAuthenticated: false,
            }
        case CHECK_USER_AUTH_REQUEST_ERROR:
            return {
                ...state,
                isAuthenticated: false,
            }
        case LOG_OUT_REQUEST_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
            }

        default:
            return state
    }
}

export default users
