import {call, put, takeEvery} from "redux-saga/effects"
import {
    ADD_BALANCE_REQUEST, UPDATE_BALANCE_RESULT_REQUEST,
} from "./actionTypes"


import moment from 'moment';
import {notification} from "antd";



const modalError = (type, placement) => {
    notification[type]({
        message: 'შეცდომა',
        description: 'გადახდისას მოხდა შეცდომა მიმართეთ ოპერატორს',
        placement
    });
};

function* addBalanceSaga({payload}) {
    try {
        const callBack = yield call(addBalanceSagaAsync, payload)
        if (callBack.success) {
            if (callBack.result.amount) {
                const amount = callBack.result.amount.toString()
                const amountDB = callBack.result.amountDB.toString()
                const orderId = callBack.result._id
                const AXID = callBack.result.userAXID
                const PayDay = moment(callBack.result.createdAt).format('Ymd H:m:i')

                const back_url_s = 'https://aligeorgia.ge/payments/success'
                const back_url_f = 'https://aligeorgia.ge/payments/failed'
                window.location.href = `https://mpi.gc.ge/page1/?page_id=B1069DCF8E094A406F0A5C82684652CA&trx_id=E5BA19944804390A51241B53BC716E62&lang_code=ka&merch_id=066782F7975C5A14ED8B41B425D1E080&ccy=981&o.amount=${amountDB}&o.order_id=${orderId}&o.axcode=${AXID}&ts=${PayDay}&back_url_s=${back_url_s}&back_url_f=${back_url_f}`;
            }else {
                modalError('error')
            }
            //
          //
           //https://3dacq.georgiancard.ge/payment/start.wsm?ws.id=1w5ovivhxp6i0&page_id=B1069DCF8E094A406F0A5C82684652CA&account_id_gel=CEAA21277C5182E7AE1DE136F8A1558D&trx_id=E5BA19944804390A51241B53BC716E62&lang=ka&merch_id=066782F7975C5A14ED8B41B425D1E080&ccy=981&o.amount=100&o.order_id=c244d1afee1ec974be012f9eadb3c9df&o.axcode=3188&ts=20220425%2010:04:32&back_url_s=http://aligeorgia.ge/payments/success.php&back_url_f=http://aligeorgia.ge/payments/failed.php
           // window.location.href = 'https://google.com';
            // yield put({type: GET_NEWS_DETAIL_REQUEST_SUCCESS, callBack})
        } else {
            modalError('error')
        }
    } catch (err) {
        console.error(err);
    }
}


const addBalanceSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/merchant/georgiancard/pay`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body : JSON.stringify(item)
    })
    return await response.json()
}

function* updateBalanceResultSaga({payload}) {
    try {
        const callBack = yield call(updateBalanceResultSagaAsync, payload)
        if (callBack.success) {


        } else {

        }
    } catch (err) {
        console.error(err);
    }
}


const updateBalanceResultSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/merchant/georgiancard/update`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body : JSON.stringify(item)
    })
    return await response.json()
}


function* newsSaga() {
    yield takeEvery(ADD_BALANCE_REQUEST, addBalanceSaga)
    yield takeEvery(UPDATE_BALANCE_RESULT_REQUEST, updateBalanceResultSaga)
}

export default newsSaga
