import {
    GET_FLIGHT_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    array : [],
}

const Flight = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FLIGHT_REQUEST_SUCCESS:
            return {
                ...state,
                array: action.callBack.flight,
            }

        default:
            return state
    }
}

export default Flight
