import React, {Fragment, useState} from "react";
import {Badge, Col, NavLink} from "reactstrap";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {CopyToClipboard} from "react-copy-to-clipboard";
import ClipboardJS from "clipboard";

new ClipboardJS(".button");
const AddressContainer = (props) => {
    const [copied, setCopied] = useState(false);
    return (
        <>
            {
                props.Offices.length > 0
                    ? <>
                        {
                            props.Offices.map((item, key) => {
                                return (
                                    <Fragment key={key}>
                                        {
                                            parseInt(item.countryId) === props.country
                                                ?
                                                <>
                                                    {
                                                        item.countryId === '1'
                                                            ?
                                                            <>
                                                                <Col xl={6} style={{padding :'2px'}}>
                                                                    <div className={'address-box'}>
                                                                        <h4>
                                                                            {
                                                                                props.t("_ADDRESS_IN_CHINESE_")
                                                                            }
                                                                        </h4>
                                                                        <p><strong>Province: </strong>
                                                                            {item.provinceLoc}
                                                                            <CopyToClipboard
                                                                                options={{debug: props.debug}}
                                                                                text={item.provinceLoc}
                                                                                onCopy={() => setCopied(true)}
                                                                            >
                                                                                <a className={'copy-btn'}>
                                                                                    <i className={'uil-copy'}> {props.t("_COPY_")} </i>
                                                                                </a>
                                                                            </CopyToClipboard>
                                                                        </p>
                                                                        <p style={{width:'80%'}}><strong>City: </strong>{item.cityLoc}
                                                                            <CopyToClipboard
                                                                                options={{debug: props.debug}}
                                                                                text={item.cityLoc}
                                                                                onCopy={() => setCopied(true)}
                                                                            >
                                                                                <a className={'copy-btn'}>
                                                                                    <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                                </a>
                                                                            </CopyToClipboard>
                                                                        </p>
                                                                        <p style={{width:'80%'}}><strong>Address: </strong>{item.addressLoc}
                                                                            {props.Auth.candidate.information
                                                                                ?
                                                                                <>
                                                                                    {
                                                                                        props.Auth.candidate.type === 1 || props.Auth.candidate.information.indEntrepreneur === true
                                                                                            ? <>
                                                                                                {' '}
                                                                                                {
                                                                                                    props.Auth.candidate.information.nameEn
                                                                                                }
                                                                                                {' '}
                                                                                                {
                                                                                                    props.Auth.candidate.information.lastNameEn
                                                                                                }
                                                                                                {' '}
                                                                                            </>
                                                                                            :null
                                                                                    }
                                                                                    {
                                                                                        props.Auth.candidate.type === 2 && props.Auth.candidate.information.indEntrepreneur === false
                                                                                            ? <>
                                                                                                {
                                                                                                    props.Auth.candidate.information.LTDNameEn
                                                                                                }
                                                                                                {' '}
                                                                                            </>
                                                                                            :null
                                                                                    }

                                                                                    {
                                                                                        props.Auth.candidate.AXID
                                                                                    }


                                                                                    {
                                                                                        props.Auth.candidate.type === 1 || props.Auth.candidate.information.indEntrepreneur === true
                                                                                            ? <>
                                                                                                <CopyToClipboard
                                                                                                    options={{debug: props.debug}}
                                                                                                    text={`${item.addressLoc} ${props.Auth.candidate.information.nameEn} ${props.Auth.candidate.information.lastNameEn} ${props.Auth.candidate.AXID}`}
                                                                                                    onCopy={() => setCopied(true)}
                                                                                                >
                                                                                                    <a className={'copy-btn'}>
                                                                                                        <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                                                    </a>
                                                                                                </CopyToClipboard>
                                                                                            </>
                                                                                            :null
                                                                                    }
                                                                                    {
                                                                                        props.Auth.candidate.type === 2 && props.Auth.candidate.information.indEntrepreneur === false
                                                                                            ? <>
                                                                                                <CopyToClipboard
                                                                                                    options={{debug: props.debug}}
                                                                                                    text={`${item.addressLoc} ${props.Auth.candidate.information.LTDNameEn} ${props.Auth.candidate.AXID}`}
                                                                                                    onCopy={() => setCopied(true)}
                                                                                                >
                                                                                                    <a className={'copy-btn'}>
                                                                                                        <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                                                    </a>
                                                                                                </CopyToClipboard>
                                                                                            </>
                                                                                            :null
                                                                                    }

                                                                                    <br/>

                                                                                </>
                                                                                : null
                                                                            }
                                                                        </p>
                                                                        <p style={{width:'80%'}}><strong>Postal Code: </strong>
                                                                            {item.postalCode}
                                                                            <CopyToClipboard
                                                                                options={{debug: props.debug}}
                                                                                text={`${item.postalCode}`}
                                                                                onCopy={() => setCopied(true)}
                                                                            >
                                                                                <a className={'copy-btn'}>
                                                                                    <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                                </a>
                                                                            </CopyToClipboard>
                                                                        </p>
                                                                        {props.Auth.candidate.information
                                                                            ?
                                                                            <>
                                                                                <p style={{width:'80%'}}><strong>Receiver Name: </strong>
                                                                                    {
                                                                                        props.Auth.candidate.type === 1 || props.Auth.candidate.information.indEntrepreneur === true
                                                                                            ? <>
                                                                                                {
                                                                                                    props.Auth.candidate.information.nameEn
                                                                                                }
                                                                                                {' '}
                                                                                                {
                                                                                                    props.Auth.candidate.information.lastNameEn
                                                                                                }
                                                                                                {' '}
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        props.Auth.candidate.type === 2 && props.Auth.candidate.information.indEntrepreneur === false
                                                                                            ? <>
                                                                                                {
                                                                                                    props.Auth.candidate.information.LTDNameEn
                                                                                                }
                                                                                                {' '}
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        props.Auth.candidate.AXID
                                                                                    }

                                                                                    {
                                                                                        props.Auth.candidate.type === 1 || props.Auth.candidate.information.indEntrepreneur === true
                                                                                            ? <>
                                                                                                <CopyToClipboard
                                                                                                    options={{debug: props.debug}}
                                                                                                    text={`${props.Auth.candidate.information.nameEn} ${props.Auth.candidate.information.lastNameEn} ${props.Auth.candidate.AXID}`}
                                                                                                    onCopy={() => setCopied(true)}
                                                                                                >
                                                                                                    <a className={'copy-btn'}>
                                                                                                        <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                                                    </a>
                                                                                                </CopyToClipboard>
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        props.Auth.candidate.type === 2 && props.Auth.candidate.information.indEntrepreneur === false
                                                                                            ? <>
                                                                                                <CopyToClipboard
                                                                                                    options={{debug: props.debug}}
                                                                                                    text={`${props.Auth.candidate.information.LTDNameEn}  ${props.Auth.candidate.AXID}`}
                                                                                                    onCopy={() => setCopied(true)}
                                                                                                >
                                                                                                    <a className={'copy-btn'}>
                                                                                                        <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                                                    </a>
                                                                                                </CopyToClipboard>
                                                                                            </>
                                                                                            : null
                                                                                    }

                                                                                </p>

                                                                            </>
                                                                            : null
                                                                        }
                                                                        <p><strong>Mobile: </strong>{item.mobile}
                                                                            <CopyToClipboard
                                                                                options={{debug: props.debug}}
                                                                                text={`${item.mobile} `}
                                                                                onCopy={() => setCopied(true)}
                                                                            >
                                                                                <a className={'copy-btn'}>
                                                                                    <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                                </a>
                                                                            </CopyToClipboard>
                                                                        </p>
                                                                    </div>
                                                                </Col>
                                                                <Col xl={6} style={{padding :'2px'}}>
                                                                    <div className={'address-box'}>
                                                                        <h4>
                                                                            {
                                                                                props.t("_ADDRESS_IN_ENGLISH_")
                                                                            }
                                                                        </h4>
                                                                        <p style={{width:'80%'}}><strong>Province: </strong>{item.provinceEn}
                                                                            <CopyToClipboard
                                                                                options={{debug: props.debug}}
                                                                                text={`${item.provinceEn} `}
                                                                                onCopy={() => setCopied(true)}
                                                                            >
                                                                                <a className={'copy-btn'}>
                                                                                    <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                                </a>
                                                                            </CopyToClipboard>
                                                                        </p>
                                                                        <p style={{width:'80%'}}><strong>City: </strong>{item.cityEn}
                                                                            <CopyToClipboard
                                                                                options={{debug: props.debug}}
                                                                                text={`${item.cityEn} `}
                                                                                onCopy={() => setCopied(true)}
                                                                            >
                                                                                <a className={'copy-btn'}>
                                                                                    <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                                </a>
                                                                            </CopyToClipboard>
                                                                        </p>
                                                                        <p style={{width:'80%'}}><strong>Address: </strong>{item.addressEn}
                                                                            {props.Auth.candidate.information
                                                                                ?
                                                                                <>
                                                                                    {
                                                                                        props.Auth.candidate.type === 1 || props.Auth.candidate.information.indEntrepreneur === true
                                                                                            ?
                                                                                            <>
                                                                                                {' '}
                                                                                                {
                                                                                                    props.Auth.candidate.information.nameEn
                                                                                                }
                                                                                                {' '}
                                                                                                {
                                                                                                    props.Auth.candidate.information.lastNameEn
                                                                                                }
                                                                                            </>
                                                                                            :null
                                                                                    }

                                                                                    {
                                                                                        props.Auth.candidate.type === 2 && props.Auth.candidate.information.indEntrepreneur === false
                                                                                            ?
                                                                                            <>
                                                                                                {' '}
                                                                                                {
                                                                                                    props.Auth.candidate.information.LTDNameEn
                                                                                                }
                                                                                                {' '}
                                                                                            </>
                                                                                            :null
                                                                                    }

                                                                                    {' '}
                                                                                    {
                                                                                        props.Auth.candidate.AXID
                                                                                    }


                                                                                    {
                                                                                        props.Auth.candidate.type === 1 || props.Auth.candidate.information.indEntrepreneur === true
                                                                                            ?
                                                                                            <>
                                                                                                <CopyToClipboard
                                                                                                    options={{debug: props.debug}}
                                                                                                    text={`${item.addressEn} ${props.Auth.candidate.information.nameEn} ${props.Auth.candidate.information.lastNameEn} ${props.Auth.candidate.AXID}`}
                                                                                                    onCopy={() => setCopied(true)}
                                                                                                >
                                                                                                    <a className={'copy-btn'}>
                                                                                                        <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                                                    </a>
                                                                                                </CopyToClipboard>
                                                                                            </>
                                                                                            :null
                                                                                    }

                                                                                    {
                                                                                        props.Auth.candidate.type === 2 && props.Auth.candidate.information.indEntrepreneur === false
                                                                                            ?
                                                                                            <>
                                                                                                <CopyToClipboard
                                                                                                    options={{debug: props.debug}}
                                                                                                    text={`${item.addressEn} ${props.Auth.candidate.information.LTDNameEn} ${props.Auth.candidate.AXID}`}
                                                                                                    onCopy={() => setCopied(true)}
                                                                                                >
                                                                                                    <a className={'copy-btn'}>
                                                                                                        <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                                                    </a>
                                                                                                </CopyToClipboard>
                                                                                            </>
                                                                                            :null
                                                                                    }



                                                                                </>
                                                                                : null
                                                                            }
                                                                        </p>
                                                                        <p><strong>Postal Code: </strong>{item.postalCode}
                                                                            <CopyToClipboard
                                                                                options={{debug: props.debug}}
                                                                                text={`${item.postalCode} `}
                                                                                onCopy={() => setCopied(true)}
                                                                            >
                                                                                <a className={'copy-btn'}>
                                                                                    <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                                </a>
                                                                            </CopyToClipboard>
                                                                        </p>
                                                                        {props.Auth.candidate.information
                                                                            ?
                                                                            <>
                                                                                <p style={{width:'80%'}}><strong>Receiver Name : </strong>

                                                                                    {
                                                                                        props.Auth.candidate.type === 1 || props.Auth.candidate.information.indEntrepreneur === true
                                                                                            ? <>
                                                                                                {
                                                                                                    props.Auth.candidate.information.nameEn
                                                                                                }
                                                                                                {' '}
                                                                                                {
                                                                                                    props.Auth.candidate.information.lastNameEn
                                                                                                }
                                                                                                {' '}
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        props.Auth.candidate.type === 2 && props.Auth.candidate.information.indEntrepreneur === false
                                                                                            ? <>
                                                                                                {
                                                                                                    props.Auth.candidate.information.LTDNameEn
                                                                                                }
                                                                                                {' '}
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        props.Auth.candidate.AXID
                                                                                    }


                                                                                    {
                                                                                        props.Auth.candidate.type === 1 || props.Auth.candidate.information.indEntrepreneur === true
                                                                                            ? <>
                                                                                                <CopyToClipboard
                                                                                                    options={{debug: props.debug}}
                                                                                                    text={`${props.Auth.candidate.information.nameEn} ${props.Auth.candidate.information.lastNameEn} ${props.Auth.candidate.AXID} `}
                                                                                                    onCopy={() => setCopied(true)}
                                                                                                >
                                                                                                    <a className={'copy-btn'}>
                                                                                                        <i className={'uil-copy'}>{props.t("_COPY_")}</i>
                                                                                                    </a>
                                                                                                </CopyToClipboard>
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        props.Auth.candidate.type === 2 && props.Auth.candidate.information.indEntrepreneur === false
                                                                                            ? <>
                                                                                                <CopyToClipboard
                                                                                                    options={{debug: props.debug}}
                                                                                                    text={`${props.Auth.candidate.information.LTDNameEn} ${props.Auth.candidate.AXID} `}
                                                                                                    onCopy={() => setCopied(true)}
                                                                                                >
                                                                                                    <a className={'copy-btn'}>
                                                                                                        <i className={'uil-copy'}>{props.t("_COPY_")}</i>
                                                                                                    </a>
                                                                                                </CopyToClipboard>
                                                                                            </>
                                                                                            : null
                                                                                    }


                                                                                </p>
                                                                                <p><strong>Mobile: </strong>{item.mobile}
                                                                                    <CopyToClipboard
                                                                                        options={{debug: props.debug}}
                                                                                        text={`${item.mobile} `}
                                                                                        onCopy={() => setCopied(true)}
                                                                                    >
                                                                                        <a className={'copy-btn'}>
                                                                                            <i className={'uil-copy'}>{props.t("_COPY_")}</i>
                                                                                        </a>
                                                                                    </CopyToClipboard>
                                                                                </p>
                                                                            </>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </>
                                                            : null
                                                    }

                                                    {
                                                        item.countryId === '2'
                                                            ?
                                                            <Col xl={8}  className={'address-box'}>
                                                                <p style={{width:'80%'}}><strong>Province: </strong>{item.provinceEn}
                                                                    <CopyToClipboard
                                                                        options={{debug: props.debug}}
                                                                        text={`${item.provinceEn} `}
                                                                        onCopy={() => setCopied(true)}
                                                                    >
                                                                        <a className={'copy-btn'}>
                                                                            <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                        </a>
                                                                    </CopyToClipboard>
                                                                </p>
                                                                <p style={{width:'80%'}}><strong>City: </strong>{item.cityEn}
                                                                    <CopyToClipboard
                                                                        options={{debug: props.debug}}
                                                                        text={`${item.cityEn} `}
                                                                        onCopy={() => setCopied(true)}
                                                                    >
                                                                        <a className={'copy-btn'}>
                                                                            <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                        </a>
                                                                    </CopyToClipboard>
                                                                </p>
                                                                <p style={{width:'80%'}}><strong>Address: </strong>{item.addressEn}
                                                                    <CopyToClipboard
                                                                        options={{debug: props.debug}}
                                                                        text={`${item.addressEn} `}
                                                                        onCopy={() => setCopied(true)}
                                                                    >
                                                                        <a className={'copy-btn'}>
                                                                            <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                        </a>
                                                                    </CopyToClipboard>
                                                                </p>

                                                                {props.Auth.candidate.information
                                                                    ?
                                                                    <>
                                                                        <p style={{width:'80%'}}><strong>Address 2 : </strong>
                                                                            {
                                                                                props.Auth.candidate.AXID
                                                                            }
                                                                            {' '}
                                                                            {
                                                                                props.Auth.candidate.information.nameEn
                                                                            }
                                                                            {' '}
                                                                            {
                                                                                props.Auth.candidate.information.lastNameEn
                                                                            }
                                                                            <CopyToClipboard
                                                                                options={{debug: props.debug}}
                                                                                text={`${props.Auth.candidate.AXID} ${props.Auth.candidate.information.nameEn} ${props.Auth.candidate.information.lastNameEn} `}
                                                                                onCopy={() => setCopied(true)}
                                                                            >
                                                                                <a className={'copy-btn'}>
                                                                                    <i className={'uil-copy'}>{props.t("_COPY_")}</i>
                                                                                </a>
                                                                            </CopyToClipboard>
                                                                        </p>
                                                                        <p><strong>Postal Code: </strong>{item.postalCode}
                                                                            <CopyToClipboard
                                                                                options={{debug: props.debug}}
                                                                                text={`${item.postalCode} `}
                                                                                onCopy={() => setCopied(true)}
                                                                            >
                                                                                <a className={'copy-btn'}>
                                                                                    <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                                </a>
                                                                            </CopyToClipboard>
                                                                        </p>
                                                                        <p><strong>Mobile: </strong>{item.mobile}
                                                                            <CopyToClipboard
                                                                                options={{debug: props.debug}}
                                                                                text={`${item.mobile} `}
                                                                                onCopy={() => setCopied(true)}
                                                                            >
                                                                                <a className={'copy-btn'}>
                                                                                    <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                                </a>
                                                                            </CopyToClipboard>
                                                                        </p>
                                                                    </>
                                                                    : null
                                                                }
                                                            </Col>
                                                            : null
                                                    }
                                                    {
                                                        item.countryId === '3'
                                                            ?
                                                            <Col xl={8}  className={'address-box'}>
                                                                <p style={{width:'80%'}}><strong>Province: </strong>{item.provinceEn}
                                                                    <CopyToClipboard
                                                                        options={{debug: props.debug}}
                                                                        text={`${item.provinceEn} `}
                                                                        onCopy={() => setCopied(true)}
                                                                    >
                                                                        <a className={'copy-btn'}>
                                                                            <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                        </a>
                                                                    </CopyToClipboard>
                                                                </p>
                                                                <p style={{width:'80%'}}><strong>City: </strong>{item.cityEn}
                                                                    <CopyToClipboard
                                                                        options={{debug: props.debug}}
                                                                        text={`${item.cityEn} `}
                                                                        onCopy={() => setCopied(true)}
                                                                    >
                                                                        <a className={'copy-btn'}>
                                                                            <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                        </a>
                                                                    </CopyToClipboard>
                                                                </p>
                                                                <p style={{width:'80%'}}><strong>Address: </strong>
                                                                    {
                                                                        props.Auth.candidate.AXID
                                                                    }
                                                                    {' '}
                                                                    {item.addressEn}

                                                                    <CopyToClipboard
                                                                        options={{debug: props.debug}}
                                                                        text={` ${
                                                                        props.Auth.candidate.AXID
                                                                    } 
                                                                    ${item.addressEn}`}
                                                                        onCopy={() => setCopied(true)}
                                                                    >
                                                                        <a className={'copy-btn'}>
                                                                            <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                        </a>
                                                                    </CopyToClipboard>
                                                                </p>
                                                                <p><strong>Neigborhood : </strong>{item.neigborhood}
                                                                    <CopyToClipboard
                                                                        options={{debug: props.debug}}
                                                                        text={`${item.neigborhood} `}
                                                                        onCopy={() => setCopied(true)}
                                                                    >
                                                                        <a className={'copy-btn'}>
                                                                            <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                        </a>
                                                                    </CopyToClipboard>
                                                                </p>
                                                                <p><strong>Postal Code: </strong>{item.postalCode}
                                                                    <CopyToClipboard
                                                                        options={{debug: props.debug}}
                                                                        text={`${item.postalCode} `}
                                                                        onCopy={() => setCopied(true)}
                                                                    >
                                                                        <a className={'copy-btn'}>
                                                                            <i className={'uil-copy'}> {props.t("_COPY_")}</i>
                                                                        </a>
                                                                    </CopyToClipboard>
                                                                </p>
                                                                {props.Auth.candidate.information
                                                                    ?
                                                                    <>
                                                                        <p style={{width:'80%'}}><strong>Receiver Name : </strong>
                                                                            {
                                                                                props.Auth.candidate.information.nameEn
                                                                            }
                                                                            {' '}
                                                                            {
                                                                                props.Auth.candidate.information.lastNameEn
                                                                            }
                                                                            <CopyToClipboard
                                                                                options={{debug: props.debug}}
                                                                                text={`${props.Auth.candidate.information.nameEn} ${props.Auth.candidate.information.lastNameEn} `}
                                                                                onCopy={() => setCopied(true)}
                                                                            >
                                                                                <a className={'copy-btn'}>
                                                                                    <i className={'uil-copy'}>{props.t("_COPY_")}</i>
                                                                                </a>
                                                                            </CopyToClipboard>
                                                                        </p>
                                                                        <p><strong>Mobile: </strong>{item.mobile}
                                                                            <br/>
                                                                            <i style={{color :'red'}}>    {props.t("_TURKISH_PHONE_NUMBER_WRITE_")} </i>
                                                                        </p>
                                                                    </>
                                                                    : null
                                                                }
                                                            </Col>
                                                            : null
                                                    }

                                                </>
                                                : null
                                        }

                                    </Fragment>
                                )
                            })
                        }
                    </>
                    : null
            }
        </>
    )
}

export default withTranslation()(AddressContainer)
