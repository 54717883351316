import React, {useEffect} from "react";
import {Card, CardBody, CardImg, CardText, CardTitle, Col, Container, Row} from "reactstrap";

import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {getNewsDetail} from "../../store/news/actions";
import {isEmpty} from "lodash";


function ucFirst(str) {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
}

const NewsDetailPage = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        let item = {
            id: props.match.params.id
        }
        dispatch(getNewsDetail(item))
    }, [])

    let title ='newsTitle'+ ucFirst(props.i18n.language)
    let description ='newsText'+ ucFirst(props.i18n.language)
    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid={true}>
                    <Row>
                        {
                            !isEmpty(props.News.post)
                                ?
                                <Col mg={12}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="h4 mt-0">{props.News.post[title]}</CardTitle>
                                            <CardText>
                                                {props.News.post[description]}
                                            </CardText>

                                        </CardBody>
                                    </Card>
                                </Col>
                                : null
                        }

                    </Row>

                </Container>
            </div>

        </React.Fragment>
    )

}


const mapStateToProps = state => {
    const {
        News
    } = state
    return {
        News
    }
};


export default connect(mapStateToProps)(withTranslation()(NewsDetailPage));

