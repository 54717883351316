import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Container, Input, Label, Row} from "reactstrap";
import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {updateUserProfile} from "../../store/auth/actions";

const Profile = (props) => {
    const dispatch = useDispatch();

    const [step, setStep] = useState(true)
    const [city, setCity] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [type, setType] = useState('')
    const [passportId, setpassportId] = useState('')
    const [orgId, setorgId] = useState('')

    const [cityErr, setCityErr] = useState(false)
    const [phoneErr, setPhoneErr] = useState(false)
    const [addressErr, setAddressErr] = useState(false)
    // orgId passportId
    useEffect(() => {
        if (props.Auth.isAuthenticated === true) {
            setCity(props.Auth.candidate.information.city)
            setPhone(props.Auth.candidate.phone)
            setAddress(props.Auth.candidate.information.address)
            setpassportId(props.Auth.candidate.information.passportId)
            setorgId(props.Auth.candidate.information.orgId)
        }

    }, [props.Auth.isAuthenticated])

    const handleStep = () =>{
        setStep(false)
    }
    const handleUpdateProfile = () => {
        if (!phone) {
            setPhoneErr(true)
        } else {
            setPhoneErr(false)
        }
        if (!address) {
            setAddressErr(true)
        } else {
            setAddressErr(false)
        }
        if (!city) {
            setCityErr(true)
        } else {
            setCityErr(false)
        }

        if (city && phone && address) {
            let item = {
                city,
                phone,
                address
            }
           dispatch(updateUserProfile(item))

            setStep(true)
        }

    }

    return (
        <React.Fragment>
            <div className="my-5 pt-sm-5 three-rem">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    {
                                        props.Auth.candidate.AXID
                                            ?
                                            <>
                                                <Row className="mb-3">
                                                    <Col md={6}>
                                                        <Label className="form-label"
                                                               htmlFor="formrow-email-input">AXID</Label>
                                                        <Input type="text"
                                                               value={props.Auth.candidate.AXID}
                                                               disabled
                                                            /* value={productOrderId}
                                                             style={productOrderIdErr ? {borderColor: 'red'} : null}
                                                             onChange={(e) => setProductOrderId(e.target.value.replace(/[^A-Za-z0-9]+/g,''))}*/
                                                               className="form-control" id="formrow-email-input"/>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Label className="form-label"
                                                               htmlFor="formrow-email-input">{props.t("_EMAIL_")}</Label>
                                                        <Input type="text"
                                                               value={props.Auth.candidate.email}
                                                               disabled
                                                               className="form-control" id="formrow-email-input"/>
                                                    </Col>

                                                    <Col md={6}>
                                                        <Label className="form-label"
                                                               htmlFor="formrow-email-input">{props.t("_FIRST_NAME_GEORGIAN_")}</Label>
                                                        <Input type="text"
                                                               value={props.Auth.candidate.information.nameKa}
                                                               disabled
                                                               className="form-control" id="formrow-email-input"/>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Label className="form-label"
                                                               htmlFor="formrow-email-input">{props.t("_LAST_NAME_GEORGIAN_")}</Label>
                                                        <Input type="text"
                                                               value={props.Auth.candidate.information.lastNameKa}
                                                               disabled
                                                               className="form-control" id="formrow-email-input"/>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Label className="form-label"
                                                               htmlFor="formrow-email-input">{props.t("_FIRST_NAME_ENGLISH_")}</Label>
                                                        <Input type="text"
                                                               value={props.Auth.candidate.information.nameEn}
                                                               disabled
                                                               className="form-control" id="formrow-email-input"/>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Label className="form-label"
                                                               htmlFor="formrow-email-input">{props.t("_LAST_NAME_ENGLISH_")}</Label>
                                                        <Input type="text"
                                                               value={props.Auth.candidate.information.lastNameEn}
                                                               disabled
                                                               className="form-control" id="formrow-email-input"/>
                                                    </Col>
                                                </Row>


                                                <Row className="mb-3">
                                                    {
                                                        props.Auth.candidate.type === 1
                                                        ?
                                                            <Col md={6}>
                                                                <Label className="form-label"
                                                                       htmlFor="formrow-email-input">{props.t("_ID_NUMBER_")}</Label>
                                                                <Input type="text"
                                                                       value={props.Auth.candidate.information.passportId}
                                                                       disabled
                                                                    /* value={productOrderId}
                                                                     style={productOrderIdErr ? {borderColor: 'red'} : null}
                                                                     onChange={(e) => setProductOrderId(e.target.value.replace(/[^A-Za-z0-9]+/g,''))}*/
                                                                       className="form-control" id="formrow-email-input"/>
                                                            </Col>
                                                            : null
                                                    }
                                                    {
                                                        props.Auth.candidate.type === 2
                                                            ?
                                                            <>
                                                            {
                                                                props.Auth.candidate.information.indEntrepreneur ===true
                                                                ? <Col md={6}>
                                                                        <Label className="form-label"
                                                                               htmlFor="formrow-email-input">{props.t("_ID_NUMBER_")}</Label>
                                                                        <Input type="text"
                                                                               value={props.Auth.candidate.information.passportId}
                                                                               disabled
                                                                            /* value={productOrderId}
                                                                             style={productOrderIdErr ? {borderColor: 'red'} : null}
                                                                             onChange={(e) => setProductOrderId(e.target.value.replace(/[^A-Za-z0-9]+/g,''))}*/
                                                                               className="form-control" id="formrow-email-input"/>
                                                                    </Col>
                                                                    :<>
                                                                        <Col md={6}>
                                                                            <Label className="form-label"
                                                                                   htmlFor="formrow-email-input">{props.t("_LTD_LLC_ID_NUMBER_")}</Label>
                                                                            <Input type="text"
                                                                                   value={props.Auth.candidate.information.orgId}
                                                                                   disabled
                                                                                /* value={productOrderId}
                                                                                 style={productOrderIdErr ? {borderColor: 'red'} : null}
                                                                                 onChange={(e) => setProductOrderId(e.target.value.replace(/[^A-Za-z0-9]+/g,''))}*/
                                                                                   className="form-control" id="formrow-email-input"/>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <Label className="form-label"
                                                                                   htmlFor="formrow-email-input">{props.t("_ORGANIZATION_NAME_")}</Label>
                                                                            <Input type="text"
                                                                                   value={props.Auth.candidate.information.LTDName}
                                                                                   disabled
                                                                                /* value={productOrderId}
                                                                                 style={productOrderIdErr ? {borderColor: 'red'} : null}
                                                                                 onChange={(e) => setProductOrderId(e.target.value.replace(/[^A-Za-z0-9]+/g,''))}*/
                                                                                   className="form-control" id="formrow-email-input"/>
                                                                        </Col>

                                                                    </>
                                                            }

                                                            </>

                                                            : null
                                                    }



                                                </Row>

                                                <Row>
                                                    <Col md={4}>
                                                        <Label className="form-label"
                                                               htmlFor="formrow-email-input">{props.t("_CITY_")}</Label>


                                                        <select value={city}
                                                                disabled={step === true ? true : false}
                                                                style={cityErr ? {borderColor: 'red'} : null}
                                                                onChange={(e) => setCity(e.target.value)}
                                                                className="form-control form-control-border">
                                                            <option value="">------</option>
                                                            <option value="_TBILISI_">{props.t("_TBILISI_")}</option>
                                                            <option value="_KUTAISI_">{props.t("_KUTAISI_")}</option>
                                                            <option value="_BATUMI_">{props.t("_BATUMI_")}</option>
                                                            <option
                                                                value="_ABASTUMANI_">{props.t("_ABASTUMANI_")}</option>
                                                            <option value="_ABASHA_">{props.t("_ABASHA_")}</option>
                                                            <option value="_ADIGENI_">{props.t("_ADIGENI_")}</option>
                                                            <option
                                                                value="_AMBROLAURI_">{props.t("_AMBROLAURI_")}</option>
                                                            <option value="_ANAKLIA_">{props.t("_ANAKLIA_")}</option>
                                                            <option value="_ASPINDZA_">{props.t("_ASPINDZA_")}</option>
                                                            <option
                                                                value="_AKHALGORI_">{props.t("_AKHALGORI_")}</option>
                                                            <option
                                                                value="_AKHALKALAKI_">{props.t("_AKHALKALAKI_")}</option>
                                                            <option
                                                                value="_AKHALTSIKHE_">{props.t("_AKHALTSIKHE_")}</option>
                                                            <option value="_AKHMETA_">{props.t("_AKHMETA_")}</option>
                                                            <option
                                                                value="_BAKURIANI_">{props.t("_BAKURIANI_")}</option>
                                                            <option value="_BAXMARO_">{props.t("_BAXMARO_")}</option>
                                                            <option value="_BARDADI_">{props.t("_BARDADI_")}</option>
                                                            <option value="_BOLNISI_">{props.t("_BOLNISI_")}</option>
                                                            <option value="_BORJOMI_">{props.t("_BORJOMI_")}</option>
                                                            <option value="_CAGVERI_">{props.t("_CAGVERI_")}</option>
                                                            <option
                                                                value="_GARDABANI_">{props.t("_GARDABANI_")}</option>
                                                            <option value="_GONIO_">{props.t("_GONIO_")}</option>
                                                            <option value="_GORI_">{props.t("_GORI_")}</option>
                                                            <option
                                                                value="_GRIGOLETI_">{props.t("_GRIGOLETI_")}</option>
                                                            <option value="_GUDAURI_">{props.t("_GUDAURI_")}</option>
                                                            <option value="_GURJAANI_">{props.t("_GURJAANI_")}</option>
                                                            <option
                                                                value="_DEDOFLISCKARO_">{props.t("_DEDOFLISCKARO_")}</option>
                                                            <option value="_DMANISI_">{props.t("_DMANISI_")}</option>
                                                            <option value="_DUSETI_">{props.t("_DUSETI_")}</option>
                                                            <option
                                                                value="_ZESTAFONI_">{props.t("_ZESTAFONI_")}</option>
                                                            <option value="_ZUGDIDI_">{props.t("_ZUGDIDI_")}</option>
                                                            <option value="_VANI_">{props.t("_VANI_")}</option>
                                                            <option
                                                                value="_TETRIWYARO_">{props.t("_TETRIWYARO_")}</option>
                                                            <option value="_TELAVI_">{props.t("_TELAVI_")}</option>
                                                            <option value="_TERJOLA_">{props.t("_TERJOLA_")}</option>
                                                            <option value="_TIANETI_">{props.t("_TIANETI_")}</option>
                                                            <option value="_KASPI_">{props.t("_KASPI_")}</option>
                                                            <option value="_KAJRETI_">{props.t("_KAJRETI_")}</option>
                                                            <option value="_KVARIATI_">{props.t("_KVARIATI_")}</option>
                                                            <option value="_KOJORI_">{props.t("_KOJORI_")}</option>
                                                            <option value="_LAGODEXI_">{props.t("_LAGODEXI_")}</option>
                                                            <option value="_LANCXUTI_">{props.t("_LANCXUTI_")}</option>
                                                            <option value="_MARNEULI_">{props.t("_MARNEULI_")}</option>
                                                            <option value="_LENTEXI_">{props.t("_LENTEXI_")}</option>
                                                            <option value="_MARTVILI_">{props.t("_MARTVILI_")}</option>
                                                            <option value="_MCXETA_">{props.t("_MCXETA_")}</option>
                                                            <option value="_MESTIA_">{props.t("_MESTIA_")}</option>
                                                            <option
                                                                value="_MCVANEKONCXI_">{props.t("_MCVANEKONCXI_")}</option>
                                                            <option
                                                                value="_NINOWMINDA_">{props.t("_NINOWMINDA_")}</option>
                                                            <option value="_OZURGETI_">{props.t("_OZURGETI_")}</option>
                                                            <option value="_ONI_">{props.t("_ONI_")}</option>
                                                            <option value="_RUSTAVI_">{props.t("_RUSTAVI_")}</option>
                                                            <option value="_SAGAREJO_">{props.t("_SAGAREJO_")}</option>
                                                            <option value="_SAGURAMO_">{props.t("_SAGURAMO_")}</option>
                                                            <option value="_SAIRME_">{props.t("_SAIRME_")}</option>
                                                            <option
                                                                value="_SAMTREDIA_">{props.t("_SAMTREDIA_")}</option>
                                                            <option value="_SARFI_">{props.t("_SARFI_")}</option>
                                                            <option value="_SACXERE_">{props.t("_SACXERE_")}</option>
                                                            <option value="_SENAKI_">{props.t("_SENAKI_")}</option>
                                                            <option value="_SIRNARI_">{props.t("_SIRNARI_")}</option>
                                                            <option
                                                                value="_STEFANWMIN_">{props.t("_STEFANWMIN_")}</option>
                                                            <option value="_SURAMI_">{props.t("_SURAMI_")}</option>
                                                            <option
                                                                value="_TABAXMELA_">{props.t("_TABAXMELA_")}</option>
                                                            <option value="_TYIBULI_">{props.t("_TYIBULI_")}</option>
                                                            <option value="_UREKI_">{props.t("_UREKI_")}</option>
                                                            <option value="_FOTI_">{props.t("_FOTI_")}</option>
                                                            <option value="_QARELI_">{props.t("_QARELI_")}</option>
                                                            <option value="_QEDA_">{props.t("_QEDA_")}</option>
                                                            <option value="_YVARELI_">{props.t("_YVARELI_")}</option>
                                                            <option value="_SUAXEVI_">{props.t("_SUAXEVI_")}</option>
                                                            <option value="_CHAQVI_">{props.t("_CHAQVI_")}</option>
                                                            <option
                                                                value="_COXATAURI_">{props.t("_COXATAURI_")}</option>
                                                            <option value="_CAGERI_">{props.t("_CAGERI_")}</option>
                                                            <option value="_CXOROWYU_">{props.t("_CXOROWYU_")}</option>
                                                            <option value="_WAVKISI_">{props.t("_WAVKISI_")}</option>
                                                            <option
                                                                value="_WALENJIXA_">{props.t("_WALENJIXA_")}</option>
                                                            <option value="_WALKA_">{props.t("_WALKA_")}</option>
                                                            <option
                                                                value="_TSEROVANI_">{props.t("_TSEROVANI_")}</option>
                                                            <option value="_WYALTUBO_">{props.t("_WYALTUBO_")}</option>
                                                            <option value="_WNORI_">{props.t("_WNORI_")}</option>
                                                            <option value="_WYNETI_">{props.t("_WYNETI_")}</option>
                                                            <option value="_WIATURA_">{props.t("_WIATURA_")}</option>
                                                            <option
                                                                value="_XARAGAULI_">{props.t("_XARAGAULI_")}</option>
                                                            <option value="_XASHURI_">{props.t("_XASHURI_")}</option>
                                                            <option
                                                                value="_XELVACAURI_">{props.t("_XELVACAURI_")}</option>
                                                            <option value="_XOBI_">{props.t("_XOBI_")}</option>
                                                            <option value="_XONI_">{props.t("_XONI_")}</option>
                                                            <option value="_XULO_">{props.t("_XULO_")}</option>
                                                        </select>
                                                    </Col>

                                                    <Col md={4}>
                                                        <Label className="form-label"
                                                               htmlFor="formrow-email-input">{props.t("_PHONE_NUMBER_")}</Label>
                                                        <Input type="text"
                                                               disabled={step === true ? true : false}
                                                               value={phone}
                                                               style={phoneErr ? {borderColor: 'red'} : null}
                                                               onChange={(e) => setPhone(e.target.value.replace(/\D/, ''))}
                                                               className="form-control" id="formrow-email-input"/>
                                                    </Col>

                                                    <Col md={4}>
                                                        <Label className="form-label"
                                                               htmlFor="formrow-email-input">{props.t("_ADDRESS_")}</Label>
                                                        <Input type="text"
                                                               disabled={step === true ? true : false}
                                                               value={address}
                                                               style={addressErr ? {borderColor: 'red'} : null}
                                                               onChange={(e) => setAddress(e.target.value)}
                                                               className="form-control" id="formrow-email-input"/>
                                                    </Col>
                                                </Row>
                                                <Row style={{paddingTop: '20px'}}>
                                                    {
                                                        step === true
                                                            ? <Col>
                                                                <div className="button-items">
                                                                    <Button
                                                                        onClick={handleStep}
                                                                        color="warning"
                                                                        className="btn btn-primary waves-effect waves-light"
                                                                    >
                                                                        {props.t("_EDIT_")}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            :<Col>
                                                                <div className="button-items">
                                                                    <Button
                                                                        onClick={handleUpdateProfile}
                                                                        color="primary"
                                                                        className="btn btn-primary waves-effect waves-light"
                                                                    >
                                                                        {props.t("_REMEMBER_ME_")}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                    }


                                                </Row>
                                            </>
                                            : null
                                    }


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    const {
        Auth,
        Layout
    } = state
    return {
        Auth,
        Layout
    }
};

export default connect(mapStateToProps)(withTranslation()(Profile));
