import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Container, Input, Label, Row} from "reactstrap";
import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {changePassword, } from "../../store/auth/actions";

const Profile = (props) => {
    const dispatch = useDispatch();

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newRePassword, setReNewPassword] = useState('')

    const [oldPasswordErr, setOldPasswordErr] = useState(false)
    const [newPasswordErr, setNewPasswordErr] = useState(false)
    const [newRePasswordErr, setReNewPasswordErr] = useState(false)


    useEffect(() => {
        if (props.Auth.isAuthenticated === true) {

        }

    }, [props.Auth.isAuthenticated])

    const handleUpdatePassword = () => {
        if (!oldPassword) {
            setOldPasswordErr(true)
        } else {
            setOldPasswordErr(false)
        }
        if (!newPassword) {
            setNewPasswordErr(true)
        } else {
            setNewPasswordErr(false)
        }
        if (!newRePassword) {
            setReNewPasswordErr(true)
        } else {
            setReNewPasswordErr(false)
        }

        if (oldPassword && newPassword && newRePassword ) {
            if (newPassword !== newRePassword) {
                setNewPasswordErr(true)
                setReNewPasswordErr(true)
            }else {
                setNewPasswordErr(false)
                setReNewPasswordErr(false)
                let item = {
                    oldPassword,
                    newPassword,
                    newRePassword
                }
                dispatch(changePassword(item))
            }
        }

    }

    return (
        <React.Fragment>
            <div className="my-5 pt-sm-5 three-rem">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    {
                                        props.Auth.candidate.AXID
                                            ?
                                            <>
                                                <Row className="mb-3">
                                                    <Col md={4}>
                                                        <Label className="form-label" >{props.t('_OLD_PASSWORD_')}</Label>
                                                        <Input type="password"
                                                               value={oldPassword}
                                                               style={oldPasswordErr ? {borderColor: 'red'} : null}
                                                               onChange={(e) => setOldPassword(e.target.value)}
                                                               className="form-control"/>
                                                        {
                                                            oldPasswordErr
                                                            ? <i className='error'> Error</i>
                                                                :null
                                                        }
                                                    </Col>
                                                    <Col md={4}>
                                                        <Label className="form-label">{props.t('_NEW_PASSWORD_')}</Label>
                                                        <Input type="password"
                                                               value={newPassword}
                                                               style={newPasswordErr ? {borderColor: 'red'} : null}
                                                               onChange={(e) => setNewPassword(e.target.value)}
                                                               className="form-control" />
                                                        {
                                                            newPasswordErr
                                                                ? <i className='error'>Error </i>
                                                                :null
                                                        }
                                                    </Col>

                                                    <Col md={4}>
                                                        <Label className="form-label" >{props.t('_CONFIRM_PASSWORD')}</Label>
                                                        <Input type="password"
                                                               value={newRePassword}
                                                               style={newRePasswordErr ? {borderColor: 'red'} : null}
                                                               onChange={(e) => setReNewPassword(e.target.value)}
                                                               className="form-control" />
                                                        {
                                                            newRePasswordErr
                                                                ? <i className='error'> Error</i>
                                                                :null
                                                        }
                                                    </Col>


                                                </Row>

                                                <Row style={{paddingTop: '20px'}}>
                                                    <Col>
                                                        <div className="button-items">
                                                            <Button
                                                                onClick={handleUpdatePassword}
                                                                color="primary"
                                                                className="btn btn-primary waves-effect waves-light"
                                                            >
                                                                {props.t('_CHANGE_PASSWORD_')}
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                            : null
                                    }


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    const {
        Auth,
        Layout
    } = state
    return {
        Auth,
        Layout
    }
};

export default connect(mapStateToProps)(withTranslation()(Profile));
