import {call, put, takeEvery} from "redux-saga/effects"
import {
    ADD_PARCEL_REQUEST,
    ADD_PARCEL_REQUEST_SUCCESS,
    ADD_PARCEL_WITH_FILE_REQUEST,
    BTN_SPINNER_ACTIVE,
    GET_PRODUCT_REQUEST,
    GET_PRODUCT_REQUEST_SUCCESS,
    GET_PRODUCTS_INTINITI_REQUEST,
    GET_PRODUCTS_INTINITI_REQUEST_OBTAINET_SUCCESS,
    GET_PRODUCTS_INTINITI_REQUEST_SUCCESS,
    GET_PRODUCTS_REQUEST,
    GET_PRODUCTS_REQUEST_OBTAINET_SUCCESS,
    GET_PRODUCTS_REQUEST_SUCCESS,
    GET_PRODUCTS_WITH_FLIGHT_ID_REQUEST,
    GET_PRODUCTS_WITH_FLIGHT_ID_REQUEST_SUCCESS,
    UPDATE_PRODUCT_DECLARATION_REQUEST,
    UPDATE_PRODUCT_DECLARATION_REQUEST_SUCCESS,
    UPDATE_PRODUCT_DECLARATION_REQUEST_WITH_FILE,
    UPDATE_PRODUCT_DECLARATION_REQUEST_WITH_FILE_SUCCESS
} from "./actionTypes"
import {notification} from 'antd';
import {showRightSidebarAddParcelAction, showRightSidebarUpdateParcelAction} from "../layout/actions";
import {getProducts} from "./actions";
import {incorectFormat, suchAnIdHasArledy, trackingAlreadyAdded} from "../../helpers/notification";

const modalSuccess = (type, placement) => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
        placement
    });
};
const modalSuccessUpdate = (type, placement) => {
    notification[type]({
        message: 'წარმატება',
        description: 'თქვენ წარმატებით დაარედაქტირეთ ამანათი',
        placement
    });
};

const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'აღნიშნული გზავნილის ნომერი უკვე დამატებულია',
    });
};

const modalErrorUnic = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მიმართეთ ოპერატორს',

    });
};



function* addParcelWithFileSaga({payload}) {
    try {
        const callBack = yield call(addParcelWithFileSagaAsync, payload)
        if (callBack.success) {
            yield put({type: ADD_PARCEL_REQUEST_SUCCESS, callBack})
            modalSuccess('success', 'topLeft');
            yield put(showRightSidebarAddParcelAction(false))

            let item = {
                status: 'waiting',
                skip: 0,
                limit: 10000
            }
            yield put(getProducts(item))
        } else {
            if (callBack.message === 'INCORECT_FILE_TYPE') {
                incorectFormat('warning')
            }else {
                trackingAlreadyAdded('warning')
            }
        }



    } catch (err) {
        console.error(err);
    }
}


const addParcelWithFileSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/product/addProductWithFile`, {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${jwt}`,
        },
        body: item
    })
    return await response.json()
}

function* addParcelSaga({payload}) {
    try {
       const callBack = yield call(addParcelSagaAsync, payload)

        if (callBack.success) {
            yield put({type: ADD_PARCEL_REQUEST_SUCCESS, callBack})
            modalSuccess('success', 'topLeft');

            let item = {
                status: payload.statusPage,
                skip: 0,
                limit: 100
            }
            yield put(getProducts(item))
            yield put(showRightSidebarAddParcelAction(false))
        } else {
            if (callBack.message === 'INCORECT_FILE_TYPE') {
                incorectFormat('warning')
            }else {
                trackingAlreadyAdded('warning')
            }
        }
    } catch (err) {
        console.error(err);
    }
}


const addParcelSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/product/addProduct`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}

function* getProductsSaga({payload}) {
    try {
        const callBack = yield call(getProductsSagaAsync, payload)
        if (callBack.success) {
            if (payload.status === 'obtained'){
                yield put({type: GET_PRODUCTS_REQUEST_OBTAINET_SUCCESS, callBack})
            }else {
                yield put({type: GET_PRODUCTS_REQUEST_SUCCESS, callBack})
            }
        } else {
            modalError('error');
        }
    } catch (err) {
        console.error(err);
    }
}

function* getProductInfinitiSaga({payload}) {
    try {
        yield put({type: BTN_SPINNER_ACTIVE, payload: {active:true}})
        const callBack = yield call(getProductsSagaAsync, payload)
        if (callBack.success) {
            if (payload.status === 'obtained'){
                yield put({type: GET_PRODUCTS_INTINITI_REQUEST_OBTAINET_SUCCESS, callBack})
            }else {
                yield put({type: GET_PRODUCTS_INTINITI_REQUEST_SUCCESS, callBack})
            }

        } else {
            modalErrorUnic('error');
        }
    } catch (err) {
        console.error(err);
    }
}


const getProductsSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/product/gets/${item.status}/${item.skip}/${item.limit}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* getProductsWithFlightIdSaga({payload}) {
    try {
        const callBack = yield call(getProductsWithFlightIdSagaAsync, payload)
        if (callBack.success) {
            yield put({type: GET_PRODUCTS_WITH_FLIGHT_ID_REQUEST_SUCCESS, callBack})
        } else {
            modalError('error');
        }
    } catch (err) {
        console.error(err);
    }
}


const getProductsWithFlightIdSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/product/getswith/${item.status}/${item.skip}/${item.limit}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}


function* getProductSaga({payload}) {
    try {
        const callBack = yield call(getProductSagaAsync, payload)
        if (callBack.success) {
            yield put({type: GET_PRODUCT_REQUEST_SUCCESS, callBack})
        } else {
            modalErrorUnic('error');
        }
    } catch (err) {
        console.error(err);
    }
}



const getProductSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/product/get/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}


function* updateProductDeclarationSaga({payload}) {
    try {
        const callBack = yield call(updateProductDeclarationSagaAsync, payload)
        if (callBack.success) {
            yield put(showRightSidebarUpdateParcelAction(false))
            let payloading = {
                payload,
                callBack
            }
            yield put({type: UPDATE_PRODUCT_DECLARATION_REQUEST_SUCCESS, payloading})
            modalSuccessUpdate('success');
        } else {
            modalErrorUnic('error');
        }
    } catch (err) {
        console.error(err);
    }
}


const updateProductDeclarationSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/product/updateProduct`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}

function* updateProductDeclarationWithFileSaga({payload}) {
    try {
        const callBack = yield call(updateProductDeclarationWithFileSagaAsync, payload)
        if (callBack.success) {
            let payloading = {
                payload,
                callBack
            }
            yield put({type: UPDATE_PRODUCT_DECLARATION_REQUEST_WITH_FILE_SUCCESS, payloading})
            yield put(showRightSidebarUpdateParcelAction(false))
            modalSuccessUpdate('success');
        } else {
            if (callBack.message === 'INCORECT_FILE_TYPE') {
                incorectFormat('warning')
            }else {
                trackingAlreadyAdded('warning')
            }
        }
    } catch (err) {
        console.error(err);
    }
}


const updateProductDeclarationWithFileSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/product/updateProductWithFile`, {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${jwt}`,
        },
        body: item
    })
    return await response.json()
}


function* productsSaga() {
    yield takeEvery(ADD_PARCEL_REQUEST, addParcelSaga)
    yield takeEvery(ADD_PARCEL_WITH_FILE_REQUEST, addParcelWithFileSaga)
    yield takeEvery(GET_PRODUCTS_REQUEST, getProductsSaga)
    yield takeEvery(GET_PRODUCTS_WITH_FLIGHT_ID_REQUEST, getProductsWithFlightIdSaga)
    yield takeEvery(GET_PRODUCT_REQUEST, getProductSaga)
    yield takeEvery(GET_PRODUCTS_INTINITI_REQUEST, getProductInfinitiSaga)
    yield takeEvery(UPDATE_PRODUCT_DECLARATION_REQUEST, updateProductDeclarationSaga)
    yield takeEvery(UPDATE_PRODUCT_DECLARATION_REQUEST_WITH_FILE, updateProductDeclarationWithFileSaga)
}

export default productsSaga
