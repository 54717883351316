import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"
import Flight from "./flight/reducer"
import News from "./news/reducer"
import Rates from "./rate/reducer"
import Offices from "./offices/reducer"
import Products from "./products/reducer"
import Pages from "./page/reducer"
import Popap from "./popap/reducer"
import Notification from "./notification/reducer"
import Invoice from "./invoice/reducer"
import Courier from "./courier/reducer"
import Merchant from "./merchant/reducer"

// Authentication
import Auth from "./auth/reducer"
const rootReducer = combineReducers({
  // public
  Layout,
  Auth,
  Flight,
  News,
  Rates,
  Offices,
  Products,
  Pages,
  Popap,
  Notification,
  Invoice,
  Courier,
  Merchant,
})

export default rootReducer
