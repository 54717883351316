// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  SHOW_SIDEBAR,
  SHOW_RIGHT_ADD_PARCEL_SIDEBAR,
  SHOW_RIGHT_ADD_AMOUNT_SIDEBAR,
  SHOW_RIGHT_UPDATE_PARCEL_SIDEBAR,
  SHOW_RIGHT_FORGET_PASSWORD_SIDEBAR, PARCEL_STATUS_CHANGES, SHOW_RIGHT_ADD_COURIER_SIDEBAR,
} from "./actionTypes";

const INIT_STATE = {
  layoutType: "vertical",
  layoutWidth: "fluid",
  leftSideBarTheme: "dark",
  leftSideBarType: "default",
  topbarTheme: "dark",
  isPreloader: false,
  showRightSidebar: false,
  showRightSidebarAddParcel: false,
  showRightSidebarCourier: false,
  showRightSidebarUpdateParcel: false,
  showRightSidebarAddAmount: false,
  showRightSidebarForgetPassword: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,

  parcelStatus :'waiting'



};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PARCEL_STATUS_CHANGES:
      return {
        ...state,
        parcelStatus: action.payload,
      };
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      };

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      };
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      };
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      };
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      };
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.payload,
      };
    case SHOW_RIGHT_ADD_COURIER_SIDEBAR:
      return {
        ...state,
        showRightSidebarCourier: action.payload,
      };
    case SHOW_RIGHT_ADD_PARCEL_SIDEBAR:
      return {
        ...state,
        showRightSidebarAddParcel: action.payload,
      };
    case SHOW_RIGHT_UPDATE_PARCEL_SIDEBAR:
      return {
        ...state,
        showRightSidebarUpdateParcel: action.payload,
      };
    case SHOW_RIGHT_ADD_AMOUNT_SIDEBAR:
      return {
        ...state,
        showRightSidebarAddAmount: action.payload,
      };
    case SHOW_RIGHT_FORGET_PASSWORD_SIDEBAR:
      return {
        ...state,
        showRightSidebarForgetPassword: action.payload,
      };
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
      };
    case TOGGLE_LEFTMENU:
      return {
        ...state,
        leftMenu: action.payload,
      };

    default:
      return state;
  }
};

export default Layout;
