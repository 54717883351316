import {
    GET_RATES_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    array : [],
    CNY : 3.7519,
    EUR : 2.9402,
    TRY : 0.0838,
    USD : 2.6999,
}

const Rates = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_RATES_REQUEST_SUCCESS:
            action.callBack.rates.map((item) =>{
                if (item.code ==='EUR') {
                    state.EUR = parseFloat(item.rate)
                }
                else if (item.code ==='CNY') {
                    state.CNY = parseFloat(item.rate)
                }
                else if (item.code ==='TRY') {
                    state.TRY = parseFloat(item.rate)
                }
                else if (item.code ==='USD') {
                    state.USD = parseFloat(item.rate)
                }
            })
            return {
                ...state,
                array: action.callBack.rates,
            }

        default:
            return state
    }
}

export default Rates
