import {
    SIGN_UP_REQUEST,
    SIGN_IN_REQUEST,
    FORGET_PASSWORD_REQUEST,
    CHECK_USER_AUTH_REQUEST,
    LOG_OUT_REQUEST,
    SIGN_UP_WITH_FILE_REQUEST, UPDATE_USER_PROFILE_REQUEST, CHANGE_PASSWORD_REQUEST,
    GET_USER_DEPT_REQUEST
} from "./actionTypes"

export const getUserDept = () => ({
    type:GET_USER_DEPT_REQUEST,
})

export const signIn = (data) => ({
    type:SIGN_IN_REQUEST,
    payload: data,
})


export const signUp = (data) => ({
    type: SIGN_UP_REQUEST,
    payload: data,
})


export const signUpWithFile = (data) => ({
    type: SIGN_UP_WITH_FILE_REQUEST,
    payload: data,
})


export const forgetPassword = (data) => ({
    type: FORGET_PASSWORD_REQUEST,
    payload: data,
})



export const changePassword = (data) => ({
    type: CHANGE_PASSWORD_REQUEST,
    payload: data,
})


export const checkUserAuth = (data) => ({
    type: CHECK_USER_AUTH_REQUEST,
    payload: data,
})

export const updateUserProfile = (data) => ({
    type: UPDATE_USER_PROFILE_REQUEST,
    payload: data,
})

export const logOut = () => ({
    type: LOG_OUT_REQUEST
})
