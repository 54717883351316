import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {showRightSidebarAddAmountAction} from "../../store/layout/actions";
import {getUserDept} from "../../store/auth/actions";
import {paymentAll} from "../../store/payment/actions";
import {Button} from "reactstrap";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const NotSignInForm = (props) =>{
    const dispatch = useDispatch();

    useEffect(() =>{

        dispatch(getUserDept())

    }, [])


    const handleAddAmountl = () =>{
        dispatch(showRightSidebarAddAmountAction(true))
    }

    const handlePayAll = () =>{
        if ( props.Auth.debt > 0) {
            dispatch(paymentAll(props.Layout.parcelStatus))
        }
    }
    return (
        <>
        <div className="p-2 mt-4">
            <div className="form-horizontal">
                <div className="mb-3" >
                    <div className={'row'} style={{textAlign:'center'}}>

                        <div className="col-12"> <h3  style={{color:'white'}}> {props.t("_HELLO_")} </h3> </div>
                        <div className="col-12"> <h4  style={{color:'white'}}> {capitalizeFirstLetter (props.Auth.candidate.information.nameEn)} {/*{props.Auth.candidate.information.lastNameKa} */} </h4> </div>
                        <div className="col-12 pt-3"> <h5 style={{color:'white'}}>{props.t("_ROOM_NUMBER_")} : <span style={{color :'#bbb40e'}}> {props.Auth.candidate.AXID} </span></h5> </div>
                        <div className="col-12"> <hr style={{height:'1px', color :'white'}}/> </div>
                    </div>
                    <div className={'row   pt-4'} >
                        <div className="col-6" > <h5 style={{color:'white'}}>{props.t("_BALANCE_")}:  </h5> </div>
                        <div className="col-6" style={{textAlign:'right'}}> <h5 style={{color:'#34c38f'}}>{props.Auth.candidate.balance.toFixed(2)} ₾  </h5> </div>
                        <div className="col-6" > <h5 style={{color:'white'}}>{props.t("_DEBT_")}:   </h5> </div>
                        <div className="col-6" style={{textAlign:'right'}}> <h5 style={{color:'red'}}> {props.Auth.debt.toFixed(2)}  ₾  </h5> </div>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <button
                            onClick={handlePayAll}
                            style={{width:'100%'}}
                            className="btn btn-success w-sm waves-effect waves-light"
                            type="submit"
                        >
                            {props.t("_PAY_")}
                        </button>
                    </div>
                    <div className={'col'}>
                        <button
                            onClick={handleAddAmountl}
                            style={{width:'100%'}}
                            className="btn btn-warning w-sm waves-effect waves-light"
                            type="submit"
                        >
                            <i className="uil uil-plus"></i>{" "} {' '}
                            {props.t("_BALANCE_")}
                        </button>
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}

const mapStateToProps = state => {
    const {
        Layout,
        Auth
    } = state
    return {
        Layout,
        Auth
    }
};

export default connect(mapStateToProps)(withTranslation()(NotSignInForm));
