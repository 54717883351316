import React from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";

import {withTranslation} from "react-i18next";
import Airpic from "../../assets/images/air.png";

import img1 from "../../assets/images/icons/1.png";
import img2 from "../../assets/images/icons/2.png";
import img3 from "../../assets/images/icons/3.png";
import img4 from "../../assets/images/icons/4.png";
import img5 from "../../assets/images/icons/5.png";
import img6 from "../../assets/images/icons/6.png";





const ServicesPage = (props) => {

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className=''>
                    <Row >
                        <Col md={4}>
                            <Card className="mt-4 maintenance-box" >
                                <CardBody className="p-4" style={{minHeight:'300px'}}>
                                    <div className="avatar-sm mx-auto mb-4">
                                        <img style={{height:'60px'}} src={img2}/>
                                    </div>
                                    <h5 className="font-size-15 text-uppercase"><strong> {props.t('_BIG_TEXT_SERVICE_1_TITLE')}</strong></h5>
                                    <p className="text-muted mb-0" style={{textAlign:'left'}}>   {props.t('_BIG_TEXT_SERVICE_1')}</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="mt-4 maintenance-box"  >
                                <CardBody className="p-4" style={{minHeight:'300px'}}>
                                    <div className="avatar-sm mx-auto mb-4">
                                        <img style={{height:'60px'}} src={img3}/>
                                    </div>
                                    <h5 className="font-size-15 text-uppercase"> <strong style={{}}> {props.t('_BIG_TEXT_SERVICE_2_TITLE')}</strong></h5>
                                    <p className="  mb-0" style={{textAlign:'left', whiteSpace: 'pre-wrap'}}>{props.t('_BIG_TEXT_SERVICE_2')}</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="mt-4 maintenance-box"  >
                                <CardBody className="p-4" style={{minHeight:'300px'}}>
                                    <div className="avatar-sm mx-auto mb-4">
                                        <img style={{height:'60px'}} src={img1}/>
                                    </div>
                                    <h5 className="font-size-15 text-uppercase"><strong> {props.t('_BIG_TEXT_SERVICE_3_TITLE')}</strong></h5>
                                    <p className="text-muted mb-0" style={{textAlign:'left'}}>   {props.t('_BIG_TEXT_SERVICE_3')}</p>
                                </CardBody>
                            </Card>
                        </Col>


                        <Col md={4}>
                            <Card className="mt-4 maintenance-box"  >
                                <CardBody className="p-4" style={{minHeight:'300px'}}>
                                    <div className="avatar-sm mx-auto mb-4">
                                        <img style={{height:'60px'}} src={img4}/>
                                    </div>
                                    <h5 className="font-size-15 text-uppercase"><strong> {props.t('_BIG_TEXT_SERVICE_4_TITLE')}</strong></h5>
                                    <p className="text-muted mb-0" style={{textAlign:'left',whiteSpace: 'pre-wrap'}}>{props.t('_BIG_TEXT_SERVICE_4')}</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="mt-4 maintenance-box"  >
                                <CardBody className="p-4" style={{minHeight:'300px'}}>
                                    <div className="avatar-sm mx-auto mb-4">
                                        <img style={{height:'60px'}} src={img5}/>
                                    </div>
                                    <h5 className="font-size-15 text-uppercase"> <strong> {props.t('_BIG_TEXT_SERVICE_5_TITLE')}</strong></h5>
                                    <p className="  mb-0" style={{textAlign:'left'}}>{props.t('_BIG_TEXT_SERVICE_5')}</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="mt-4 maintenance-box" >
                                <CardBody className="p-4" style={{minHeight:'300px'}}>
                                    <div className="avatar-sm mx-auto mb-4">
                                        <img style={{height:'60px'}} src={img6}/>
                                    </div>
                                    <h5 className="font-size-15 text-uppercase"><strong> {props.t('_BIG_TEXT_SERVICE_6_TITLE')}</strong></h5>
                                    <p className="text-muted mb-0" style={{textAlign:'left',whiteSpace: 'pre-wrap'}}>{props.t('_BIG_TEXT_SERVICE_6')}</p>
                                </CardBody>
                            </Card>
                        </Col>


                    </Row>




                </Container>
            </div>
        </React.Fragment>
    )

}

export default withTranslation()(ServicesPage);

