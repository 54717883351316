import {
    COURIER_NULL,
    GET_COURIER_FLIGHTS_REQUEST_SUCCESS, GET_COURIER_PARCELS_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    array : [],
    message : '',
}

const Flight = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COURIER_FLIGHTS_REQUEST_SUCCESS:

            return {
                ...state,
                array: action.callBack.flightArray,
                message: action.callBack.message,
            }

        case COURIER_NULL:
            return {
                ...state,
                array: [],
                message: '',
            }

        case GET_COURIER_PARCELS_REQUEST_SUCCESS:

            return {
                ...state,
                array: action.callBack.array,
                message: action.callBack.message,
            }

        default:
            return state
    }
}

export default Flight
