import {call, put, takeEvery} from "redux-saga/effects"
import {
    GET_OFFICES_REQUEST,
    GET_OFFICES_REQUEST_SUCCESS
} from "./actionTypes"


function* getOfficesSaga({payload}) {
    try {
        const callBack = yield call(getOfficesSagaAsync, payload)
        if (callBack.success) {
            yield put({type: GET_OFFICES_REQUEST_SUCCESS, callBack})
        }
    } catch (err) {
        console.error(err);
    }
}


const getOfficesSagaAsync = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/office/gets`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    })
    return await response.json()
}


function* officesSaga() {
    yield takeEvery(GET_OFFICES_REQUEST, getOfficesSaga)
}

export default officesSaga
