import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {Card, CardBody, Col, Row, Table} from "reactstrap";
import React, {useState, useEffect} from "react";
import Moment from 'react-moment';
import FlighDown from "../assets/images/flight-down.png";
import ChinaFlag from "../assets/images/flags/china.png";
import TurkeyFlag from "../assets/images/flags/turkey.png";
import USAFlag from "../assets/images/flags/united-states.png";
import {getFlight} from "../store/flight/actions";

const Schedule = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        let item = {
            skip: 0,
            limit: 25
        }
        dispatch(getFlight(item))
    }, [])
    return (
        <>

            <Card className={'mm-none'}>
                <CardBody>
                    <div>
                        <div className="table-responsive ">
                            <Table className="table-borderless table-centered table-nowrap">
                                <thead className="table-light">
                                <tr>
                                    <th>{props.t("_FLIGHTS_")} </th>
                                    <th></th>
                                    <th></th>
                                    <th style={{width: "20%", textAlign: 'center'}}>{props.t("_DEPARTURE_")}</th>
                                    <th style={{
                                        width: "20%",
                                        textAlign: 'center'
                                    }}>{props.t("_ESTIMATED_ARRIVAL_")}</th>
                                    <th style={{width: "20%", textAlign: 'center'}}>{props.t("_ARRIVAL_")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    props.Flight.array.length > 0
                                        ?
                                        <>
                                            {
                                                props.Flight.array.map((item, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td style={{width: "1%"}}>
                                                                {
                                                                    item.countryId === '1'
                                                                        ?
                                                                        <img src={ChinaFlag} style={{
                                                                            width: "60px",
                                                                            height: '40px'
                                                                        }} className="avatar-xs  " alt="..."/>
                                                                        : null
                                                                }
                                                                {
                                                                    item.countryId === '2'
                                                                        ?
                                                                        <img src={USAFlag} style={{
                                                                            width: "60px",
                                                                            height: '40px'
                                                                        }} className="avatar-xs  " alt="..."/>
                                                                        : null
                                                                }
                                                                {
                                                                    item.countryId === '3'
                                                                        ?
                                                                        <img src={TurkeyFlag} style={{
                                                                            width: "60px",
                                                                            height: '40px'
                                                                        }} className="avatar-xs  " alt="..."/>
                                                                        : null
                                                                }
                                                            </td>
                                                            <td style={{width: "20%"}}>
                                                                <h6 className="font-size-15 mb-1 fw-normal">{item.flightId}</h6>
                                                                <p className="text-muted font-size-13 mb-0">
                                                                    <i className="mdi mdi-map-marker"></i>
                                                                    {
                                                                        item.countryId === '1'
                                                                            ? props.t("_CHINA_")
                                                                            : null
                                                                    }
                                                                    {
                                                                        item.countryId === '3'
                                                                            ? props.t("_TURKEY_")
                                                                            : null
                                                                    }

                                                                </p>
                                                            </td>
                                                            <td style={{width: "20%"}}> {props.t(item.additionalText)}</td>
                                                            <td style={{width: "20%", textAlign: 'center'}}><span
                                                                className="text-muted fw-semibold">
                                                            {
                                                                item.startdAt
                                                                    ? <Moment format="YYYY-MM-DD" date={item.startdAt}/>
                                                                    : <> ------ </>
                                                            }


                                                        </span></td>
                                                            <td style={{width: "20%", textAlign: 'center'}}><span
                                                                className="text-muted fw-semibold">
                                                            {
                                                                item.estimatedAt
                                                                    ? <Moment format="YYYY-MM-DD"
                                                                              date={item.estimatedAt}/>
                                                                    : <> ------ </>
                                                            }

                                                        </span></td>
                                                            <td style={{width: "20%", textAlign: 'center'}}><span
                                                                className="text-muted fw-semibold">
                                                            {
                                                                item.endAt
                                                                    ? <Moment format="YYYY-MM-DD" date={item.endAt}/>
                                                                    : <> ------ </>
                                                            }
                                                            </span></td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </>
                                        : null

                                }


                                </tbody>
                            </Table>
                        </div>
                    </div>
                </CardBody>
            </Card>

            <Card className={'dd-none'}>
                <CardBody>
                    {
                        props.Flight.array.length > 0
                            ?
                            <>
                                {
                                    props.Flight.array.map((item, key) => {
                                        return (
                                            <div style={{paddingTop:'10px'}} key={key}>
                                                <div style={{border: '2px solid #f5f6f8', borderRadius: '5px', padding: '5px'}}>
                                                    <Row>
                                                        <Col> რეისი</Col>
                                                        <Col>
                                                            {
                                                                item.countryId === '1'
                                                                    ?
                                                                    <img src={ChinaFlag} style={{
                                                                        width: "30px",
                                                                        height: '20px'
                                                                    }} className="avatar-xs  " alt="..."/>
                                                                    : null
                                                            }
                                                            {
                                                                item.countryId === '2'
                                                                    ?
                                                                    <img src={USAFlag} style={{
                                                                        width: "30px",
                                                                        height: '20px'
                                                                    }} className="avatar-xs  " alt="..."/>
                                                                    : null
                                                            }
                                                            {
                                                                item.countryId === '3'
                                                                    ?
                                                                    <img src={TurkeyFlag} style={{
                                                                        width: "30px",
                                                                        height: '20px'
                                                                    }} className="avatar-xs  " alt="..."/>
                                                                    : null
                                                            }
                                                           <span style={{paddingLeft:'10px'}}>
                                                                <strong>
                                                                    {
                                                                        item.flightId
                                                                    }
                                                                </strong>
                                                           </span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>შეტყობინება</Col>
                                                        <Col>
                                                            {props.t(item.additionalText)}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>{props.t("_DEPARTURE_")} </Col>
                                                        <Col>
                                                            {
                                                                item.startdAt
                                                                    ? <Moment format="YYYY-MM-DD" date={item.startdAt}/>
                                                                    : <> ------ </>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>{props.t("_ESTIMATED_ARRIVAL_MOB")} </Col>
                                                        <Col>
                                                            {
                                                                item.estimatedAt
                                                                    ? <Moment format="YYYY-MM-DD"
                                                                              date={item.estimatedAt}/>
                                                                    : <> ------ </>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>{props.t("_ARRIVAL_")} </Col>
                                                        <Col>
                                                            {
                                                                item.endAt
                                                                    ? <Moment format="YYYY-MM-DD" date={item.endAt}/>
                                                                    : <> ------ </>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </>
                            : null


                    }

                </CardBody>
            </Card>


        </>
    )
}

const mapStateToProps = state => {
    const {
        Auth,
        Flight
    } = state
    return {
        Auth,
        Flight
    }
};

export default connect(mapStateToProps)(withTranslation()(Schedule));
